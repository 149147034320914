import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../api";

export function useBIServerStatus(args: { fetchEvery: number }) {
  const api = useAPI();
  return useQuery({
    queryKey: ["status"],
    queryFn: async (): ReturnType<(typeof api)["getStatus"]> => {
      try {
        const resp = await api.getStatus();
        return resp;
      } catch (err) {
        console.error("biserver status fetch error: ", err);
        return { available: false };
      }
    },
    refetchInterval: args.fetchEvery,
  });
}
