/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  BudgetResponse,
  BudgetValidationErrorResponse,
  Business,
  ChangeBusinessContextRequest,
  ChangeModeRequest,
  ChangePasswordRequest,
  GetBranchGeneralMetrics200Response,
  GetBranchOrderTypesNetSales200Response,
  GetBranchesNetSalesTimeseries200Response,
  GetBranchesReport200Response,
  GetBusinessesResponse,
  GetInactiveFoodicsUsersResponse,
  GetInitialOnboardingFoodicsAccountsResponse,
  GetOnboardBusinessTokenResponse,
  GetThoughtspotDataSourcesResponse,
  GetThoughtspotLiveboardsResponse,
  GetThoughtspotSchedulesResponse,
  GetUsersByBusinessResponse,
  GetUsersByOrganizationResponse,
  ImpersonateResponse,
  InitialOnboardFoodicsUsersRequest,
  InviteFoodicsUsersRequest,
  LiveboardCategory,
  LoginRequest,
  LoginResponse,
  MobileImpersonatorResponse,
  OnboardFoodicsUsersRequest,
  RequestResetPasswordRequest,
  ResetPasswordRequest,
  StatusResponse,
  ThoughtspotToken,
  Token,
  UpdateBusinessRequest,
  UserAggregate,
} from '../models/index';
import {
    BudgetResponseFromJSON,
    BudgetResponseToJSON,
    BudgetValidationErrorResponseFromJSON,
    BudgetValidationErrorResponseToJSON,
    BusinessFromJSON,
    BusinessToJSON,
    ChangeBusinessContextRequestFromJSON,
    ChangeBusinessContextRequestToJSON,
    ChangeModeRequestFromJSON,
    ChangeModeRequestToJSON,
    ChangePasswordRequestFromJSON,
    ChangePasswordRequestToJSON,
    GetBranchGeneralMetrics200ResponseFromJSON,
    GetBranchGeneralMetrics200ResponseToJSON,
    GetBranchOrderTypesNetSales200ResponseFromJSON,
    GetBranchOrderTypesNetSales200ResponseToJSON,
    GetBranchesNetSalesTimeseries200ResponseFromJSON,
    GetBranchesNetSalesTimeseries200ResponseToJSON,
    GetBranchesReport200ResponseFromJSON,
    GetBranchesReport200ResponseToJSON,
    GetBusinessesResponseFromJSON,
    GetBusinessesResponseToJSON,
    GetInactiveFoodicsUsersResponseFromJSON,
    GetInactiveFoodicsUsersResponseToJSON,
    GetInitialOnboardingFoodicsAccountsResponseFromJSON,
    GetInitialOnboardingFoodicsAccountsResponseToJSON,
    GetOnboardBusinessTokenResponseFromJSON,
    GetOnboardBusinessTokenResponseToJSON,
    GetThoughtspotDataSourcesResponseFromJSON,
    GetThoughtspotDataSourcesResponseToJSON,
    GetThoughtspotLiveboardsResponseFromJSON,
    GetThoughtspotLiveboardsResponseToJSON,
    GetThoughtspotSchedulesResponseFromJSON,
    GetThoughtspotSchedulesResponseToJSON,
    GetUsersByBusinessResponseFromJSON,
    GetUsersByBusinessResponseToJSON,
    GetUsersByOrganizationResponseFromJSON,
    GetUsersByOrganizationResponseToJSON,
    ImpersonateResponseFromJSON,
    ImpersonateResponseToJSON,
    InitialOnboardFoodicsUsersRequestFromJSON,
    InitialOnboardFoodicsUsersRequestToJSON,
    InviteFoodicsUsersRequestFromJSON,
    InviteFoodicsUsersRequestToJSON,
    LiveboardCategoryFromJSON,
    LiveboardCategoryToJSON,
    LoginRequestFromJSON,
    LoginRequestToJSON,
    LoginResponseFromJSON,
    LoginResponseToJSON,
    MobileImpersonatorResponseFromJSON,
    MobileImpersonatorResponseToJSON,
    OnboardFoodicsUsersRequestFromJSON,
    OnboardFoodicsUsersRequestToJSON,
    RequestResetPasswordRequestFromJSON,
    RequestResetPasswordRequestToJSON,
    ResetPasswordRequestFromJSON,
    ResetPasswordRequestToJSON,
    StatusResponseFromJSON,
    StatusResponseToJSON,
    ThoughtspotTokenFromJSON,
    ThoughtspotTokenToJSON,
    TokenFromJSON,
    TokenToJSON,
    UpdateBusinessRequestFromJSON,
    UpdateBusinessRequestToJSON,
    UserAggregateFromJSON,
    UserAggregateToJSON,
} from '../models/index';

export interface DeleteUsersUserIdRequest {
    user_id: string;
}

export interface FavoriteThoughtspotLiveboardRequest {
    liveboard_id: string;
}

export interface GetBranchAppsNetSalesRequest {
    branchId: string;
    time_period: GetBranchAppsNetSalesTimePeriodEnum;
}

export interface GetBranchGeneralMetricsRequest {
    branchId: string;
    time_period: GetBranchGeneralMetricsTimePeriodEnum;
}

export interface GetBranchNetSalesTimeseriesRequest {
    branchId: string;
    time_period: GetBranchNetSalesTimeseriesTimePeriodEnum;
}

export interface GetBranchOrderSourcesNetSalesRequest {
    branchId: string;
    time_period: GetBranchOrderSourcesNetSalesTimePeriodEnum;
}

export interface GetBranchOrderTagsNetSalesRequest {
    branchId: string;
    time_period: GetBranchOrderTagsNetSalesTimePeriodEnum;
}

export interface GetBranchOrderTypesNetSalesRequest {
    branchId: string;
    time_period: GetBranchOrderTypesNetSalesTimePeriodEnum;
}

export interface GetBranchesNetSalesTimeseriesRequest {
    time_period: GetBranchesNetSalesTimeseriesTimePeriodEnum;
}

export interface GetBranchesReportRequest {
    time_period: GetBranchesReportTimePeriodEnum;
}

export interface GetBudgetRequest {
    business_id: string;
    resolution: GetBudgetResolutionEnum;
    year: number;
}

export interface GetBudgetTemplateRequest {
    business_id: string;
    resolution: GetBudgetTemplateResolutionEnum;
    year: number;
}

export interface GetBudgetXlsxRequest {
    business_id: string;
    resolution: GetBudgetXlsxResolutionEnum;
    year: number;
}

export interface GetInactiveUsersByBusinessRequest {
    business_id: string;
}

export interface GetIntegrationsFoodicsOnboardBusinessRequest {
    token: string;
}

export interface GetRefreshRequest {
    token: string;
}

export interface GetUsersByBusinessRequest {
    business_id: string;
}

export interface ImpersonateRequest {
    id: string;
}

export interface PatchBusinessesBusinessIdRequest {
    business_id: string;
    UpdateBusinessRequest?: UpdateBusinessRequest;
}

export interface PostBudgetRequest {
    business_id: string;
    resolution: PostBudgetResolutionEnum;
    year: number;
    body?: Blob;
}

export interface PostChangePasswordRequest {
    ChangePasswordRequest?: ChangePasswordRequest;
}

export interface PostIntegrationsFoodicsAccountsInitialOnboardingRequest {
    InitialOnboardFoodicsUsersRequest?: InitialOnboardFoodicsUsersRequest;
}

export interface PostInviteFoodicsAccountsRequest {
    InviteFoodicsUsersRequest?: InviteFoodicsUsersRequest;
}

export interface PostLoginRequest {
    LoginRequest?: LoginRequest;
}

export interface PostOnboardUsersToBusinessRequest {
    business_id: string;
    OnboardFoodicsUsersRequest?: OnboardFoodicsUsersRequest;
}

export interface PostRequestResetPasswordRequest {
    RequestResetPasswordRequest?: RequestResetPasswordRequest;
}

export interface PostResetPasswordRequest {
    ResetPasswordRequest?: ResetPasswordRequest;
}

export interface PostSelfBusinessContextRequest {
    ChangeBusinessContextRequest?: ChangeBusinessContextRequest;
}

export interface PostSelfEnterpriseWebAppLiveboardsOrderRequest {
    LiveboardCategory?: Array<LiveboardCategory>;
}

export interface PostSelfEnterpriseWebAppModeRequest {
    ChangeModeRequest?: ChangeModeRequest;
}

export interface UnfavoriteThoughtspotLiveboardRequest {
    liveboard_id: string;
}

export interface UsersPromoteUserRequest {
    user_id: string;
}

/**
 * DefaultApi - interface
 * 
 * @export
 * @interface DefaultApiInterface
 */
export interface DefaultApiInterface {
    /**
     * 
     * @summary Delete user
     * @param {string} user_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    deleteUsersUserIdRaw(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Delete user
     */
    deleteUsersUserId(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Favorite thoughtspot liveboard to favorites
     * @param {string} liveboard_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    favoriteThoughtspotLiveboardRaw(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Favorite thoughtspot liveboard to favorites
     */
    favoriteThoughtspotLiveboard(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get net sales per app
     * @param {string} branchId The id of the branch
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchAppsNetSalesRaw(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>>;

    /**
     * Get net sales per app
     */
    getBranchAppsNetSales(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response>;

    /**
     * 
     * @summary Get general metrics of a branch
     * @param {string} branchId The id of the branch
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchGeneralMetricsRaw(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchGeneralMetrics200Response>>;

    /**
     * Get general metrics of a branch
     */
    getBranchGeneralMetrics(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchGeneralMetrics200Response>;

    /**
     * 
     * @summary Get net sales timeseries of a branch
     * @param {string} branchId The id of the branch
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchNetSalesTimeseriesRaw(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesNetSalesTimeseries200Response>>;

    /**
     * Get net sales timeseries of a branch
     */
    getBranchNetSalesTimeseries(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesNetSalesTimeseries200Response>;

    /**
     * 
     * @summary Get net sales per order source
     * @param {string} branchId The id of the branch
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchOrderSourcesNetSalesRaw(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>>;

    /**
     * Get net sales per order source
     */
    getBranchOrderSourcesNetSales(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response>;

    /**
     * 
     * @summary Get net sales per order tag
     * @param {string} branchId The id of the branch
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchOrderTagsNetSalesRaw(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>>;

    /**
     * Get net sales per order tag
     */
    getBranchOrderTagsNetSales(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response>;

    /**
     * 
     * @summary Get net sales per order type
     * @param {string} branchId The id of the branch
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchOrderTypesNetSalesRaw(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>>;

    /**
     * Get net sales per order type
     */
    getBranchOrderTypesNetSales(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response>;

    /**
     * 
     * @summary Get net sales timeseries of user\'s branches
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchesNetSalesTimeseriesRaw(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesNetSalesTimeseries200Response>>;

    /**
     * Get net sales timeseries of user\'s branches
     */
    getBranchesNetSalesTimeseries(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesNetSalesTimeseries200Response>;

    /**
     * 
     * @summary Get report of user\'s branches
     * @param {'today' | 'yesterday' | 'this-week' | 'last-week' | 'this-month' | 'last-month' | 'this-year'} time_period The time period over which a stat is queried
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBranchesReportRaw(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesReport200Response>>;

    /**
     * Get report of user\'s branches
     */
    getBranchesReport(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesReport200Response>;

    /**
     * 
     * @summary Get a budget
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBudgetRaw(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BudgetResponse>>;

    /**
     * Get a budget
     */
    getBudget(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BudgetResponse>;

    /**
     * 
     * @summary Get a budget template file
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBudgetTemplateRaw(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Get a budget template file
     */
    getBudgetTemplate(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @summary Get a budget in XLSX format
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBudgetXlsxRaw(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>>;

    /**
     * Get a budget in XLSX format
     */
    getBudgetXlsx(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob>;

    /**
     * 
     * @summary Get businesses of user
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getBusinessesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBusinessesResponse>>;

    /**
     * Get businesses of user
     */
    getBusinesses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBusinessesResponse>;

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * @summary Initiate Foodics OAuth2 flow
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getFoodicsAuthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * Initiate Foodics OAuth2 flow
     */
    getFoodicsAuth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Get impersonator data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getImpersonatorDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileImpersonatorResponse>>;

    /**
     * Get impersonator data
     */
    getImpersonatorData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileImpersonatorResponse>;

    /**
     * 
     * @summary Get inactive users by business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getInactiveUsersByBusinessRaw(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInactiveFoodicsUsersResponse>>;

    /**
     * Get inactive users by business
     */
    getInactiveUsersByBusiness(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInactiveFoodicsUsersResponse>;

    /**
     * 
     * @summary Get the foodics accounts that are eligible for the initial onboarding
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationsFoodicsAccountsInitialOnboardingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInitialOnboardingFoodicsAccountsResponse>>;

    /**
     * Get the foodics accounts that are eligible for the initial onboarding
     */
    getIntegrationsFoodicsAccountsInitialOnboarding(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInitialOnboardingFoodicsAccountsResponse>;

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * @summary Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationsFoodicsOnboardBusinessRaw(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     */
    getIntegrationsFoodicsOnboardBusiness(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Mint a short lived token that allows onboarding a new business to an existing org
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getIntegrationsFoodicsOnboardBusinessRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOnboardBusinessTokenResponse>>;

    /**
     * Mint a short lived token that allows onboarding a new business to an existing org
     */
    getIntegrationsFoodicsOnboardBusinessRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOnboardBusinessTokenResponse>;

    /**
     * 
     * @summary Get the users of the logged in user\'s organization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getOrganizationUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByOrganizationResponse>>;

    /**
     * Get the users of the logged in user\'s organization
     */
    getOrganizationUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByOrganizationResponse>;

    /**
     * 
     * @summary Refresh auth
     * @param {string} token 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getRefreshRaw(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>>;

    /**
     * Refresh auth
     */
    getRefresh(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token>;

    /**
     * Update self
     * @summary Self endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getSelfRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAggregate>>;

    /**
     * Update self
     * Self endpoint
     */
    getSelf(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAggregate>;

    /**
     * 
     * @summary Returns information about the status of the biserver service
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusResponse>>;

    /**
     * Returns information about the status of the biserver service
     */
    getStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusResponse>;

    /**
     * 
     * @summary Get thoughtspot liveboards
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotLiveboardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotLiveboardsResponse>>;

    /**
     * Get thoughtspot liveboards
     */
    getThoughtspotLiveboards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotLiveboardsResponse>;

    /**
     * 
     * @summary Get thoughtspot schedules
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotSchedulesResponse>>;

    /**
     * Get thoughtspot schedules
     */
    getThoughtspotSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotSchedulesResponse>;

    /**
     * 
     * @summary Get thoughtspot data sources for the search data feature
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotSearchDataDataSourcesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotDataSourcesResponse>>;

    /**
     * Get thoughtspot data sources for the search data feature
     */
    getThoughtspotSearchDataDataSources(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotDataSourcesResponse>;

    /**
     * 
     * @summary Get thoughtspot token
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getThoughtspotTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThoughtspotToken>>;

    /**
     * Get thoughtspot token
     */
    getThoughtspotToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThoughtspotToken>;

    /**
     * 
     * @summary Get users by business
     * @param {string} business_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    getUsersByBusinessRaw(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByBusinessResponse>>;

    /**
     * Get users by business
     */
    getUsersByBusiness(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByBusinessResponse>;

    /**
     * 
     * @summary Impersonate another user
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    impersonateRaw(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImpersonateResponse>>;

    /**
     * Impersonate another user
     */
    impersonate(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImpersonateResponse>;

    /**
     * 
     * @summary Update business
     * @param {string} business_id 
     * @param {UpdateBusinessRequest} [UpdateBusinessRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    patchBusinessesBusinessIdRaw(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>>;

    /**
     * Update business
     */
    patchBusinessesBusinessId(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business>;

    /**
     * 
     * @summary Upload a budget file
     * @param {string} business_id 
     * @param {'weekly' | 'monthly'} resolution 
     * @param {number} year 
     * @param {Blob} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postBudgetRaw(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Upload a budget file
     */
    postBudget(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Change password
     * @param {ChangePasswordRequest} [ChangePasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postChangePasswordRaw(requestParameters: PostChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change password
     */
    postChangePassword(requestParameters: PostChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Create the respective Norma accounts for the selected foodics accounts
     * @param {InitialOnboardFoodicsUsersRequest} [InitialOnboardFoodicsUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postIntegrationsFoodicsAccountsInitialOnboardingRaw(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Create the respective Norma accounts for the selected foodics accounts
     */
    postIntegrationsFoodicsAccountsInitialOnboarding(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Invite Foodics accounts
     * @param {InviteFoodicsUsersRequest} [InviteFoodicsUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postInviteFoodicsAccountsRaw(requestParameters: PostInviteFoodicsAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Invite Foodics accounts
     */
    postInviteFoodicsAccounts(requestParameters: PostInviteFoodicsAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @param {LoginRequest} [LoginRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postLoginRaw(requestParameters: PostLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     */
    postLogin(requestParameters: PostLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse>;

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     */
    postLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Onboard users in business
     * @param {string} business_id 
     * @param {OnboardFoodicsUsersRequest} [OnboardFoodicsUsersRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postOnboardUsersToBusinessRaw(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Onboard users in business
     */
    postOnboardUsersToBusiness(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Request reset password
     * @param {RequestResetPasswordRequest} [RequestResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postRequestResetPasswordRaw(requestParameters: PostRequestResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Request reset password
     */
    postRequestResetPassword(requestParameters: PostRequestResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Reset password
     * @param {ResetPasswordRequest} [ResetPasswordRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postResetPasswordRaw(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>>;

    /**
     * Reset password
     */
    postResetPassword(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse>;

    /**
     * 
     * @summary Change business context
     * @param {ChangeBusinessContextRequest} [ChangeBusinessContextRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postSelfBusinessContextRaw(requestParameters: PostSelfBusinessContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change business context
     */
    postSelfBusinessContext(requestParameters: PostSelfBusinessContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Change enterprise web app liveboards order
     * @param {Array<LiveboardCategory>} [LiveboardCategory] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postSelfEnterpriseWebAppLiveboardsOrderRaw(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change enterprise web app liveboards order
     */
    postSelfEnterpriseWebAppLiveboardsOrder(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Change enterprise web app mode
     * @param {ChangeModeRequest} [ChangeModeRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    postSelfEnterpriseWebAppModeRaw(requestParameters: PostSelfEnterpriseWebAppModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Change enterprise web app mode
     */
    postSelfEnterpriseWebAppMode(requestParameters: PostSelfEnterpriseWebAppModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Unfavorite thoughtspot liveboard
     * @param {string} liveboard_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    unfavoriteThoughtspotLiveboardRaw(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Unfavorite thoughtspot liveboard
     */
    unfavoriteThoughtspotLiveboard(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

    /**
     * 
     * @summary Promote user to admin
     * @param {string} user_id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApiInterface
     */
    usersPromoteUserRaw(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>>;

    /**
     * Promote user to admin
     */
    usersPromoteUser(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void>;

}

/**
 * 
 */
export class DefaultApi extends runtime.BaseAPI implements DefaultApiInterface {

    /**
     * Delete user
     */
    async deleteUsersUserIdRaw(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['user_id'] == null) {
            throw new runtime.RequiredError(
                'user_id',
                'Required parameter "user_id" was null or undefined when calling deleteUsersUserId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['user_id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete user
     */
    async deleteUsersUserId(requestParameters: DeleteUsersUserIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteUsersUserIdRaw(requestParameters, initOverrides);
    }

    /**
     * Favorite thoughtspot liveboard to favorites
     */
    async favoriteThoughtspotLiveboardRaw(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['liveboard_id'] == null) {
            throw new runtime.RequiredError(
                'liveboard_id',
                'Required parameter "liveboard_id" was null or undefined when calling favoriteThoughtspotLiveboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['liveboard_id'] != null) {
            queryParameters['liveboard_id'] = requestParameters['liveboard_id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards/favorite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Favorite thoughtspot liveboard to favorites
     */
    async favoriteThoughtspotLiveboard(requestParameters: FavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.favoriteThoughtspotLiveboardRaw(requestParameters, initOverrides);
    }

    /**
     * Get net sales per app
     */
    async getBranchAppsNetSalesRaw(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling getBranchAppsNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchAppsNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/branches/{branchId}/apps/net-sales`.replace(`{${"branchId"}}`, encodeURIComponent(String(requestParameters['branchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchOrderTypesNetSales200ResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per app
     */
    async getBranchAppsNetSales(requestParameters: GetBranchAppsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response> {
        const response = await this.getBranchAppsNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get general metrics of a branch
     */
    async getBranchGeneralMetricsRaw(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchGeneralMetrics200Response>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling getBranchGeneralMetrics().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchGeneralMetrics().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/branches/{branchId}/general-metrics`.replace(`{${"branchId"}}`, encodeURIComponent(String(requestParameters['branchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchGeneralMetrics200ResponseFromJSON(jsonValue));
    }

    /**
     * Get general metrics of a branch
     */
    async getBranchGeneralMetrics(requestParameters: GetBranchGeneralMetricsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchGeneralMetrics200Response> {
        const response = await this.getBranchGeneralMetricsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales timeseries of a branch
     */
    async getBranchNetSalesTimeseriesRaw(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesNetSalesTimeseries200Response>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling getBranchNetSalesTimeseries().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchNetSalesTimeseries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/branches/{branchId}/timeseries/net-sales`.replace(`{${"branchId"}}`, encodeURIComponent(String(requestParameters['branchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchesNetSalesTimeseries200ResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales timeseries of a branch
     */
    async getBranchNetSalesTimeseries(requestParameters: GetBranchNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesNetSalesTimeseries200Response> {
        const response = await this.getBranchNetSalesTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales per order source
     */
    async getBranchOrderSourcesNetSalesRaw(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling getBranchOrderSourcesNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchOrderSourcesNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/branches/{branchId}/order-sources/net-sales`.replace(`{${"branchId"}}`, encodeURIComponent(String(requestParameters['branchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchOrderTypesNetSales200ResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per order source
     */
    async getBranchOrderSourcesNetSales(requestParameters: GetBranchOrderSourcesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response> {
        const response = await this.getBranchOrderSourcesNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales per order tag
     */
    async getBranchOrderTagsNetSalesRaw(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling getBranchOrderTagsNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchOrderTagsNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/branches/{branchId}/order-tags/net-sales`.replace(`{${"branchId"}}`, encodeURIComponent(String(requestParameters['branchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchOrderTypesNetSales200ResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per order tag
     */
    async getBranchOrderTagsNetSales(requestParameters: GetBranchOrderTagsNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response> {
        const response = await this.getBranchOrderTagsNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales per order type
     */
    async getBranchOrderTypesNetSalesRaw(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchOrderTypesNetSales200Response>> {
        if (requestParameters['branchId'] == null) {
            throw new runtime.RequiredError(
                'branchId',
                'Required parameter "branchId" was null or undefined when calling getBranchOrderTypesNetSales().'
            );
        }

        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchOrderTypesNetSales().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/branches/{branchId}/order-types/net-sales`.replace(`{${"branchId"}}`, encodeURIComponent(String(requestParameters['branchId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchOrderTypesNetSales200ResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales per order type
     */
    async getBranchOrderTypesNetSales(requestParameters: GetBranchOrderTypesNetSalesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchOrderTypesNetSales200Response> {
        const response = await this.getBranchOrderTypesNetSalesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get net sales timeseries of user\'s branches
     */
    async getBranchesNetSalesTimeseriesRaw(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesNetSalesTimeseries200Response>> {
        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchesNetSalesTimeseries().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report/timeseries/net-sales`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchesNetSalesTimeseries200ResponseFromJSON(jsonValue));
    }

    /**
     * Get net sales timeseries of user\'s branches
     */
    async getBranchesNetSalesTimeseries(requestParameters: GetBranchesNetSalesTimeseriesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesNetSalesTimeseries200Response> {
        const response = await this.getBranchesNetSalesTimeseriesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get report of user\'s branches
     */
    async getBranchesReportRaw(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBranchesReport200Response>> {
        if (requestParameters['time_period'] == null) {
            throw new runtime.RequiredError(
                'time_period',
                'Required parameter "time_period" was null or undefined when calling getBranchesReport().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['time_period'] != null) {
            queryParameters['time-period'] = requestParameters['time_period'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/branches-report`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBranchesReport200ResponseFromJSON(jsonValue));
    }

    /**
     * Get report of user\'s branches
     */
    async getBranchesReport(requestParameters: GetBranchesReportRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBranchesReport200Response> {
        const response = await this.getBranchesReportRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a budget
     */
    async getBudgetRaw(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<BudgetResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBudget().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling getBudget().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling getBudget().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BudgetResponseFromJSON(jsonValue));
    }

    /**
     * Get a budget
     */
    async getBudget(requestParameters: GetBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<BudgetResponse> {
        const response = await this.getBudgetRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a budget template file
     */
    async getBudgetTemplateRaw(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBudgetTemplate().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling getBudgetTemplate().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling getBudgetTemplate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}/template`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a budget template file
     */
    async getBudgetTemplate(requestParameters: GetBudgetTemplateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getBudgetTemplateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a budget in XLSX format
     */
    async getBudgetXlsxRaw(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Blob>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getBudgetXlsx().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling getBudgetXlsx().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling getBudgetXlsx().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}/xlsx`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.BlobApiResponse(response);
    }

    /**
     * Get a budget in XLSX format
     */
    async getBudgetXlsx(requestParameters: GetBudgetXlsxRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Blob> {
        const response = await this.getBudgetXlsxRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get businesses of user
     */
    async getBusinessesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetBusinessesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetBusinessesResponseFromJSON(jsonValue));
    }

    /**
     * Get businesses of user
     */
    async getBusinesses(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetBusinessesResponse> {
        const response = await this.getBusinessesRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * Initiate Foodics OAuth2 flow
     */
    async getFoodicsAuthRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/foodics-auth`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint is used to install a new Foodics Business: create a new Norma Organization and Business
     * Initiate Foodics OAuth2 flow
     */
    async getFoodicsAuth(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getFoodicsAuthRaw(initOverrides);
    }

    /**
     * Get impersonator data
     */
    async getImpersonatorDataRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<MobileImpersonatorResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ops/mobile/impersonator`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => MobileImpersonatorResponseFromJSON(jsonValue));
    }

    /**
     * Get impersonator data
     */
    async getImpersonatorData(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<MobileImpersonatorResponse> {
        const response = await this.getImpersonatorDataRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get inactive users by business
     */
    async getInactiveUsersByBusinessRaw(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInactiveFoodicsUsersResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getInactiveUsersByBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/{business_id}/accounts/inactive`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInactiveFoodicsUsersResponseFromJSON(jsonValue));
    }

    /**
     * Get inactive users by business
     */
    async getInactiveUsersByBusiness(requestParameters: GetInactiveUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInactiveFoodicsUsersResponse> {
        const response = await this.getInactiveUsersByBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the foodics accounts that are eligible for the initial onboarding
     */
    async getIntegrationsFoodicsAccountsInitialOnboardingRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetInitialOnboardingFoodicsAccountsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Foodics_Initial_Onboarding", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/accounts/initial-onboarding`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetInitialOnboardingFoodicsAccountsResponseFromJSON(jsonValue));
    }

    /**
     * Get the foodics accounts that are eligible for the initial onboarding
     */
    async getIntegrationsFoodicsAccountsInitialOnboarding(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetInitialOnboardingFoodicsAccountsResponse> {
        const response = await this.getIntegrationsFoodicsAccountsInitialOnboardingRaw(initOverrides);
        return await response.value();
    }

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     */
    async getIntegrationsFoodicsOnboardBusinessRaw(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling getIntegrationsFoodicsOnboardBusiness().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/onboard-business`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * This endpoint is used to onboard a Foodics Business inside an existing Norma Organization
     * Initiate Foodics OAuth2 flow for Business onboarding in existing Organization
     */
    async getIntegrationsFoodicsOnboardBusiness(requestParameters: GetIntegrationsFoodicsOnboardBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.getIntegrationsFoodicsOnboardBusinessRaw(requestParameters, initOverrides);
    }

    /**
     * Mint a short lived token that allows onboarding a new business to an existing org
     */
    async getIntegrationsFoodicsOnboardBusinessRequestRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetOnboardBusinessTokenResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/onboard-business-request`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetOnboardBusinessTokenResponseFromJSON(jsonValue));
    }

    /**
     * Mint a short lived token that allows onboarding a new business to an existing org
     */
    async getIntegrationsFoodicsOnboardBusinessRequest(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetOnboardBusinessTokenResponse> {
        const response = await this.getIntegrationsFoodicsOnboardBusinessRequestRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get the users of the logged in user\'s organization
     */
    async getOrganizationUsersRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByOrganizationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/organization/users`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersByOrganizationResponseFromJSON(jsonValue));
    }

    /**
     * Get the users of the logged in user\'s organization
     */
    async getOrganizationUsers(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByOrganizationResponse> {
        const response = await this.getOrganizationUsersRaw(initOverrides);
        return await response.value();
    }

    /**
     * Refresh auth
     */
    async getRefreshRaw(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Token>> {
        if (requestParameters['token'] == null) {
            throw new runtime.RequiredError(
                'token',
                'Required parameter "token" was null or undefined when calling getRefresh().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['token'] != null) {
            queryParameters['token'] = requestParameters['token'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/refresh`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => TokenFromJSON(jsonValue));
    }

    /**
     * Refresh auth
     */
    async getRefresh(requestParameters: GetRefreshRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Token> {
        const response = await this.getRefreshRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update self
     * Self endpoint
     */
    async getSelfRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<UserAggregate>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserAggregateFromJSON(jsonValue));
    }

    /**
     * Update self
     * Self endpoint
     */
    async getSelf(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<UserAggregate> {
        const response = await this.getSelfRaw(initOverrides);
        return await response.value();
    }

    /**
     * Returns information about the status of the biserver service
     */
    async getStatusRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<StatusResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusResponseFromJSON(jsonValue));
    }

    /**
     * Returns information about the status of the biserver service
     */
    async getStatus(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<StatusResponse> {
        const response = await this.getStatusRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot liveboards
     */
    async getThoughtspotLiveboardsRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotLiveboardsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThoughtspotLiveboardsResponseFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot liveboards
     */
    async getThoughtspotLiveboards(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotLiveboardsResponse> {
        const response = await this.getThoughtspotLiveboardsRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot schedules
     */
    async getThoughtspotSchedulesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotSchedulesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/schedules`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThoughtspotSchedulesResponseFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot schedules
     */
    async getThoughtspotSchedules(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotSchedulesResponse> {
        const response = await this.getThoughtspotSchedulesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot data sources for the search data feature
     */
    async getThoughtspotSearchDataDataSourcesRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetThoughtspotDataSourcesResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/search-data/data-sources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetThoughtspotDataSourcesResponseFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot data sources for the search data feature
     */
    async getThoughtspotSearchDataDataSources(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetThoughtspotDataSourcesResponse> {
        const response = await this.getThoughtspotSearchDataDataSourcesRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get thoughtspot token
     */
    async getThoughtspotTokenRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ThoughtspotToken>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/token`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ThoughtspotTokenFromJSON(jsonValue));
    }

    /**
     * Get thoughtspot token
     */
    async getThoughtspotToken(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ThoughtspotToken> {
        const response = await this.getThoughtspotTokenRaw(initOverrides);
        return await response.value();
    }

    /**
     * Get users by business
     */
    async getUsersByBusinessRaw(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetUsersByBusinessResponse>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling getUsersByBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{business_id}/users`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetUsersByBusinessResponseFromJSON(jsonValue));
    }

    /**
     * Get users by business
     */
    async getUsersByBusiness(requestParameters: GetUsersByBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetUsersByBusinessResponse> {
        const response = await this.getUsersByBusinessRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Impersonate another user
     */
    async impersonateRaw(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ImpersonateResponse>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling impersonate().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['id'] != null) {
            queryParameters['id'] = requestParameters['id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/ops/impersonate`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImpersonateResponseFromJSON(jsonValue));
    }

    /**
     * Impersonate another user
     */
    async impersonate(requestParameters: ImpersonateRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ImpersonateResponse> {
        const response = await this.impersonateRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update business
     */
    async patchBusinessesBusinessIdRaw(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Business>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling patchBusinessesBusinessId().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/businesses/{business_id}`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateBusinessRequestToJSON(requestParameters['UpdateBusinessRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BusinessFromJSON(jsonValue));
    }

    /**
     * Update business
     */
    async patchBusinessesBusinessId(requestParameters: PatchBusinessesBusinessIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Business> {
        const response = await this.patchBusinessesBusinessIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Upload a budget file
     */
    async postBudgetRaw(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling postBudget().'
            );
        }

        if (requestParameters['resolution'] == null) {
            throw new runtime.RequiredError(
                'resolution',
                'Required parameter "resolution" was null or undefined when calling postBudget().'
            );
        }

        if (requestParameters['year'] == null) {
            throw new runtime.RequiredError(
                'year',
                'Required parameter "year" was null or undefined when calling postBudget().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['resolution'] != null) {
            queryParameters['resolution'] = requestParameters['resolution'];
        }

        if (requestParameters['year'] != null) {
            queryParameters['year'] = requestParameters['year'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/budget/{business_id}/upload`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'] as any,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Upload a budget file
     */
    async postBudget(requestParameters: PostBudgetRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postBudgetRaw(requestParameters, initOverrides);
    }

    /**
     * Change password
     */
    async postChangePasswordRaw(requestParameters: PostChangePasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangePasswordRequestToJSON(requestParameters['ChangePasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change password
     */
    async postChangePassword(requestParameters: PostChangePasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postChangePasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Create the respective Norma accounts for the selected foodics accounts
     */
    async postIntegrationsFoodicsAccountsInitialOnboardingRaw(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Foodics_Initial_Onboarding", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/accounts/initial-onboarding`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InitialOnboardFoodicsUsersRequestToJSON(requestParameters['InitialOnboardFoodicsUsersRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create the respective Norma accounts for the selected foodics accounts
     */
    async postIntegrationsFoodicsAccountsInitialOnboarding(requestParameters: PostIntegrationsFoodicsAccountsInitialOnboardingRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postIntegrationsFoodicsAccountsInitialOnboardingRaw(requestParameters, initOverrides);
    }

    /**
     * Invite Foodics accounts
     */
    async postInviteFoodicsAccountsRaw(requestParameters: PostInviteFoodicsAccountsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/accounts/invite`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: InviteFoodicsUsersRequestToJSON(requestParameters['InviteFoodicsUsersRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Invite Foodics accounts
     */
    async postInviteFoodicsAccounts(requestParameters: PostInviteFoodicsAccountsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postInviteFoodicsAccountsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async postLoginRaw(requestParameters: PostLoginRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/login`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LoginRequestToJSON(requestParameters['LoginRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     */
    async postLogin(requestParameters: PostLoginRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.postLoginRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async postLogoutRaw(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/logout`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async postLogout(initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postLogoutRaw(initOverrides);
    }

    /**
     * Onboard users in business
     */
    async postOnboardUsersToBusinessRaw(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['business_id'] == null) {
            throw new runtime.RequiredError(
                'business_id',
                'Required parameter "business_id" was null or undefined when calling postOnboardUsersToBusiness().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/foodics/{business_id}/accounts/onboard`.replace(`{${"business_id"}}`, encodeURIComponent(String(requestParameters['business_id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OnboardFoodicsUsersRequestToJSON(requestParameters['OnboardFoodicsUsersRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Onboard users in business
     */
    async postOnboardUsersToBusiness(requestParameters: PostOnboardUsersToBusinessRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postOnboardUsersToBusinessRaw(requestParameters, initOverrides);
    }

    /**
     * Request reset password
     */
    async postRequestResetPasswordRaw(requestParameters: PostRequestResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/request-reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RequestResetPasswordRequestToJSON(requestParameters['RequestResetPasswordRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Request reset password
     */
    async postRequestResetPassword(requestParameters: PostRequestResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postRequestResetPasswordRaw(requestParameters, initOverrides);
    }

    /**
     * Reset password
     */
    async postResetPasswordRaw(requestParameters: PostResetPasswordRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LoginResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        const response = await this.request({
            path: `/reset-password`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ResetPasswordRequestToJSON(requestParameters['ResetPasswordRequest']),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LoginResponseFromJSON(jsonValue));
    }

    /**
     * Reset password
     */
    async postResetPassword(requestParameters: PostResetPasswordRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LoginResponse> {
        const response = await this.postResetPasswordRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Change business context
     */
    async postSelfBusinessContextRaw(requestParameters: PostSelfBusinessContextRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/business-context`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeBusinessContextRequestToJSON(requestParameters['ChangeBusinessContextRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change business context
     */
    async postSelfBusinessContext(requestParameters: PostSelfBusinessContextRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSelfBusinessContextRaw(requestParameters, initOverrides);
    }

    /**
     * Change enterprise web app liveboards order
     */
    async postSelfEnterpriseWebAppLiveboardsOrderRaw(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/enterprise-web-app/liveboards-order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['LiveboardCategory']!.map(LiveboardCategoryToJSON),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change enterprise web app liveboards order
     */
    async postSelfEnterpriseWebAppLiveboardsOrder(requestParameters: PostSelfEnterpriseWebAppLiveboardsOrderRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSelfEnterpriseWebAppLiveboardsOrderRaw(requestParameters, initOverrides);
    }

    /**
     * Change enterprise web app mode
     */
    async postSelfEnterpriseWebAppModeRaw(requestParameters: PostSelfEnterpriseWebAppModeRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/self/enterprise-web-app/mode`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ChangeModeRequestToJSON(requestParameters['ChangeModeRequest']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Change enterprise web app mode
     */
    async postSelfEnterpriseWebAppMode(requestParameters: PostSelfEnterpriseWebAppModeRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.postSelfEnterpriseWebAppModeRaw(requestParameters, initOverrides);
    }

    /**
     * Unfavorite thoughtspot liveboard
     */
    async unfavoriteThoughtspotLiveboardRaw(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['liveboard_id'] == null) {
            throw new runtime.RequiredError(
                'liveboard_id',
                'Required parameter "liveboard_id" was null or undefined when calling unfavoriteThoughtspotLiveboard().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['liveboard_id'] != null) {
            queryParameters['liveboard_id'] = requestParameters['liveboard_id'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/integrations/thoughtspot/liveboards/favorite`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Unfavorite thoughtspot liveboard
     */
    async unfavoriteThoughtspotLiveboard(requestParameters: UnfavoriteThoughtspotLiveboardRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.unfavoriteThoughtspotLiveboardRaw(requestParameters, initOverrides);
    }

    /**
     * Promote user to admin
     */
    async usersPromoteUserRaw(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['user_id'] == null) {
            throw new runtime.RequiredError(
                'user_id',
                'Required parameter "user_id" was null or undefined when calling usersPromoteUser().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("Norma_BI_token", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/users/{user_id}/promote`.replace(`{${"user_id"}}`, encodeURIComponent(String(requestParameters['user_id']))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Promote user to admin
     */
    async usersPromoteUser(requestParameters: UsersPromoteUserRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.usersPromoteUserRaw(requestParameters, initOverrides);
    }

}

/**
 * @export
 */
export const GetBranchAppsNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchAppsNetSalesTimePeriodEnum = typeof GetBranchAppsNetSalesTimePeriodEnum[keyof typeof GetBranchAppsNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchGeneralMetricsTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchGeneralMetricsTimePeriodEnum = typeof GetBranchGeneralMetricsTimePeriodEnum[keyof typeof GetBranchGeneralMetricsTimePeriodEnum];
/**
 * @export
 */
export const GetBranchNetSalesTimeseriesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchNetSalesTimeseriesTimePeriodEnum = typeof GetBranchNetSalesTimeseriesTimePeriodEnum[keyof typeof GetBranchNetSalesTimeseriesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchOrderSourcesNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchOrderSourcesNetSalesTimePeriodEnum = typeof GetBranchOrderSourcesNetSalesTimePeriodEnum[keyof typeof GetBranchOrderSourcesNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchOrderTagsNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchOrderTagsNetSalesTimePeriodEnum = typeof GetBranchOrderTagsNetSalesTimePeriodEnum[keyof typeof GetBranchOrderTagsNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchOrderTypesNetSalesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchOrderTypesNetSalesTimePeriodEnum = typeof GetBranchOrderTypesNetSalesTimePeriodEnum[keyof typeof GetBranchOrderTypesNetSalesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchesNetSalesTimeseriesTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchesNetSalesTimeseriesTimePeriodEnum = typeof GetBranchesNetSalesTimeseriesTimePeriodEnum[keyof typeof GetBranchesNetSalesTimeseriesTimePeriodEnum];
/**
 * @export
 */
export const GetBranchesReportTimePeriodEnum = {
    today: 'today',
    yesterday: 'yesterday',
    this_week: 'this-week',
    last_week: 'last-week',
    this_month: 'this-month',
    last_month: 'last-month',
    this_year: 'this-year'
} as const;
export type GetBranchesReportTimePeriodEnum = typeof GetBranchesReportTimePeriodEnum[keyof typeof GetBranchesReportTimePeriodEnum];
/**
 * @export
 */
export const GetBudgetResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type GetBudgetResolutionEnum = typeof GetBudgetResolutionEnum[keyof typeof GetBudgetResolutionEnum];
/**
 * @export
 */
export const GetBudgetTemplateResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type GetBudgetTemplateResolutionEnum = typeof GetBudgetTemplateResolutionEnum[keyof typeof GetBudgetTemplateResolutionEnum];
/**
 * @export
 */
export const GetBudgetXlsxResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type GetBudgetXlsxResolutionEnum = typeof GetBudgetXlsxResolutionEnum[keyof typeof GetBudgetXlsxResolutionEnum];
/**
 * @export
 */
export const PostBudgetResolutionEnum = {
    weekly: 'weekly',
    monthly: 'monthly'
} as const;
export type PostBudgetResolutionEnum = typeof PostBudgetResolutionEnum[keyof typeof PostBudgetResolutionEnum];
