export function Star() {
  return (
    <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.48346 3.33286L4.41462 0.957116C4.62441 0.421847 5.37569 0.421847 5.58549 0.957116L6.51664 3.33286L9.12362 3.46597C9.70487 3.49565 9.93832 4.23654 9.48066 4.59908L7.466 6.19497L8.13563 8.63848C8.28883 9.1975 7.67958 9.65383 7.19309 9.34447L5.00005 7.94989L2.80702 9.34447C2.32053 9.65383 1.71128 9.1975 1.86447 8.63848L2.53411 6.19497L0.519444 4.59908C0.0617847 4.23654 0.295241 3.49565 0.876485 3.46597L3.48346 3.33286Z"
        fill="currentColor"
      />
    </svg>
  );
}
