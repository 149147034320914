import { Outlet } from "react-router";
import { MobileDeviceGuard } from "./guards/MobileDeviceGuard";
import { PostHogInstrumentation, WithPostHog } from "./posthog";

export function Root() {
  return (
    <WithPostHog>
      <MobileDeviceGuard>
        <PostHogInstrumentation>
          <Outlet />
        </PostHogInstrumentation>
      </MobileDeviceGuard>
    </WithPostHog>
  );
}
