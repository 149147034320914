import React from "react";

export function Comparison() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33325 11.3333V11.2332C1.33325 11.0222 1.33325 10.9167 1.34939 10.8139C1.36372 10.7227 1.38749 10.6332 1.42033 10.5468C1.45731 10.4496 1.50965 10.358 1.61433 10.1748L3.99992 6M1.33325 11.3333C1.33325 12.8061 2.52716 14 3.99992 14C5.47268 14 6.66659 12.8061 6.66659 11.3333M1.33325 11.3333V11.2C1.33325 11.0133 1.33325 10.92 1.36958 10.8487C1.40154 10.7859 1.45253 10.735 1.51526 10.703C1.58656 10.6667 1.6799 10.6667 1.86659 10.6667H6.13325C6.31994 10.6667 6.41328 10.6667 6.48458 10.703C6.5473 10.735 6.5983 10.7859 6.63025 10.8487C6.66659 10.92 6.66659 11.0133 6.66659 11.2V11.3333M3.99992 6L6.3855 10.1748C6.49019 10.358 6.54253 10.4496 6.57951 10.5468C6.61234 10.6332 6.63612 10.7227 6.65045 10.8139C6.66659 10.9167 6.66659 11.0222 6.66659 11.2332V11.3333M3.99992 6L11.9999 4.66667M9.33325 10V9.89987C9.33325 9.68888 9.33325 9.58338 9.34939 9.4806C9.36372 9.38934 9.38749 9.29982 9.42033 9.21348C9.45731 9.11623 9.50965 9.02463 9.61433 8.84144L11.9999 4.66667M9.33325 10C9.33325 11.4728 10.5272 12.6667 11.9999 12.6667C13.4727 12.6667 14.6666 11.4728 14.6666 10M9.33325 10V9.86667C9.33325 9.67998 9.33325 9.58664 9.36958 9.51534C9.40154 9.45262 9.45253 9.40162 9.51526 9.36966C9.58656 9.33333 9.6799 9.33333 9.86659 9.33333H14.1333C14.3199 9.33333 14.4133 9.33333 14.4846 9.36966C14.5473 9.40162 14.5983 9.45262 14.6303 9.51534C14.6666 9.58664 14.6666 9.67998 14.6666 9.86667V10M11.9999 4.66667L14.3855 8.84144C14.4902 9.02463 14.5425 9.11623 14.5795 9.21348C14.6123 9.29982 14.6361 9.38934 14.6504 9.4806C14.6666 9.58338 14.6666 9.68888 14.6666 9.89987V10M7.99992 2V5.33333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
