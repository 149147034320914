import React from "react";

export function AlignVerticalCenter02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66675 12V4C6.66675 3.37874 6.66675 3.06812 6.56525 2.82309C6.42993 2.49638 6.17036 2.23682 5.84366 2.10149C5.59863 2 5.288 2 4.66675 2C4.04549 2 3.73486 2 3.48984 2.10149C3.16313 2.23682 2.90357 2.49638 2.76824 2.82309C2.66675 3.06812 2.66675 3.37874 2.66675 4V12C2.66675 12.6213 2.66675 12.9319 2.76824 13.1769C2.90357 13.5036 3.16313 13.7632 3.48984 13.8985C3.73486 14 4.04549 14 4.66675 14C5.288 14 5.59863 14 5.84366 13.8985C6.17036 13.7632 6.42993 13.5036 6.56525 13.1769C6.66675 12.9319 6.66675 12.6213 6.66675 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 10.6667V5.33333C13.3334 4.71208 13.3334 4.40145 13.2319 4.15642C13.0966 3.82972 12.837 3.57015 12.5103 3.43483C12.2653 3.33333 11.9547 3.33333 11.3334 3.33333C10.7122 3.33333 10.4015 3.33333 10.1565 3.43483C9.8298 3.57015 9.57023 3.82972 9.43491 4.15642C9.33341 4.40145 9.33341 4.71208 9.33341 5.33333V10.6667C9.33341 11.2879 9.33341 11.5986 9.43491 11.8436C9.57023 12.1703 9.8298 12.4298 10.1565 12.5652C10.4015 12.6667 10.7122 12.6667 11.3334 12.6667C11.9547 12.6667 12.2653 12.6667 12.5103 12.5652C12.837 12.4298 13.0966 12.1703 13.2319 11.8436C13.3334 11.5986 13.3334 11.2879 13.3334 10.6667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
