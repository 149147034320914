export function isHomePath(pathname: string) {
  return pathname.startsWith("/home");
}

export function isLiveboardPath(pathname: string) {
  return pathname.includes("pinboard");
}

export function getLiveboardIdFromPath(pathname: string) {
  const parts = pathname.split("/");
  const pinboardIndex = parts.findIndex((p) => p === "pinboard");
  if (pinboardIndex === -1) {
    return null;
  }
  return parts[pinboardIndex + 1];
}

export function isAnswersPath(pathname: string) {
  return pathname.includes("/answers") || pathname.includes("/saved-answer");
}

export function isInsightsPath(pathname: string) {
  return (
    pathname.startsWith("/insights") &&
    !pathname.includes("pinboard") &&
    !pathname.includes("answer")
  );
}

export function isSearchDataPath(pathname: string) {
  return pathname.endsWith("/answer");
}
