import React from "react";

export function Fi_5115617() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11890)">
        <path
          d="M10.7295 1C8.37477 1 6.45904 2.91729 6.45904 5.27394C6.45904 5.77384 6.54553 6.25383 6.70385 6.70011C6.25561 6.53976 5.7731 6.45215 5.27049 6.45215C2.91573 6.45215 1 8.36941 1 10.7261C1 13.0827 2.91573 15 5.27049 15C7.62523 15 9.54096 13.0827 9.54096 10.7261C9.54096 10.2262 9.45447 9.74617 9.29615 9.29989C9.74439 9.46024 10.2269 9.54785 10.7295 9.54785C13.0843 9.54785 15 7.63059 15 5.27394C15 2.91729 13.0843 1 10.7295 1ZM8.72064 10.7261C8.72064 12.6304 7.17291 14.1797 5.27049 14.1797C3.36805 14.1797 1.82031 12.6304 1.82031 10.7261C1.82031 8.82173 3.36805 7.27244 5.27049 7.27244C7.14797 7.27246 8.72064 8.84891 8.72064 10.7261ZM10.7295 8.72754C8.85203 8.72754 7.27936 7.15109 7.27936 5.27391C7.27936 3.36961 8.82709 1.82031 10.7295 1.82031C12.6319 1.82031 14.1797 3.36961 14.1797 5.27394C14.1797 7.17827 12.6319 8.72754 10.7295 8.72754Z"
          fill="currentColor"
        />
        <path
          d="M4.16025 8.38535C3.48177 8.38535 2.92979 8.93734 2.92979 9.61582C2.92979 10.2943 3.48177 10.8463 4.16025 10.8463C4.83873 10.8463 5.39072 10.2943 5.39072 9.61582C5.39072 8.93734 4.83873 8.38535 4.16025 8.38535ZM4.16025 10.026C3.93409 10.026 3.7501 9.84198 3.7501 9.61582C3.7501 9.38966 3.93409 9.20567 4.16025 9.20567C4.38641 9.20567 4.57041 9.38966 4.57041 9.61582C4.57041 9.84198 4.38641 10.026 4.16025 10.026Z"
          fill="currentColor"
        />
        <path
          d="M6.3807 10.6058C5.70222 10.6058 5.15023 11.1578 5.15023 11.8363C5.15023 12.5148 5.70222 13.0668 6.3807 13.0668C7.05918 13.0668 7.61117 12.5148 7.61117 11.8363C7.61117 11.1578 7.05918 10.6058 6.3807 10.6058ZM6.3807 12.2465C6.15454 12.2465 5.97055 12.0625 5.97055 11.8363C5.97055 11.6101 6.15454 11.4261 6.3807 11.4261C6.60686 11.4261 6.79086 11.6101 6.79086 11.8363C6.79086 12.0625 6.60686 12.2465 6.3807 12.2465Z"
          fill="currentColor"
        />
        <path
          d="M10.1271 3.14257L9.65263 3.61702L9.17819 3.14257L8.59814 3.72262L9.07259 4.19706L8.59814 4.67147L9.17819 5.25152L9.65263 4.77707L10.1271 5.25152L10.7071 4.67147L10.2327 4.19706L10.7071 3.72262L10.1271 3.14257Z"
          fill="currentColor"
        />
        <path
          d="M12.2809 5.29633L11.8064 5.77077L11.3319 5.29633L10.7519 5.87635L11.2264 6.35082L10.7519 6.82526L11.3319 7.40527L11.8064 6.93083L12.2809 7.40527L12.8609 6.82526L12.3864 6.35082L12.8609 5.87635L12.2809 5.29633Z"
          fill="currentColor"
        />
        <path
          d="M10.7295 1C8.37477 1 6.45904 2.91729 6.45904 5.27394C6.45904 5.77384 6.54553 6.25383 6.70385 6.70011C6.25561 6.53976 5.7731 6.45215 5.27049 6.45215C2.91573 6.45215 1 8.36941 1 10.7261C1 13.0827 2.91573 15 5.27049 15C7.62523 15 9.54096 13.0827 9.54096 10.7261C9.54096 10.2262 9.45447 9.74617 9.29615 9.29989C9.74439 9.46024 10.2269 9.54785 10.7295 9.54785C13.0843 9.54785 15 7.63059 15 5.27394C15 2.91729 13.0843 1 10.7295 1ZM8.72064 10.7261C8.72064 12.6304 7.17291 14.1797 5.27049 14.1797C3.36805 14.1797 1.82031 12.6304 1.82031 10.7261C1.82031 8.82173 3.36805 7.27244 5.27049 7.27244C7.14797 7.27246 8.72064 8.84891 8.72064 10.7261ZM10.7295 8.72754C8.85203 8.72754 7.27936 7.15109 7.27936 5.27391C7.27936 3.36961 8.82709 1.82031 10.7295 1.82031C12.6319 1.82031 14.1797 3.36961 14.1797 5.27394C14.1797 7.17827 12.6319 8.72754 10.7295 8.72754Z"
          stroke="currentColor"
          strokeWidth="0.3"
        />
        <path
          d="M4.16025 8.38535C3.48177 8.38535 2.92979 8.93734 2.92979 9.61582C2.92979 10.2943 3.48177 10.8463 4.16025 10.8463C4.83873 10.8463 5.39072 10.2943 5.39072 9.61582C5.39072 8.93734 4.83873 8.38535 4.16025 8.38535ZM4.16025 10.026C3.93409 10.026 3.7501 9.84198 3.7501 9.61582C3.7501 9.38966 3.93409 9.20567 4.16025 9.20567C4.38641 9.20567 4.57041 9.38966 4.57041 9.61582C4.57041 9.84198 4.38641 10.026 4.16025 10.026Z"
          stroke="currentColor"
          strokeWidth="0.3"
        />
        <path
          d="M6.3807 10.6058C5.70222 10.6058 5.15023 11.1578 5.15023 11.8363C5.15023 12.5148 5.70222 13.0668 6.3807 13.0668C7.05918 13.0668 7.61117 12.5148 7.61117 11.8363C7.61117 11.1578 7.05918 10.6058 6.3807 10.6058ZM6.3807 12.2465C6.15454 12.2465 5.97055 12.0625 5.97055 11.8363C5.97055 11.6101 6.15454 11.4261 6.3807 11.4261C6.60686 11.4261 6.79086 11.6101 6.79086 11.8363C6.79086 12.0625 6.60686 12.2465 6.3807 12.2465Z"
          stroke="currentColor"
          strokeWidth="0.3"
        />
        <path
          d="M10.1271 3.14257L9.65263 3.61702L9.17819 3.14257L8.59814 3.72262L9.07259 4.19706L8.59814 4.67147L9.17819 5.25152L9.65263 4.77707L10.1271 5.25152L10.7071 4.67147L10.2327 4.19706L10.7071 3.72262L10.1271 3.14257Z"
          stroke="currentColor"
          strokeWidth="0.3"
        />
        <path
          d="M12.2809 5.29633L11.8064 5.77077L11.3319 5.29633L10.7519 5.87635L11.2264 6.35082L10.7519 6.82526L11.3319 7.40527L11.8064 6.93083L12.2809 7.40527L12.8609 6.82526L12.3864 6.35082L12.8609 5.87635L12.2809 5.29633Z"
          stroke="currentColor"
          strokeWidth="0.3"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11890">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
