import React from "react";

export type CardProps = React.PropsWithChildren<
  {
    cardTitle: React.ReactNode;
    Footer: React.ReactNode;
    footerSeparator?: boolean;
  } & React.HTMLAttributes<HTMLDivElement>
>;

export function Card({ cardTitle, Footer, children, footerSeparator, ...divProps }: CardProps) {
  return (
    <div
      {...divProps}
      className={`bg-[#F6F8FA] flex flex-col overflow-hidden ${divProps.className || ""}`}
    >
      <div className="px-8 py-4 content-center">
        <span className="section-label">{cardTitle}</span>
      </div>

      <div className="flex-1 bg-white flex flex-col px-8 overflow-auto">{children}</div>

      <div className="flex flex-col">
        {footerSeparator && <div className="w-full h-[1px] bg-backgroundGray" />}
        <div className="bg-white px-6 py-6 flex justify-end gap-4">{Footer}</div>
      </div>
    </div>
  );
}
