import React from "react";
import { SvgIcon } from "../svg";
import clsx from "clsx";

export type FavoriteToggleProps = {
  showFavoritesOnly: boolean;
  setShowFavoritesOnly: React.Dispatch<React.SetStateAction<boolean>>;
};

export const FavoriteToggle = ({
  showFavoritesOnly,
  setShowFavoritesOnly,
}: FavoriteToggleProps) => {
  return (
    <button
      className={clsx("h-full flex-1 flex-shrink min-w-0 max-w-fit overflow-hidden")}
      onClick={() => setShowFavoritesOnly((curr) => !curr)}
    >
      <div
        className={clsx(
          "h-full w-min px-4 py-3 rounded-lg  inline-flex items-center justify-center",
          showFavoritesOnly
            ? "bg-secondaryColor text-white"
            : "bg-white text-feintGray hover:text-dark",
        )}
      >
        <SvgIcon icon={"FavoriteEmpty"} />
      </div>
    </button>
  );
};
