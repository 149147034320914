import React from "react";
import { useAPI } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import { OnboardingOrchestrator } from "../components/OnboardingOrchestrator";
import { useOnboardingStore } from "./store";
import { useParams } from "react-router";
import { customToast } from "../../../utils/customToast";

export function OnboardingInExistingOrganization() {
  const { businessId } = useParams<{ businessId: string }>();
  if (!businessId || businessId === "") {
    throw new Error(
      "business_id param not found, PostOnboardingRedirectsHandler should not allow that",
    );
  }

  const api = useAPI();
  const onboardingStore = useOnboardingStore();

  const doFinishStep = useMutation({
    mutationFn: () => {
      const accountIds = Object.entries(onboardingStore.selectedAccountIds)
        .filter(([, isSelected]) => isSelected)
        .map(([id]) => id);

      if (!accountIds.length) {
        return Promise.resolve();
      }

      return api.postOnboardUsersToBusiness({
        business_id: businessId,
        OnboardFoodicsUsersRequest: { ext_ids: accountIds },
      });
    },
    onSuccess: () => {
      customToast(
        "Business added. We may need up to 30 minutes to retrieve its data.",
        "success",
        "bottom",
      );
    },
  });

  return (
    <OnboardingOrchestrator
      stages={[
        {
          path: "start",
        },
        {
          path: "users",
        },
        {
          path: "done",
        },
      ]}
      finishMutation={doFinishStep}
      cleanup={() => onboardingStore.reset()}
      onDone={{ navigateTo: "/settings/businesses" }}
      onCancel={{ navigateTo: "/settings/businesses" }}
      onError={{ navigateTo: "/settings/businesses" }}
    />
  );
}
