export function Liveboard() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.3999 7.59354C10.3999 6.99445 10.8775 6.50879 11.4666 6.50879H14.9332C15.5223 6.50879 15.9999 6.99445 15.9999 7.59354V14.9156C15.9999 15.5147 15.5223 16.0003 14.9332 16.0003H11.4666C10.8775 16.0003 10.3999 15.5147 10.3999 14.9156L10.3999 7.59354Z"
        fill="currentColor"
      />
      <path
        d="M0 1.08475C0 0.485658 0 0 1.06667 0H8.26668C8.85579 0 9.33335 0.485658 9.33335 1.08475V14.9153C9.33335 15.5144 8.85579 16 8.26668 16H1.06667C0.477564 16 0 15.5144 0 14.9153L0 1.08475Z"
        fill="currentColor"
      />
      <path
        d="M10.3999 1.08475C10.3999 0.485658 10.8775 0 11.4666 0L14.9332 0C15.5223 0 15.9999 0.485658 15.9999 1.08475V4.0678C15.9999 4.66689 15.5223 5.15255 14.9332 5.15255H11.4666C10.8775 5.15255 10.3999 4.66689 10.3999 4.0678V1.08475Z"
        fill="currentColor"
      />
    </svg>
  );
}
