import defaultTheme from "tailwindcss/defaultTheme";
import { colors } from "./src/theme/colors";
import { hexToRgba } from "@norma-bi/utils";

/** @type {import('tailwindcss').Config} */
export default {
  content: ["./index.html", "./src/**/*.{js,ts,jsx,tsx}"],
  theme: {
    fontFamily: {
      sans: ["Inter var", ...defaultTheme.fontFamily.sans],
      mono: ["Consolas", "Monaco", "Andale Mono", "Ubuntu Mono", ...defaultTheme.fontFamily.mono],
    },
    extend: {
      colors,
      boxShadow: {
        button: `0 20px 25px -5px ${hexToRgba(colors.primaryColor, 0.1)}`,
        activebutton: `0px 4px 4px 0px rgba(0, 0, 0, 0.25), 0px 24px 32px 0px rgba(25, 35, 49, 0.16)`,
      },
      screens: {
        "max-1440": { max: "1440px" },
      },
    },
  },
};
