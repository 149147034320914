import React from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTokenStore } from "./TokenStore";
import { useAPI } from "../api";

export function useThoughtSpotToken() {
  const queryClient = useQueryClient();
  const api = useAPI();

  const { token: savedToken, setToken: saveToken } = useTokenStore();

  const [queryKey] = React.useState(["thoughtSpotToken"]);

  const query = useQuery({
    queryKey,
    queryFn: async () => {
      return api.getThoughtspotToken();
    },
    // don't run the query if we already have a token
    enabled: api.isAuthenticated && !savedToken,
  });

  React.useEffect(() => {
    if (!query.isSuccess) {
      return;
    }
    saveToken(query.data?.token);
  }, [query.isSuccess, query.data, saveToken]);

  const invalidateCurrentToken = React.useCallback(() => {
    saveToken(null);
    queryClient.invalidateQueries({ queryKey });
  }, [saveToken, queryClient, queryKey]);

  return { token: savedToken, invalidateCurrentToken };
}
