export function Logout() {
  return (
    <svg
      fill="#FFFFFF"
      height="30px"
      width="30px"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6,2 L6,22 M6,12 L18,12 M14,8 L18,12 L14,16"
        stroke="#FFFFFF"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </svg>
  );
}
