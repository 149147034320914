/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BranchReport } from './BranchReport';
import {
    BranchReportFromJSON,
    BranchReportFromJSONTyped,
    BranchReportToJSON,
    BranchReportToJSONTyped,
} from './BranchReport';

/**
 * 
 * @export
 * @interface GetBranchesReport200Response
 */
export interface GetBranchesReport200Response {
    /**
     * 
     * @type {Array<BranchReport>}
     * @memberof GetBranchesReport200Response
     */
    data: Array<BranchReport>;
}

/**
 * Check if a given object implements the GetBranchesReport200Response interface.
 */
export function instanceOfGetBranchesReport200Response(value: object): value is GetBranchesReport200Response {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function GetBranchesReport200ResponseFromJSON(json: any): GetBranchesReport200Response {
    return GetBranchesReport200ResponseFromJSONTyped(json, false);
}

export function GetBranchesReport200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBranchesReport200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BranchReportFromJSON)),
    };
}

export function GetBranchesReport200ResponseToJSON(json: any): GetBranchesReport200Response {
    return GetBranchesReport200ResponseToJSONTyped(json, false);
}

export function GetBranchesReport200ResponseToJSONTyped(value?: GetBranchesReport200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BranchReportToJSON)),
    };
}

