export function SearchData() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.66645 0.888885H0.888672V2.66666H2.66645V0.888885Z" fill="currentColor" />
      <path d="M5.33312 0.888885H3.55534V2.66666H5.33312V0.888885Z" fill="currentColor" />
      <path d="M6.222 0.888885H7.99978V2.66666H6.222V0.888885Z" fill="currentColor" />
      <path d="M10.6664 0.888885H8.88867V2.66666H10.6664V0.888885Z" fill="currentColor" />
      <path d="M11.5553 0.888885H13.3331V2.66666H11.5553V0.888885Z" fill="currentColor" />
      <path d="M15.1109 0.888885H14.222V2.66666H15.1109V0.888885Z" fill="currentColor" />
      <path d="M15.1109 6.22222V8H13.3331V6.22222H15.1109Z" fill="currentColor" />
      <path d="M15.1109 5.33333V3.55555H13.3331V5.33333H15.1109Z" fill="currentColor" />
      <path d="M15.1109 8.88889V10.6667H13.3331V8.88889H15.1109Z" fill="currentColor" />
      <path d="M2.66645 3.55555H0.888672V5.33333H2.66645V3.55555Z" fill="currentColor" />
      <path d="M0.888672 6.22222H2.66645V8H0.888672V6.22222Z" fill="currentColor" />
      <path d="M2.66645 14.2222H0.888672V15.1111H2.66645V14.2222Z" fill="currentColor" />
      <path d="M0.888672 11.5556H2.66645V13.3333H0.888672V11.5556Z" fill="currentColor" />
      <path d="M5.33312 13.3333H3.55534V15.1111H5.33312V13.3333Z" fill="currentColor" />
      <path d="M0.888672 8.88889H2.66645V10.6667H0.888672V8.88889Z" fill="currentColor" />
      <path d="M7.99978 13.3333H6.222V15.1111H7.99978V13.3333Z" fill="currentColor" />
      <path d="M8.88867 13.3333H10.6664V15.1111H8.88867V13.3333Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.70123 5.48584C7.08976 4.09731 9.34101 4.09731 10.7295 5.48584C11.9395 6.69584 12.0951 8.56097 11.1963 9.93944L14.9717 13.7148L13.7146 14.9719L9.88363 11.141C8.53959 11.8554 6.83355 11.6465 5.70123 10.5142C4.3127 9.12562 4.3127 6.87437 5.70123 5.48584ZM9.47246 6.74292C8.7782 6.04865 7.65257 6.04865 6.95831 6.74292C6.26404 7.43718 6.26404 8.56281 6.95831 9.25707C7.65257 9.95134 8.7782 9.95134 9.47246 9.25707C10.1667 8.56281 10.1667 7.43718 9.47246 6.74292Z"
        fill="currentColor"
      />
    </svg>
  );
}
