import { CursorBox } from "./CursorBox";
import { HorizontalBarChart01 } from "./HorizontalBarChart01";
import { PieChart03 } from "./PieChart03";
import { Table } from "./Table";
import { FaceWink } from "./FaceWink";
import { ChartBreakoutSquare } from "./ChartBreakoutSquare";
import { Custom } from "./Custom";
import { LayoutTop } from "./LayoutTop";
import { DistributeSpacingHorizontal } from "./DistributeSpacingHorizontal";
import { Mark } from "./Mark";
import { PieChart01 } from "./PieChart01";
import { HorizontalBarChart03 } from "./HorizontalBarChart03";
import { Flag05 } from "./Flag05";
import { Hurricane01 } from "./Hurricane01";
import { PaintPour } from "./PaintPour";
import { Colors } from "./Colors";
import { NavigationPointer01 } from "./NavigationPointer01";
import { LayoutAlt04 } from "./LayoutAlt04";
import { Reflect02 } from "./Reflect02";
import { Fi_5115617 } from "./Fi_5115617";
import { Reflect01 } from "./Reflect01";
import { Package } from "./Package";
import { AlignTopArrow02 } from "./AlignTopArrow02";
import { Hurricane03 } from "./Hurricane03";
import { HomeSmile } from "./HomeSmile";
import { Compare2 } from "./Compare2";
import { Divider } from "./Divider";
import { Route } from "./Route";
import { FlexAlignLeft } from "./FlexAlignLeft";
import { FlexAlignBottom } from "./FlexAlignBottom";
import { PuzzlePiece02 } from "./PuzzlePiece02";
import { Candidates } from "./Candidates";
import { Server04 } from "./Server04";
import { Drop } from "./Drop";
import { Menu } from "./Menu";
import { Columns03 } from "./Columns03";
import { Dropper } from "./Dropper";
import { IntersectCircle } from "./IntersectCircle";
import { Briefcase01 } from "./Briefcase01";
import { LayoutGrid01 } from "./LayoutGrid01";
import { FakeNews } from "./FakeNews";
import { AlignVerticalCenter02 } from "./AlignVerticalCenter02";
import { Globe01 } from "./Globe01";
import { Comparison } from "./Comparison";
import { BarChartSquareUp } from "./BarChartSquareUp";
import { Compass03 } from "./Compass03";
import { LifeBuoy02 } from "./LifeBuoy02";
import { Speedometer01 } from "./Speedometer01";
import { Compare3 } from "./Compare3";
import { Dataflow03 } from "./Dataflow03";
import { Arabic } from "./Arabic";
import { PresentationChart01 } from "./PresentationChart01";
import { Globe02 } from "./Globe02";
import { PackageSearch } from "./PackageSearch";
import { Stand } from "./Stand";
import { Type02 } from "./Type02";
import { Contrast02 } from "./Contrast02";
import { CodeBrowser } from "./CodeBrowser";
import { HomeLine } from "./HomeLine";
import { Contrast03 } from "./Contrast03";
import { Skew } from "./Skew";
import { BezierCurve01 } from "./BezierCurve01";
import { Move } from "./Move";
import { MagicWand02 } from "./MagicWand02";
import { LayoutBottom } from "./LayoutBottom";
import { BezierCurve03 } from "./BezierCurve03";
import { Sliders04 } from "./Sliders04";
import { Contrast01 } from "./Contrast01";
import { PackageCheck } from "./PackageCheck";
import { Safe } from "./Safe";
import { BezierCurve02 } from "./BezierCurve02";
import { Analysis } from "./Analysis";
import { ZapFast } from "./ZapFast";
import { Fi_4753365 } from "./Fi_4753365";
import { MagicWand01 } from "./MagicWand01";
import { Disc02 } from "./Disc02";
import { MarkerPin04 } from "./MarkerPin04";
import { Inventory } from "./Inventory";
import { CheckDone01 } from "./CheckDone01";
import { Fi_17271980 } from "./Fi_17271980";
import { Cryptocurrency01 } from "./Cryptocurrency01";
import { LayerSingle } from "./LayerSingle";
import { Glasses02 } from "./Glasses02";
import { StickerCircle } from "./StickerCircle";
import { PenTool01 } from "./PenTool01";
import { TrendUp02 } from "./TrendUp02";
import { LineChartUp02 } from "./LineChartUp02";
import { Sliders02 } from "./Sliders02";
import { Recording02 } from "./Recording02";
import { Toggle03Left } from "./Toggle03Left";
import { AnnotationDots } from "./AnnotationDots";
import { Compare1 } from "./Compare1";
import { GridDotsHorizontalCenter } from "./GridDotsHorizontalCenter";
import { Toggle03Right } from "./Toggle03Right";
import { Hand } from "./Hand";
import { Folder } from "./Folder";
import { Cryptocurrency02 } from "./Cryptocurrency02";
import { Cryptocurrency03 } from "./Cryptocurrency03";
import { MarkerPin06 } from "./MarkerPin06";
import { Sliders03 } from "./Sliders03";
import { TrendUp01 } from "./TrendUp01";
import { Scale03 } from "./Scale03";
import { AnnotationInfo } from "./AnnotationInfo";
import { Candidates1 } from "./Candidates1";
import { Fi_3815314 } from "./Fi_3815314";
import { Browser } from "./Browser";
import { DotsGrid } from "./DotsGrid";
import { FlexAlignRight } from "./FlexAlignRight";
import { Grid02 } from "./Grid02";
import { BarChart12 } from "./BarChart12";
import { Grid03 } from "./Grid03";
import { BarChart07 } from "./BarChart07";
import { Wallet05 } from "./Wallet05";
import { Scale02 } from "./Scale02";
import { Italic02 } from "./Italic02";
import { Perspective01 } from "./Perspective01";
import { Atom02 } from "./Atom02";
import { GridDotsOuter } from "./GridDotsOuter";
import { Tag01 } from "./Tag01";
import { Scale01 } from "./Scale01";
import { Perspective02 } from "./Perspective02";
import { Summary } from "./Summary";
import { Feather } from "./Feather";
import { LayersThree01 } from "./LayersThree01";
import { PuzzlePiece } from "./PuzzlePiece";
import { GridDotsVerticalCenter } from "./GridDotsVerticalCenter";
import { Expand06 } from "./Expand06";
import { BookOpen01 } from "./BookOpen01";
import { LayersTwo01 } from "./LayersTwo01";
import { Ruler } from "./Ruler";
import { Database01 } from "./Database01";
import { SpacingWidth01 } from "./SpacingWidth01";
import { Compare } from "./Compare";

export default {
  CursorBox,
  HorizontalBarChart01,
  PieChart03,
  Table,
  FaceWink,
  ChartBreakoutSquare,
  Custom,
  LayoutTop,
  DistributeSpacingHorizontal,
  Mark,
  PieChart01,
  HorizontalBarChart03,
  Flag05,
  Hurricane01,
  PaintPour,
  Colors,
  NavigationPointer01,
  LayoutAlt04,
  Reflect02,
  Fi_5115617,
  Reflect01,
  Package,
  AlignTopArrow02,
  Hurricane03,
  HomeSmile,
  Compare2,
  Divider,
  Route,
  FlexAlignLeft,
  FlexAlignBottom,
  PuzzlePiece02,
  Candidates,
  Server04,
  Drop,
  Menu,
  Columns03,
  Dropper,
  IntersectCircle,
  Briefcase01,
  LayoutGrid01,
  FakeNews,
  AlignVerticalCenter02,
  Globe01,
  Comparison,
  BarChartSquareUp,
  Compass03,
  LifeBuoy02,
  Speedometer01,
  Compare3,
  Dataflow03,
  Arabic,
  PresentationChart01,
  Globe02,
  PackageSearch,
  Stand,
  Type02,
  Contrast02,
  CodeBrowser,
  HomeLine,
  Contrast03,
  Skew,
  BezierCurve01,
  Move,
  MagicWand02,
  LayoutBottom,
  BezierCurve03,
  Sliders04,
  Contrast01,
  PackageCheck,
  Safe,
  BezierCurve02,
  Analysis,
  ZapFast,
  Fi_4753365,
  MagicWand01,
  Disc02,
  MarkerPin04,
  Inventory,
  CheckDone01,
  Fi_17271980,
  Cryptocurrency01,
  LayerSingle,
  Glasses02,
  StickerCircle,
  PenTool01,
  TrendUp02,
  LineChartUp02,
  Sliders02,
  Recording02,
  Toggle03Left,
  AnnotationDots,
  Compare1,
  GridDotsHorizontalCenter,
  Toggle03Right,
  Hand,
  Folder,
  Cryptocurrency02,
  Cryptocurrency03,
  MarkerPin06,
  Sliders03,
  TrendUp01,
  Scale03,
  AnnotationInfo,
  Candidates1,
  Fi_3815314,
  Browser,
  DotsGrid,
  FlexAlignRight,
  Grid02,
  BarChart12,
  Grid03,
  BarChart07,
  Wallet05,
  Scale02,
  Italic02,
  Perspective01,
  Atom02,
  GridDotsOuter,
  Tag01,
  Scale01,
  Perspective02,
  Summary,
  Feather,
  LayersThree01,
  PuzzlePiece,
  GridDotsVerticalCenter,
  Expand06,
  BookOpen01,
  LayersTwo01,
  Ruler,
  Database01,
  SpacingWidth01,
  Compare,
};
