import React from "react";

export function Reflect02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99992 2V4M7.99992 7V9M7.99992 12V14M14.6666 8H10.3333M10.3333 8L12.9999 10.6667M10.3333 8L12.9999 5.33333M1.33325 8H5.66659M5.66659 8L2.99992 10.6667M5.66659 8L2.99992 5.33333"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
