import { create } from "zustand";
import { OnboardingProgress } from "@norma-bi/bi-api";

export type OnboardingProgressStore = {
  isHydrated: boolean;
  allHaveFinished: boolean;
  atLeastOneHasFinished: boolean;
  progress: OnboardingProgress;

  setProgress: (progress: OnboardingProgress) => void;
  businessHasFinished: (businessId: string) => boolean;
  reset: () => void;
};

const defaultState: () => Pick<
  OnboardingProgressStore,
  "allHaveFinished" | "atLeastOneHasFinished" | "isHydrated" | "progress"
> = () => ({
  isHydrated: false,
  allHaveFinished: false,
  atLeastOneHasFinished: false,
  progress: [],
});

export const useOnboardingProgressStore = create<OnboardingProgressStore>((set, get) => ({
  ...defaultState(),

  setProgress: (progress) => {
    set({
      progress,
      allHaveFinished: progress.length > 0 && progress.every((p) => p.current >= p.total),
      atLeastOneHasFinished: progress.some((p) => p.current >= p.total),
      isHydrated: progress.length > 0,
    });
  },
  businessHasFinished: (businessId: string): boolean => {
    const { progress } = get();
    const found = progress.find((p) => p.business_id === businessId);
    if (!found) {
      return false;
    }
    return found.current >= found.total;
  },
  reset: () => {
    set({ ...defaultState() });
  },
}));
