import React from "react";

export function Stand() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11974)">
        <path
          d="M6.00016 11.3335L2.66683 14.6668M10.0002 11.3335L13.3335 14.6668M8.00016 1.3335V2.66683M8.00016 14.6668V11.3335M3.46683 11.3335H12.5335C13.2802 11.3335 13.6536 11.3335 13.9388 11.1882C14.1897 11.0603 14.3937 10.8564 14.5215 10.6055C14.6668 10.3203 14.6668 9.9469 14.6668 9.20016V4.80016C14.6668 4.05343 14.6668 3.68006 14.5215 3.39484C14.3937 3.14396 14.1897 2.93999 13.9388 2.81215C13.6536 2.66683 13.2802 2.66683 12.5335 2.66683H3.46683C2.72009 2.66683 2.34672 2.66683 2.06151 2.81215C1.81063 2.93999 1.60665 3.14396 1.47882 3.39484C1.3335 3.68006 1.3335 4.05343 1.3335 4.80016V9.20016C1.3335 9.9469 1.3335 10.3203 1.47882 10.6055C1.60665 10.8564 1.81063 11.0603 2.06151 11.1882C2.34672 11.3335 2.72009 11.3335 3.46683 11.3335Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11974">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
