import React from "react";
import { useBusinessContext } from "../hooks/useBusinessContext";
import { RuntimeFilter, RuntimeFilterOp } from "@thoughtspot/visual-embed-sdk";
import { isAllBusiness } from "../api";
import { useGetBusinesses } from "../hooks/useGetBusinesses";

export function useGetRuntimeFilters() {
  const businessContext = useBusinessContext();
  const businesses = useGetBusinesses();

  return React.useMemo<RuntimeFilter[] | undefined>(() => {
    if (businessContext.isLoading) {
      return undefined;
    }
    if (isAllBusiness(businessContext.business.id)) {
      if (!businesses.isSuccess) {
        return undefined;
      }
      return [
        {
          columnName: "norma business id",
          operator: RuntimeFilterOp.IN,
          values: businesses.data.map((b) => b.id),
        },
      ];
    }
    return [
      {
        columnName: "norma business id",
        operator: RuntimeFilterOp.EQ,
        values: [businessContext.business.id],
      },
    ];
  }, [businessContext.business, businessContext.isLoading, businesses.isSuccess, businesses.data]);
}
