import React from "react";
import * as Sentry from "@sentry/react";
import * as ReactRouter from "react-router";

const sentryEnabled = import.meta.env.PROD;

if (sentryEnabled) {
  const sentryDSN = import.meta.env.VITE_SENTRY_DSN;
  if (!sentryDSN) {
    console.warn("sentry will be no-op: the DSN is missing");
  }

  Sentry.init({
    dsn: sentryDSN,
    sendDefaultPii: true,
    debug: !import.meta.env.PROD,
    environment: import.meta.env.MODE,
    // NOTE(yannis): this may be too high, but we'll find out I guess
    replaysOnErrorSampleRate: 1.0,

    tracesSampleRate: 1.0,

    // NOTE(yannis): In case we detect slow startup, some of the integrations below can be lazy loaded.
    integrations: [
      Sentry.reactRouterV7BrowserTracingIntegration({
        useEffect: React.useEffect,
        useLocation: ReactRouter.useLocation,
        useNavigationType: ReactRouter.useNavigationType,
        createRoutesFromChildren: ReactRouter.createRoutesFromChildren,
        matchRoutes: ReactRouter.matchRoutes,
      }),
      Sentry.replayIntegration({
        maskAllText: false,
        maskAllInputs: true, // disable when marking as "should-mask" the password input fields
      }),
      Sentry.httpClientIntegration({
        failedRequestStatusCodes: [[400, 599]],
      }),
      Sentry.browserProfilingIntegration(),
      Sentry.browserTracingIntegration(),
    ],
  });
}
