export function FavoriteEmpty() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.80985 7.04915L3.51623 7.21731L6.07958 9.24785L5.22136 12.3795L7.99989 10.6126L10.7784 12.3795L9.9202 9.24785L12.4836 7.21731L9.18993 7.04915L7.99989 4.01288L6.80985 7.04915ZM10.4264 5.33219L8.93659 1.53099C8.60091 0.674564 7.39887 0.674564 7.0632 1.53099L5.57335 5.33219L1.40218 5.54516C0.472191 5.59264 0.0986603 6.77808 0.830915 7.35813L4.05437 9.91156L2.98296 13.8212C2.73785 14.7156 3.71265 15.4457 4.49104 14.9508L7.99989 12.7194L11.5087 14.9508C12.2871 15.4457 13.2619 14.7156 13.0168 13.8212L11.9454 9.91156L15.1689 7.35813C15.9011 6.77808 15.5276 5.59264 14.5976 5.54516L10.4264 5.33219Z"
        fill="currentColor"
      />
    </svg>
  );
}
