/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeBusinessContextRequest
 */
export interface ChangeBusinessContextRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeBusinessContextRequest
     */
    business_context: string;
}

/**
 * Check if a given object implements the ChangeBusinessContextRequest interface.
 */
export function instanceOfChangeBusinessContextRequest(value: object): value is ChangeBusinessContextRequest {
    if (!('business_context' in value) || value['business_context'] === undefined) return false;
    return true;
}

export function ChangeBusinessContextRequestFromJSON(json: any): ChangeBusinessContextRequest {
    return ChangeBusinessContextRequestFromJSONTyped(json, false);
}

export function ChangeBusinessContextRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeBusinessContextRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'business_context': json['business_context'],
    };
}

export function ChangeBusinessContextRequestToJSON(json: any): ChangeBusinessContextRequest {
    return ChangeBusinessContextRequestToJSONTyped(json, false);
}

export function ChangeBusinessContextRequestToJSONTyped(value?: ChangeBusinessContextRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'business_context': value['business_context'],
    };
}

