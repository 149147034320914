import React from "react";
import { useCookies } from "react-cookie";

export function useAccessToken() {
  const [{ token }, setCookie, removeCookie] = useCookies(["token"]);

  return {
    accessToken: token,
    setAccessToken: React.useCallback(
      (token: string) => {
        const inTwoMonths = new Date();
        inTwoMonths.setMonth(inTwoMonths.getMonth() + 2);
        setCookie("token", token, { secure: true, expires: inTwoMonths });
      },
      [setCookie],
    ),
    removeAccessToken: React.useCallback(() => removeCookie("token"), [removeCookie]),
  };
}
