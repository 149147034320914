import toast, { Toast, ToastOptions } from "react-hot-toast";

type ToastType = "success" | "error";
type ToastPreset = "default" | "bottom";

const bottomToastOptions: ToastOptions = {
  position: "bottom-center",
  duration: 3000,
  style: {
    background: "rgba(29, 35, 47, 0.7)",
    color: "#fff",
    padding: "16px",
    borderRadius: "4px",
    maxWidth: "none",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontWeight: "500",
  },
};

const createToastContent = (message: string, t: Toast) => (
  <div style={{ display: "flex", alignItems: "center" }}>
    <button
      onClick={() => toast.dismiss(t.id)}
      style={{
        background: "none",
        border: "none",
        color: "#fff",
        fontSize: "16px",
        marginRight: "10px",
        cursor: "pointer",
      }}
    >
      ✕
    </button>
    <span>{message}</span>
  </div>
);

export const customToast = (message: string, type: ToastType, preset: ToastPreset = "default") => {
  if (preset === "bottom") {
    toast((t) => createToastContent(message, t), bottomToastOptions);
  } else {
    // Use default toast
    toast[type](message);
  }
};
