import React from "react";

export function Compare2() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11886)">
        <path
          d="M3.19085 12.0295C3.28773 12.2233 3.47801 12.353 3.69376 12.3725C3.90951 12.3919 4.11991 12.2983 4.24988 12.125L6.12488 9.62504C6.33198 9.34891 6.27604 8.95713 5.99988 8.75004C5.72376 8.54291 5.33201 8.59891 5.12488 8.87504L3.85663 10.566L3.68391 10.2205C3.52957 9.91182 3.15423 9.78663 2.84538 9.94104C2.53663 10.0954 2.41151 10.4708 2.56588 10.7796L3.19085 12.0295Z"
          fill="currentColor"
        />
        <path
          d="M9.93305 7.19195C10.1771 7.43601 10.5729 7.43601 10.817 7.19195L11.625 6.38391L12.433 7.19195C12.6771 7.43601 13.0729 7.43601 13.317 7.19195C13.561 6.94785 13.561 6.55213 13.317 6.30807L12.5089 5.50001L13.317 4.69195C13.5611 4.44788 13.5611 4.05213 13.317 3.80807C13.0729 3.56398 12.6771 3.56398 12.4331 3.80807L11.625 4.6161L10.817 3.80807C10.5729 3.56398 10.1771 3.56398 9.93308 3.80807C9.68898 4.05213 9.68898 4.44788 9.93308 4.69195L10.7411 5.50001L9.93305 6.30807C9.68898 6.55213 9.68898 6.94788 9.93305 7.19195Z"
          fill="currentColor"
        />
        <path
          d="M9.93305 12.1919C10.1771 12.436 10.5729 12.436 10.817 12.1919L11.625 11.3839L12.433 12.1919C12.6771 12.436 13.0729 12.436 13.317 12.1919C13.561 11.9479 13.561 11.5521 13.317 11.3081L12.5089 10.5L13.317 9.69195C13.5611 9.44785 13.5611 9.05213 13.317 8.80807C13.0729 8.56401 12.6771 8.56398 12.4331 8.80807L11.625 9.6161L10.817 8.80807C10.5729 8.56398 10.1771 8.56398 9.93308 8.80807C9.68902 9.05216 9.68898 9.44788 9.93308 9.69195L10.7411 10.5L9.93305 11.3081C9.68898 11.5521 9.68898 11.9479 9.93305 12.1919Z"
          fill="currentColor"
        />
        <path
          d="M14.125 1.25H10.5C10.1548 1.25 9.875 1.52981 9.875 1.875C9.875 2.22019 10.1548 2.5 10.5 2.5H14.125C14.4696 2.5 14.75 2.78038 14.75 3.125V12.875C14.75 13.2196 14.4696 13.5 14.125 13.5H8.625V0.625C8.625 0.279813 8.34519 0 8 0C7.65481 0 7.375 0.279813 7.375 0.625V1.25H1.875C0.841125 1.25 0 2.09113 0 3.125V12.875C0 13.9089 0.841125 14.75 1.875 14.75H7.375V15.375C7.375 15.7202 7.65481 16 8 16C8.34519 16 8.625 15.7202 8.625 15.375V14.75H14.125C15.1589 14.75 16 13.9089 16 12.875V3.125C16 2.09113 15.1589 1.25 14.125 1.25ZM1.875 13.5C1.53038 13.5 1.25 13.2196 1.25 12.875V3.125C1.25 2.78038 1.53038 2.5 1.875 2.5H7.375V13.5H1.875Z"
          fill="currentColor"
        />
        <path
          d="M5.25895 5.50001L6.06701 4.69195C6.3111 4.44788 6.3111 4.05213 6.06701 3.80807C5.82291 3.56398 5.42716 3.56398 5.1831 3.80807L4.37504 4.6161L3.56701 3.80807C3.32291 3.56398 2.92716 3.56398 2.6831 3.80807C2.43901 4.05213 2.43901 4.44788 2.6831 4.69195L3.49113 5.50001L2.68307 6.30807C2.43898 6.55216 2.43898 6.94788 2.68307 7.19195C2.92713 7.43601 3.32291 7.43601 3.56698 7.19195L4.37504 6.38391L5.18307 7.19195C5.42713 7.43601 5.82291 7.43601 6.06698 7.19195C6.31107 6.94785 6.31107 6.55213 6.06698 6.30807L5.25895 5.50001Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11886">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
