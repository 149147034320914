import React from "react";
import { TooltipData } from "./types";
import { TooltipContext } from "./context";
import { DefaultTooltipRenderer } from "./renderer";

export type TooltipProviderProps = React.PropsWithChildren<{
  rendererOverride?: React.FC<{ tooltip: TooltipData | null }>;
}>;

export function TooltipProvider(props: TooltipProviderProps) {
  const [tooltip, setTooltip] = React.useState<TooltipData | null>(null);

  return (
    <TooltipContext.Provider value={{ setTooltip }}>
      {props.children}

      {props.rendererOverride ? (
        props.rendererOverride({ tooltip })
      ) : (
        <DefaultTooltipRenderer tooltip={tooltip} />
      )}
    </TooltipContext.Provider>
  );
}
