import { ColumnDef } from "@tanstack/react-table";
import { useNavigate } from "react-router";
import { ThoughtspotSchedule } from "@norma-bi/bi-api";
import { BaseSettingsTable } from "../components/BaseSettingsTable";
import { BaseSettingsCell } from "../components/BaseSettingsCell";
import { EmptyTable } from "../components/EmptyTable";
import { SCHEDULES_DOCS_URL } from "../../../constants";
import { Shopping } from "../../../svg/Shopping";

export type SettingsEmailSchedulesTableProps = {
  emailSchedules: ThoughtspotSchedule[];
  tableClassName?: string;
};

export function SettingsEmailSchedulesTable({
  emailSchedules,
  tableClassName = "settings-table settings-table-schedules",
}: SettingsEmailSchedulesTableProps) {
  const navigate = useNavigate();

  const columns: ColumnDef<ThoughtspotSchedule, keyof ThoughtspotSchedule>[] = [
    {
      accessorKey: "name",
      header: "Name",
      cell: ({ getValue }) => BaseSettingsCell.businessAvatar(getValue() as string),
    },
    {
      accessorKey: "liveboard_name",
      header: "Liveboard",
    },
    {
      accessorKey: "recipients",
      header: "Recipients",
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ getValue }) => BaseSettingsCell.status(getValue() as string),
    },
    {
      accessorKey: "runs",
      header: "Runs",
    },
  ];

  const handleRowClick = (emailSchedule: ThoughtspotSchedule) => {
    navigate(`/schedules/${emailSchedule.liveboard_id}`);
  };

  if (!emailSchedules.length) {
    return (
      <EmptyTable
        icon={Shopping}
        message={
          <>
            Add <span className="text-primaryColor">schedules</span> to manage them
          </>
        }
        subtitle="See how to add an email scheduled report"
        linkTo={SCHEDULES_DOCS_URL}
        linkText="here"
      />
    );
  }

  return (
    <BaseSettingsTable
      data={emailSchedules}
      columns={columns}
      tableClassName={tableClassName}
      title="Email Schedules"
      description="This is a list of all the email schedules"
      onRowClick={handleRowClick}
    />
  );
}
