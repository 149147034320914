import React from "react";

export function PieChart03() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11926)">
        <path
          d="M7.99992 1.3335C8.8754 1.3335 9.74231 1.50593 10.5511 1.84097C11.36 2.176 12.0949 2.66706 12.714 3.28612C13.333 3.90518 13.8241 4.64011 14.1591 5.44894C14.4941 6.25778 14.6666 7.12469 14.6666 8.00017M7.99992 1.3335V8.00016M7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016C1.33325 11.6821 4.31802 14.6668 7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00017M7.99992 1.3335C11.6818 1.3335 14.6666 4.31827 14.6666 8.00017M14.6666 8.00017L7.99992 8.00016M14.6666 8.00017C14.6666 9.05224 14.4176 10.0894 13.94 11.0268C13.4623 11.9642 12.7696 12.7752 11.9185 13.3936L7.99992 8.00016"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11926">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
