export function Answers() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.47883 1.31595C2.81779 1.81666 0 4.89708 0 8.6224C0 12.697 3.3709 16.0001 7.52911 16.0001C11.3412 16.0001 14.4916 13.224 14.9896 9.62237L6.47882 9.62214L6.47883 1.31595Z"
        fill="currentColor"
      />
      <path
        d="M8.33013 0.69227V0C8.33013 0 8.72645 0.0265142 8.92189 0.0500097C10.7969 0.275423 12.4704 1.11865 13.7166 2.35641C14.9068 3.5386 15.7145 5.08778 15.9377 6.81384C15.979 7.13309 16.0002 7.45763 16.0002 7.78611H12.2678C12.2678 7.78605 12.2678 7.78617 12.2678 7.78611L8.3291 7.78594V0.692169L8.33013 0.69227Z"
        fill="currentColor"
      />
    </svg>
  );
}
