import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../api";

export function useSelf() {
  const api = useAPI();

  return useQuery({
    queryKey: ["self"],
    queryFn: async () => {
      return api.getSelf();
    },
    enabled: api.isAuthenticated,
  });
}
