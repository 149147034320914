import React from "react";

export function Drop() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.3332 9.3335C13.3332 12.279 10.9454 14.6668 7.99984 14.6668C5.05432 14.6668 2.6665 12.279 2.6665 9.3335C2.6665 8.62623 2.80418 7.95112 3.05418 7.3335C3.84536 5.37894 7.99984 1.3335 7.99984 1.3335C7.99984 1.3335 12.1543 5.37894 12.9455 7.3335C13.1955 7.95112 13.3332 8.62623 13.3332 9.3335Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
