import { SSEEmitter } from "./sseemitter";

export type OnboardingProgress = {
  business_id: string;
  current: number;
  total: number;
}[];
export class OnboardingProgressSSEEmitter extends SSEEmitter<OnboardingProgress> {
  constructor(serverHost: string, biToken: string, extraHeaders?: Headers) {
    const url = new URL("/v2/sub/progress", serverHost);
    super(
      url,
      {
        headers: {
          ...extraHeaders,
          Authorization: `Bearer ${biToken}`,
        },
      },
      { debugLogs: false },
    );
  }
}
