import React from "react";

export function FakeNews() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11887)">
        <path
          d="M9.25 10.4375H6.75C6.49112 10.4375 6.28125 10.6474 6.28125 10.9062C6.28125 11.1651 6.49112 11.375 6.75 11.375H9.25C9.50887 11.375 9.71875 11.1651 9.71875 10.9062C9.71875 10.6474 9.50887 10.4375 9.25 10.4375Z"
          fill="currentColor"
        />
        <path
          d="M9.25 12.3125H6.75C6.49112 12.3125 6.28125 12.5224 6.28125 12.7812C6.28125 13.0401 6.49112 13.25 6.75 13.25H9.25C9.50887 13.25 9.71875 13.0401 9.71875 12.7812C9.71875 12.5224 9.50887 12.3125 9.25 12.3125Z"
          fill="currentColor"
        />
        <path
          d="M13 0C11.3458 0 10 1.34578 10 3C10 4.49478 11.0989 5.73759 12.5312 5.96328V8.8125H11.5938V8.03125C11.5938 7.77238 11.3839 7.5625 11.125 7.5625H4.875C4.61613 7.5625 4.40625 7.77238 4.40625 8.03125V8.8125H3.46875V5.96328C4.90109 5.73756 6 4.49475 6 3C6 1.34578 4.65422 0 3 0C1.34578 0 0 1.34578 0 3C0 4.49478 1.09891 5.73759 2.53125 5.96328V9.28125C2.53125 9.54012 2.74113 9.75 3 9.75H4.40625V14.5938C4.40625 15.3692 5.03709 16 5.8125 16H12.0625C12.8379 16 13.4688 15.3692 13.4688 14.5938V11.7812C13.4688 11.5224 13.2589 11.3125 13 11.3125H11.5938V9.75H13C13.2589 9.75 13.4688 9.54012 13.4688 9.28125V5.96328C14.9011 5.73756 16 4.49475 16 3C16 1.34578 14.6542 0 13 0ZM0.9375 3C0.9375 1.86272 1.86272 0.9375 3 0.9375C4.13728 0.9375 5.0625 1.86272 5.0625 3C5.0625 4.13728 4.13728 5.0625 3 5.0625C1.86272 5.0625 0.9375 4.13728 0.9375 3ZM5.8125 15.0625C5.55403 15.0625 5.34375 14.8522 5.34375 14.5938V8.5H10.6562V14.5938C10.6562 14.758 10.6846 14.9158 10.7366 15.0625H5.8125ZM12.5312 12.25V14.5938C12.5312 14.8522 12.321 15.0625 12.0625 15.0625C11.804 15.0625 11.5938 14.8522 11.5938 14.5938V12.25H12.5312ZM13 5.0625C11.8627 5.0625 10.9375 4.13728 10.9375 3C10.9375 1.86272 11.8627 0.9375 13 0.9375C14.1373 0.9375 15.0625 1.86272 15.0625 3C15.0625 4.13728 14.1373 5.0625 13 5.0625Z"
          fill="currentColor"
        />
        <path
          d="M13.9565 2.04355C13.7734 1.86048 13.4766 1.86048 13.2935 2.04355L13 2.33711L12.7065 2.04358C12.5234 1.86052 12.2266 1.86052 12.0435 2.04358C11.8605 2.22664 11.8605 2.52342 12.0435 2.70648L12.3371 3.00002L12.0435 3.29355C11.8605 3.47661 11.8605 3.77339 12.0435 3.95645C12.2266 4.13952 12.5234 4.13952 12.7065 3.95645L13 3.66292L13.2935 3.95645C13.4766 4.13952 13.7734 4.13952 13.9564 3.95645C14.1395 3.77339 14.1395 3.47661 13.9564 3.29355L13.6629 3.00002L13.9564 2.70648C14.1395 2.52342 14.1395 2.22661 13.9565 2.04355Z"
          fill="currentColor"
        />
        <path
          d="M3.33155 2.22663L2.77911 2.77907L2.66861 2.66857C2.48558 2.4855 2.18877 2.4855 2.00571 2.66857C1.82264 2.85163 1.82264 3.14841 2.00571 3.33147L2.44764 3.77341C2.53917 3.86494 2.65911 3.91072 2.77908 3.91072C2.89902 3.91072 3.01902 3.86494 3.11052 3.77344L3.99442 2.88954C4.17749 2.7065 4.17749 2.40969 3.99442 2.22663C3.81136 2.0436 3.51461 2.0436 3.33155 2.22663Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11887">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
