import { SettingsSidebarGroup } from "./SettingsSidebar";

export const SIDEBAR_GROUPS: SettingsSidebarGroup[] = [
  {
    header: "Admin",
    items: [
      { label: "Users", path: "users" },
      { label: "Businesses", path: "businesses" },
      { label: "Email Schedules", path: "email_schedules" },
    ],
  },
];
