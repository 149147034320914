export function Products() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M16.6684 9.16627V4.16419C16.6684 3.24333 15.9219 2.49683 15.0011 2.49683H4.16321C3.24235 2.49683 2.49585 3.24333 2.49585 4.16419V15.8357C2.49585 16.7566 3.24235 17.5031 4.16321 17.5031H8.33161"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70638 12.501H8.33164"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5858 11.5156V14.1683"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5031 18.3368H11.7716C11.2536 18.3368 10.8337 17.9169 10.8337 17.3989V13.8074C10.8338 13.5822 10.8795 13.3593 10.9679 13.1521L11.4239 12.085C11.5717 11.7397 11.9111 11.5156 12.2868 11.5156H16.8837C17.2591 11.5158 17.5982 11.7398 17.7457 12.085L18.2026 13.1538C18.2911 13.3609 18.3368 13.5838 18.3368 13.8091V17.5031C18.3368 17.9635 17.9636 18.3368 17.5031 18.3368Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3368 15.0021C18.3368 14.5416 17.9636 14.1684 17.5031 14.1684H11.6674C11.2069 14.1684 10.8337 14.5416 10.8337 15.0021"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70636 9.16629H10.8327"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.70636 5.93576H13.3337"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.10214 12.6052C5.1597 12.6052 5.20635 12.5586 5.20635 12.501C5.20635 12.4435 5.1597 12.3968 5.10214 12.3968C5.04459 12.3968 4.99793 12.4435 4.99793 12.501C4.99793 12.5586 5.04459 12.6052 5.10214 12.6052"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.10214 9.2705C5.1597 9.2705 5.20635 9.22384 5.20635 9.16629C5.20635 9.10874 5.1597 9.06208 5.10214 9.06208C5.04459 9.06208 4.99793 9.10874 4.99793 9.16629C4.99793 9.22384 5.04459 9.2705 5.10214 9.2705"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.10214 6.03997C5.1597 6.03997 5.20635 5.99331 5.20635 5.93576C5.20635 5.87821 5.1597 5.83155 5.10214 5.83155C5.04459 5.83155 4.99793 5.87821 4.99793 5.93576C4.99793 5.99331 5.04459 6.03997 5.10214 6.03997"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
