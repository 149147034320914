import {
  FrameParams,
  AppViewConfig,
  LiveboardViewConfig,
  SearchViewConfig,
} from "@thoughtspot/visual-embed-sdk/react";
import { LOCALE_OVERRIDE } from "./constants";
import { useGetRuntimeFilters } from "./useGetRuntimeFilters";

export const frameParams: FrameParams = {
  height: "100%",
  width: "100%",
  sandbox:
    "allow-forms allow-modals allow-orientation-lock allow-pointer-lock allow-presentation allow-same-origin allow-scripts allow-top-navigation allow-top-navigation-by-user-activation allow-downloads",
};

export type ThoughtspotEmbedCommonViewConfig = AppViewConfig &
  LiveboardViewConfig &
  SearchViewConfig;

export function useThoughtSpotEmbedCommonProps(): ThoughtspotEmbedCommonViewConfig {
  const runtimeFilters = useGetRuntimeFilters();
  return {
    frameParams,
    locale: LOCALE_OVERRIDE,
    runtimeFilters,
  };
}
