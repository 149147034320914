/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { LiveboardCategoryLiveboardsInner } from './LiveboardCategoryLiveboardsInner';
import {
    LiveboardCategoryLiveboardsInnerFromJSON,
    LiveboardCategoryLiveboardsInnerFromJSONTyped,
    LiveboardCategoryLiveboardsInnerToJSON,
    LiveboardCategoryLiveboardsInnerToJSONTyped,
} from './LiveboardCategoryLiveboardsInner';

/**
 * 
 * @export
 * @interface LiveboardCategory
 */
export interface LiveboardCategory {
    /**
     * 
     * @type {string}
     * @memberof LiveboardCategory
     */
    name: string;
    /**
     * 
     * @type {Array<LiveboardCategoryLiveboardsInner>}
     * @memberof LiveboardCategory
     */
    liveboards: Array<LiveboardCategoryLiveboardsInner>;
}

/**
 * Check if a given object implements the LiveboardCategory interface.
 */
export function instanceOfLiveboardCategory(value: object): value is LiveboardCategory {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('liveboards' in value) || value['liveboards'] === undefined) return false;
    return true;
}

export function LiveboardCategoryFromJSON(json: any): LiveboardCategory {
    return LiveboardCategoryFromJSONTyped(json, false);
}

export function LiveboardCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): LiveboardCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'liveboards': ((json['liveboards'] as Array<any>).map(LiveboardCategoryLiveboardsInnerFromJSON)),
    };
}

export function LiveboardCategoryToJSON(json: any): LiveboardCategory {
    return LiveboardCategoryToJSONTyped(json, false);
}

export function LiveboardCategoryToJSONTyped(value?: LiveboardCategory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'liveboards': ((value['liveboards'] as Array<any>).map(LiveboardCategoryLiveboardsInnerToJSON)),
    };
}

