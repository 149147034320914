export function CheckboxTicked() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2094_3963)">
        <path
          d="M13.3337 4L6.00033 11.3333L2.66699 8"
          stroke="#440099"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" stroke="#1D232F" />
      <defs>
        <clipPath id="clip0_2094_3963">
          <rect width="16" height="16" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
