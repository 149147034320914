import { SubmitHandler, useForm } from "react-hook-form";
import { Card } from "./Card";
import React from "react";
import { useNavigate } from "react-router";

export type ResetPasswordFormProps = {
  onPasswordReady: (password: string) => void;
  title: React.ReactNode;
  confirmButtonText: string;
  hasExpired: boolean;
  isLoading?: boolean;
  cardClassname?: string;
};

export function ResetPasswordForm(props: ResetPasswordFormProps) {
  const navigate = useNavigate();

  type UpdatePasswordDetails = {
    password: string;
    repeatPassword: string;
  };

  const { register, handleSubmit, watch } = useForm<UpdatePasswordDetails>({
    defaultValues: {
      password: "",
      repeatPassword: "",
    },
  });

  const canSubmit = watch("password") !== "" && watch("password") === watch("repeatPassword");

  const onSubmit: SubmitHandler<UpdatePasswordDetails> = async (data) => {
    props.onPasswordReady(data.password);
  };

  async function requestNewLink() {
    navigate("/login/forgot-password");
  }

  return (
    <div className={"flex flex-col gap-10 w-full items-center"}>
      <Card
        className={props.cardClassname || "w-[53%] max-w-[660px]"}
        cardTitle={props.title}
        Footer={
          <input
            disabled={!canSubmit || props.isLoading || props.hasExpired}
            type="submit"
            className="thoughtspot-main-button"
            value={props.isLoading ? "Loading..." : props.confirmButtonText}
            form="update-password-form"
          />
        }
      >
        <form id="update-password-form" className="py-10" onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-8">
            <div className="thoughtspot-text-input-field">
              <label className="w-[30%]" htmlFor="password">
                Password
              </label>
              <input
                className="w-[55%]"
                id="password"
                type="password"
                placeholder="Password"
                disabled={props.hasExpired}
                {...register("password")}
              />
            </div>

            <div className="thoughtspot-text-input-field">
              <label className="w-[30%]" htmlFor="repeat-password">
                Repeat Password
              </label>
              <input
                className="w-[55%]"
                id="repeat-password"
                type="password"
                placeholder="Password"
                disabled={props.hasExpired}
                {...register("repeatPassword")}
              />
            </div>
          </div>
        </form>
      </Card>

      {props.hasExpired && (
        <div className={"text-center text-sematicRed section-label"}>
          Your reset link has expired,
          <br />
          please press{" "}
          <button className={"underline"} onClick={requestNewLink}>
            here
          </button>{" "}
          in order to receive a new link.
        </div>
      )}
    </div>
  );
}
