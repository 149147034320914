import React from "react";

export function PieChart01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11917)">
        <path
          d="M14.1404 10.5932C13.7163 11.5962 13.053 12.48 12.2084 13.1674C11.3637 13.8548 10.3636 14.3248 9.29539 14.5364C8.22717 14.748 7.12338 14.6946 6.08053 14.3811C5.03768 14.0675 4.08752 13.5033 3.31311 12.7377C2.53871 11.972 1.96364 11.0284 1.63819 9.98918C1.31274 8.94998 1.24681 7.84688 1.44616 6.77631C1.64552 5.70574 2.1041 4.7003 2.7818 3.8479C3.4595 2.9955 4.33569 2.3221 5.33377 1.88655M14.1596 5.44866C14.4265 6.09297 14.5902 6.77413 14.6458 7.46711C14.6595 7.63831 14.6664 7.72391 14.6324 7.80102C14.604 7.86543 14.5477 7.92638 14.4858 7.95984C14.4116 7.99988 14.319 7.99988 14.1338 7.99988H8.53377C8.34708 7.99988 8.25374 7.99988 8.18244 7.96355C8.11971 7.93159 8.06872 7.8806 8.03676 7.81788C8.00043 7.74658 8.00043 7.65323 8.00043 7.46655V1.86655C8.00043 1.68131 8.00043 1.58868 8.04048 1.51454C8.07393 1.4526 8.13489 1.39634 8.1993 1.36795C8.2764 1.33395 8.362 1.34081 8.5332 1.35454C9.22619 1.4101 9.90735 1.57381 10.5517 1.84069C11.3605 2.17572 12.0954 2.66678 12.7145 3.28584C13.3335 3.9049 13.8246 4.63982 14.1596 5.44866Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11917">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
