export function ArrowDown() {
  return (
    <svg width="14" height="17" viewBox="0 0 14 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.08 11H14L8 17L2 11H6C6 4.168 5.264 1.3104 0 0C10.08 0 10.08 6.6656 10.08 11Z"
        fill="#1D232F"
      />
    </svg>
  );
}
