import { create } from "zustand";

export type TokenStore = {
  token: string | null;
  setToken: (token: string | null) => void;
  reset: () => void;
};

const defaultState: () => Pick<TokenStore, "token"> = () => ({ token: null });

export const useTokenStore = create<TokenStore>((set) => ({
  ...defaultState(),

  setToken: (token) => set({ token }),
  reset: () => {
    set({ ...defaultState() });
  },
}));
