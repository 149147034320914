import React from "react";
import { PulseLoader } from "react-spinners";

export type ContextMenuOption = {
  id: string;
  label: string;
  handler: () => Promise<void>;
  isDisabled?: boolean;
  isPending?: boolean;
};

export type ContextMenuProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  options: ContextMenuOption[];
};

export function ContextMenu({ options, show, setShow }: ContextMenuProps) {
  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setShow(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [setShow]);

  if (!show) {
    return null;
  }

  return (
    <div
      ref={ref}
      className="absolute top-[-20px] left-full flex flex-col p-2 ml-2 mt-1 gap-y-2 bg-white text-sm shadow-md w-[176px] h-auto"
    >
      {options.map((option) => (
        <ContextMenuItem
          key={option.id}
          option={option}
          onOptionSelectedAndDone={() => {
            setShow(false);
          }}
        />
      ))}
    </div>
  );
}

type ContextMenuItemProps = {
  option: ContextMenuOption;
  onOptionSelectedAndDone: () => void;
};

function ContextMenuItem({ option, onOptionSelectedAndDone }: ContextMenuItemProps) {
  async function onClick() {
    await option.handler();
    onOptionSelectedAndDone();
  }

  return (
    <button
      className={`text-sm font-medium text-left p-2 
      ${option.isPending ? "opacity-50" : ""}
      ${
        option.isDisabled
          ? "cursor-not-allowed text-feintGray hover:bg-transparent"
          : "hover:bg-backgroundGray hover:text-dark"
      }`}
      onClick={onClick}
      disabled={option.isDisabled || option.isPending}
    >
      <div className={"flex gap-4 items-center"}>
        {option.label} {option.isPending && <PulseLoader size={5} />}
      </div>
    </button>
  );
}
