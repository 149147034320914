import React from "react";

export function Toggle03Left() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.33325 8C1.33325 5.79086 3.12411 4 5.33325 4H10.6666C12.8757 4 14.6666 5.79086 14.6666 8C14.6666 10.2091 12.8757 12 10.6666 12H5.33325C3.12411 12 1.33325 10.2091 1.33325 8Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.33325 9.66667C6.25373 9.66667 6.99992 8.92047 6.99992 8C6.99992 7.07953 6.25373 6.33333 5.33325 6.33333C4.41278 6.33333 3.66659 7.07953 3.66659 8C3.66659 8.92047 4.41278 9.66667 5.33325 9.66667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
