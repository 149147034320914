export function Reports() {
  return (
    <svg width="32" height="32" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.6663 5.89124V9.33335C15.6663 9.80006 15.6663 10.0334 15.7572 10.2117C15.8371 10.3685 15.9645 10.496 16.1213 10.5759C16.2996 10.6667 16.533 10.6667 16.9997 10.6667H20.4418M10.6663 16.5V19M17.333 14.8333V19M13.9997 12.75V19M20.6663 12.3235V18.3333C20.6663 19.7334 20.6663 20.4335 20.3939 20.9683C20.1542 21.4387 19.7717 21.8211 19.3013 22.0608C18.7665 22.3333 18.0665 22.3333 16.6663 22.3333H11.333C9.93288 22.3333 9.23281 22.3333 8.69803 22.0608C8.22763 21.8211 7.84517 21.4387 7.60549 20.9683C7.33301 20.4335 7.33301 19.7334 7.33301 18.3333V9.66663C7.33301 8.26649 7.33301 7.56643 7.60549 7.03165C7.84517 6.56124 8.22763 6.17879 8.69803 5.93911C9.23281 5.66663 9.93288 5.66663 11.333 5.66663H14.0095C14.621 5.66663 14.9267 5.66663 15.2144 5.7357C15.4695 5.79694 15.7134 5.89795 15.9371 6.03503C16.1893 6.18963 16.4055 6.40582 16.8379 6.8382L19.4948 9.49505C19.9271 9.92743 20.1433 10.1436 20.2979 10.3959C20.435 10.6196 20.536 10.8635 20.5973 11.1185C20.6663 11.4063 20.6663 11.712 20.6663 12.3235Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
