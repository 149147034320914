import React from "react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider as _QueryClientProvider,
} from "@tanstack/react-query";
import { useLogout } from "./hooks/useLogout";
import toast from "react-hot-toast";
import { ResponseError } from "@norma-bi/bi-api";
import { throttle } from "@norma-bi/utils";

export function QueryClientProvider(props: React.PropsWithChildren<{ retries?: boolean }>) {
  const { retries = true } = props;

  const [mustLogout, setMustLogout] = React.useState(false);

  const showUnknownErrorToast = React.useMemo(
    () =>
      throttle(() => {
        toast.error("Something went wrong, please try again later");
      }, 1000),
    [],
  );

  const onError = React.useCallback(
    (err: Error) => {
      showUnknownErrorToast();

      if (err instanceof ResponseError) {
        console.error("Response error: ", err.response);
      }
    },
    [showUnknownErrorToast],
  );

  const queryClient = React.useMemo(() => {
    return new QueryClient({
      defaultOptions: {
        queries: {
          retry: (_, err) => {
            const respErr = err as Partial<{ response: { status: number } }>;
            if (respErr?.response?.status === 401 || respErr?.response?.status === 403) {
              setMustLogout(true);
              return false;
            }
            return retries;
          },
        },
      },
      queryCache: new QueryCache({
        onError: (err, query) => {
          if (query.meta?.preventDefaultErrorHandling) {
            return;
          }
          return onError(err);
        },
      }),
      mutationCache: new MutationCache({
        onError: (err, _vars, _ctx, mutation) => {
          if (mutation.meta?.preventDefaultErrorHandling) {
            return;
          }
          return onError(err);
        },
      }),
    });
  }, [onError, retries]);

  return (
    <_QueryClientProvider client={queryClient}>
      <LogoutHandler logout={mustLogout}>{props.children}</LogoutHandler>
    </_QueryClientProvider>
  );
}

function LogoutHandler(props: React.PropsWithChildren<{ logout: boolean }>) {
  const { logout } = useLogout();

  React.useEffect(() => {
    if (props.logout) {
      logout();
    }
  }, [props.logout, logout]);

  return props.children;
}
