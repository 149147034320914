import React from "react";
import { AllBusiness, isAllBusiness, useAPI } from "../api";
import { Business } from "@norma-bi/bi-api";
import { useGetBusinesses } from "./useGetBusinesses";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAuth } from "../Auth";

export type BusinessContext = {
  setBusiness: (businessId: string) => Promise<void>;
} & (
  | {
      business: Business;
      isLoading: false;
    }
  | {
      business: undefined;
      isLoading: true;
    }
);

export function useBusinessContext(): BusinessContext {
  const api = useAPI();
  const auth = useAuth();
  const businesses = useGetBusinesses();
  const queryClient = useQueryClient();

  const changeSelectedBusiness = useMutation({
    mutationFn: async (businessId: string) => {
      await api.postSelfBusinessContext({
        ChangeBusinessContextRequest: {
          business_context: businessId,
        },
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["self"],
      });
    },
  });

  const businessContext = auth.user?.meta?.norma?.business_context;
  const setBusiness = changeSelectedBusiness.mutateAsync;

  return React.useMemo<BusinessContext>(() => {
    if (!businesses.isSuccess || !businessContext) {
      return {
        business: undefined,
        isLoading: true,
        setBusiness,
      };
    }
    if (isAllBusiness(businessContext)) {
      return {
        business: AllBusiness,
        isLoading: false,
        setBusiness,
      };
    }
    const business = businesses.data?.find((b) => b.id === businessContext);
    if (!business) {
      return {
        business: undefined,
        isLoading: true,
        setBusiness,
      };
    }
    return {
      business,
      isLoading: false,
      setBusiness,
    };
  }, [businesses.isSuccess, businesses.data, businessContext, setBusiness]);
}
