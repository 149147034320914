import React from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useAPI } from "../api";
import liveboardIconsPackage from "@norma-bi/liveboard-icons";
import { useLiveboards } from "../hooks/useLiveboards";
import { ThoughtspotLiveboard } from "@norma-bi/bi-api";

const liveboardIcons: [string, React.FC<void>][] = Object.entries(liveboardIconsPackage);

export const CustomLiveboardCategoryId = "custom-liveboards-category-id";

export type Liveboard = {
  id: string;
  name: string;
  icon: React.ReactNode;
  created_at: number;
  is_favorite: boolean;
};

export type LiveboardCategory = {
  id: string;
  name: string;
  icon: React.ReactNode;
  liveboards: Liveboard[];
};

export type OrderedLiveboardsCategory = {
  id: string;
  liveboards: { id: string }[];
};

function computeLiveboardCategories(
  liveboards: ThoughtspotLiveboard[],
  ordering: OrderedLiveboardsCategory[],
  searchTerm?: string,
  favoritesOnly?: boolean,
): LiveboardCategory[] {
  type liveboard = Liveboard & { defaultIndex: number };
  type liveboardCategory = Omit<LiveboardCategory, "liveboards"> & {
    defaultIndex: number;
    liveboards: liveboard[];
  };

  const map = new Map<string, liveboardCategory>();

  function setCategory(category: liveboardCategory) {
    map.set(category.id, category);
  }

  function initializeAndGetCategory(
    category: Omit<liveboardCategory, "liveboards">,
  ): liveboardCategory {
    if (!map.has(category.id)) {
      const ret = {
        ...category,
        liveboards: [],
      };
      map.set(category.id, ret);
    }
    return map.get(category.id)!;
  }

  function fallbackIcon(timestamp: number): React.ReactNode {
    const idx = timestamp % liveboardIcons.length;
    const ic = liveboardIcons[idx];
    return ic[1]();
  }

  function iconWithFallback(item: { icon: string; hashable: number | string }) {
    const found = liveboardIcons.find((i) => i[0] === item.icon);
    if (!found) {
      if (typeof item.hashable === "number") {
        return fallbackIcon(item.hashable);
      } else {
        return fallbackIcon(cyrb53(item.hashable));
      }
    }
    return found[1]();
  }

  function addLiveboard(liveboard: ThoughtspotLiveboard) {
    // NOTE(yannis): the server returns -1 as the default index when we cannot know the liveboard's
    //  or tag's default ordering. Since we want these liveboards to appear last and we sort in
    //  ascending index order, -1 is replaced with Infinity.
    function translateDefaultIndex(defaultIndex: number) {
      return defaultIndex === -1 ? Infinity : defaultIndex;
    }
    const category = initializeAndGetCategory(
      liveboard.category
        ? {
            ...liveboard.category,
            id: liveboard.category.name,
            defaultIndex: translateDefaultIndex(liveboard.category.index),
            icon: iconWithFallback({
              icon: liveboard.category.icon,
              hashable: liveboard.category.name,
            }),
          }
        : {
            id: CustomLiveboardCategoryId,
            name: "", // NOTE(yannis): This should be empty and let the presentation layer decide its name
            icon: iconWithFallback({ icon: "Custom", hashable: CustomLiveboardCategoryId }),
            defaultIndex: translateDefaultIndex(Infinity),
          },
    );
    category.liveboards.push({
      id: liveboard.liveboard_id,
      name: liveboard.liveboard_name,
      icon: iconWithFallback({ icon: liveboard.icon, hashable: liveboard.created_at }),
      is_favorite: liveboard.is_favorite,
      created_at: liveboard.created_at,
      defaultIndex: translateDefaultIndex(liveboard.index),
    });
    setCategory(category);
  }

  function filterAndOrderLiveboards(ordering: OrderedLiveboardsCategory[]): LiveboardCategory[] {
    const categoriesOrderMap = new Map(ordering.map((cat, index) => [cat.id, index]));
    const liveboardsOrderMaps = new Map(
      ordering.map((c) => {
        const liveboardsOrder = new Map(c.liveboards.map((l, index) => [l.id, index]));
        return [c.id, liveboardsOrder];
      }),
    );

    const ret = Array.from(map.values()).map((c) => {
      const liveboardOrder = liveboardsOrderMaps.get(c.id);
      c.liveboards.sort((a, b) => {
        const orderA = liveboardOrder?.get(a.id);
        const orderB = liveboardOrder?.get(b.id);

        if (orderA === undefined && orderB === undefined) {
          return a.defaultIndex - b.defaultIndex;
        }

        if (orderA === undefined) {
          return 1;
        }
        if (orderB === undefined) {
          return -1;
        }

        return orderA - orderB;
      });
      return c;
    });

    ret.sort((a, b) => {
      const orderA = categoriesOrderMap.get(a.id);
      const orderB = categoriesOrderMap.get(b.id);

      if (orderA === undefined && orderB === undefined) {
        return a.defaultIndex - b.defaultIndex;
      }
      if (orderA === undefined) {
        return 1;
      }
      if (orderB === undefined) {
        return -1;
      }

      return orderA - orderB;
    });

    return ret;
  }

  liveboards.forEach((l) => {
    const satisfiesSearchTerm =
      !searchTerm || l.liveboard_name.toLowerCase().includes(searchTerm.toLowerCase());
    const satisfiesFavoritesOnly = !favoritesOnly || l.is_favorite;
    if (!satisfiesSearchTerm || !satisfiesFavoritesOnly) {
      return;
    }
    addLiveboard(l);
  });

  return filterAndOrderLiveboards(ordering);
}

export function useGetOrderedLiveboardCategories(args: {
  searchTerm: string;
  favoritesOnly: boolean;
}) {
  const queryClient = useQueryClient();
  const api = useAPI();

  const orderQueryKey = ["liveboardsOrders"];

  const orderQuery = useQuery({
    queryKey: orderQueryKey,
    queryFn: async (): Promise<OrderedLiveboardsCategory[]> => {
      const self = await api.getSelf();
      const order = self.meta?.norma?.liveboard_categories_ordered;
      if (!order) {
        return [];
      }
      return order.map((c) => ({ id: c.name, liveboards: c.liveboards }));
    },
    enabled: api.isAuthenticated,
  });

  const query = useLiveboards();

  // NOTE(yannis): This piece of state could be a useMemo and the optimistic update below could
  // be implemented using the queryClient.getQueryData and queryClient.setQueryData. However,
  // this leads to a weird artifact appearing over the liveboard list when the reordering ends
  const [liveboardCategories, setLiveboardCategories] = React.useState<LiveboardCategory[]>([]);
  React.useEffect(() => {
    if (!query.isSuccess || !orderQuery.isSuccess) {
      return;
    }
    setLiveboardCategories(
      computeLiveboardCategories(query.data, orderQuery.data, args.searchTerm, args.favoritesOnly),
    );
  }, [
    query.isSuccess,
    query.data,
    orderQuery.isSuccess,
    orderQuery.data,
    args.searchTerm,
    args.favoritesOnly,
  ]);

  // NOTE(yannis): Optimistic update.
  // See: https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates
  const mutation = useMutation({
    mutationFn: (args: { newLiveboardCategoriesOrder: OrderedLiveboardsCategory[] }) => {
      return api.postSelfEnterpriseWebAppLiveboardsOrder({
        LiveboardCategory: args.newLiveboardCategoriesOrder.map((c) => ({
          name: c.id,
          liveboards: c.liveboards,
        })),
      });
    },
    onMutate: async ({ newLiveboardCategoriesOrder }) => {
      await queryClient.cancelQueries({ queryKey: orderQueryKey });

      const previousLiveboardCategories = [...liveboardCategories];

      setLiveboardCategories(
        computeLiveboardCategories(
          query.data || [],
          newLiveboardCategoriesOrder,
          args.searchTerm,
          args.favoritesOnly,
        ),
      );

      return { previousLiveboardCategories };
    },
    onError: async (_err, _args, ctx) => {
      if (!ctx) {
        return;
      }
      setLiveboardCategories(ctx.previousLiveboardCategories);
    },
    onSettled: async () => {
      await queryClient.invalidateQueries({ queryKey: orderQueryKey });
    },
  });

  return {
    liveboardCategories,
    query,
    mutation,
  };
}

/*
  This was found on GitHub through a Stack Overflow question and was slightly modified to
  match our coding style and to type-annotate the str argument.

    Original Author comment:
    cyrb53 (c) 2018 bryc (github.com/bryc)
    License: Public domain (or MIT if needed). Attribution appreciated.
    A fast and simple 53-bit string hash function with decent collision resistance.
    Largely inspired by MurmurHash2/3, but with a focus on speed/simplicity.
*/
function cyrb53(str: string, seed = 0) {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  let ch: number;
  for (let i = 0; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507);
  h1 ^= Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507);
  h2 ^= Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}
