/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { ThoughtspotLiveboard } from './ThoughtspotLiveboard';
import {
    ThoughtspotLiveboardFromJSON,
    ThoughtspotLiveboardFromJSONTyped,
    ThoughtspotLiveboardToJSON,
    ThoughtspotLiveboardToJSONTyped,
} from './ThoughtspotLiveboard';

/**
 * 
 * @export
 * @interface GetThoughtspotLiveboardsResponse
 */
export interface GetThoughtspotLiveboardsResponse {
    /**
     * 
     * @type {Array<ThoughtspotLiveboard>}
     * @memberof GetThoughtspotLiveboardsResponse
     */
    data: Array<ThoughtspotLiveboard>;
}

/**
 * Check if a given object implements the GetThoughtspotLiveboardsResponse interface.
 */
export function instanceOfGetThoughtspotLiveboardsResponse(value: object): value is GetThoughtspotLiveboardsResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function GetThoughtspotLiveboardsResponseFromJSON(json: any): GetThoughtspotLiveboardsResponse {
    return GetThoughtspotLiveboardsResponseFromJSONTyped(json, false);
}

export function GetThoughtspotLiveboardsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetThoughtspotLiveboardsResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(ThoughtspotLiveboardFromJSON)),
    };
}

export function GetThoughtspotLiveboardsResponseToJSON(json: any): GetThoughtspotLiveboardsResponse {
    return GetThoughtspotLiveboardsResponseToJSONTyped(json, false);
}

export function GetThoughtspotLiveboardsResponseToJSONTyped(value?: GetThoughtspotLiveboardsResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(ThoughtspotLiveboardToJSON)),
    };
}

