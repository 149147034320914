import React from "react";

export function CheckDone01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11934)">
        <path
          d="M3.99992 10.0002L5.33325 11.3335L8.33325 8.3335M5.33325 5.3335V3.46683C5.33325 2.72009 5.33325 2.34672 5.47858 2.06151C5.60641 1.81063 5.81038 1.60665 6.06126 1.47882C6.34648 1.3335 6.71985 1.3335 7.46659 1.3335H12.5333C13.28 1.3335 13.6534 1.3335 13.9386 1.47882C14.1895 1.60665 14.3934 1.81063 14.5213 2.06151C14.6666 2.34672 14.6666 2.72009 14.6666 3.46683V8.5335C14.6666 9.28023 14.6666 9.6536 14.5213 9.93882C14.3934 10.1897 14.1895 10.3937 13.9386 10.5215C13.6534 10.6668 13.28 10.6668 12.5333 10.6668H10.6666M3.46659 14.6668H8.53325C9.27999 14.6668 9.65336 14.6668 9.93857 14.5215C10.1895 14.3937 10.3934 14.1897 10.5213 13.9388C10.6666 13.6536 10.6666 13.2802 10.6666 12.5335V7.46683C10.6666 6.72009 10.6666 6.34672 10.5213 6.06151C10.3934 5.81063 10.1895 5.60665 9.93857 5.47882C9.65336 5.3335 9.27999 5.3335 8.53325 5.3335H3.46659C2.71985 5.3335 2.34648 5.3335 2.06126 5.47882C1.81038 5.60665 1.60641 5.81063 1.47858 6.06151C1.33325 6.34672 1.33325 6.72009 1.33325 7.46683V12.5335C1.33325 13.2802 1.33325 13.6536 1.47858 13.9388C1.60641 14.1897 1.81038 14.3937 2.06126 14.5215C2.34648 14.6668 2.71985 14.6668 3.46659 14.6668Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11934">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
