import React from "react";
import { Dialog } from "../../../components/Dialog";
import { Business } from "@norma-bi/bi-api";

export type RenameDialogProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  business: Business;
  onConfirm: (newDisplayName: string) => void;
};

export function RenameDialog({ show, setShow, business, onConfirm }: RenameDialogProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  React.useEffect(() => {
    if (show) {
      inputRef.current?.focus();
    }
  }, [show]);

  const [displayName, setDisplayName] = React.useState(business.display_name);

  const isDisabled = displayName.trim() === "" || displayName === business.name;

  return (
    <Dialog
      show={show}
      title="Rename Business"
      messageClassName={"justify-start items-start"}
      message={
        <div className="space-y-8 py-10 pl-10 w-full">
          <div className="w-[80%] flex items-center justify-between text-base">
            <label
              htmlFor="businessName"
              className="font-medium text-feintGray whitespace-nowrap mr-4"
            >
              Business Name
            </label>
            <input
              id="businessName"
              type="text"
              value={business.name}
              readOnly
              className="w-[308px] p-2 bg-lightGray border-b border-dark outline-none cursor-not-allowed"
            />
          </div>
          <div className="w-[80%] flex items-center justify-between text-base">
            <label
              htmlFor="displayName"
              className="font-medium text-feintGray whitespace-nowrap mr-4"
            >
              Display Name
            </label>
            <input
              ref={inputRef}
              id="displayName"
              type="text"
              value={displayName}
              onChange={(e) => setDisplayName(e.target.value)}
              className="w-[308px] p-2 bg-lightGray border-b border-dark outline-none text-dark"
            />
          </div>
        </div>
      }
      onConfirm={() => onConfirm(displayName)}
      onCancel={() => setShow(false)}
      confirmText="Update"
      cancelText="Cancel"
      isConfirmDisabled={isDisabled}
    />
  );
}
