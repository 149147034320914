import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../api";

export function useSearchDataFeatureDataSources() {
  const api = useAPI();

  return useQuery({
    queryKey: ["search-data-data-sources"],
    queryFn: async () => {
      const resp = await api.getThoughtspotSearchDataDataSources();
      return resp.data;
    },
    enabled: api.isAuthenticated,
  });
}
