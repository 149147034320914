export function Heart() {
  return (
    <svg width="24" height="24" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.99364 4.27985C8.32752 2.332 5.54914 1.80804 3.46159 3.59168C1.37405 5.37532 1.08016 8.35748 2.71951 10.467C4.08253 12.2209 8.20749 15.9201 9.55943 17.1174C9.71069 17.2513 9.78631 17.3183 9.87453 17.3446C9.95152 17.3676 10.0358 17.3676 10.1128 17.3446C10.201 17.3183 10.2766 17.2513 10.4279 17.1174C11.7798 15.9201 15.9048 12.2209 17.2678 10.467C18.9071 8.35748 18.6491 5.35656 16.5257 3.59168C14.4023 1.8268 11.6598 2.332 9.99364 4.27985Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
