/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue } from './MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue';
import {
    MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueFromJSON,
    MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueFromJSONTyped,
    MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueToJSON,
    MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueToJSONTyped,
} from './MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue';

/**
 * 
 * @export
 * @interface MobileImpersonatorResponseOrganizationsValueBusinessesValue
 */
export interface MobileImpersonatorResponseOrganizationsValueBusinessesValue {
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValue
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValue
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValue
     */
    Ref: string;
    /**
     * 
     * @type {{ [key: string]: MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue; }}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValue
     */
    Users: { [key: string]: MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue; };
}

/**
 * Check if a given object implements the MobileImpersonatorResponseOrganizationsValueBusinessesValue interface.
 */
export function instanceOfMobileImpersonatorResponseOrganizationsValueBusinessesValue(value: object): value is MobileImpersonatorResponseOrganizationsValueBusinessesValue {
    if (!('ID' in value) || value['ID'] === undefined) return false;
    if (!('Name' in value) || value['Name'] === undefined) return false;
    if (!('Ref' in value) || value['Ref'] === undefined) return false;
    if (!('Users' in value) || value['Users'] === undefined) return false;
    return true;
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueFromJSON(json: any): MobileImpersonatorResponseOrganizationsValueBusinessesValue {
    return MobileImpersonatorResponseOrganizationsValueBusinessesValueFromJSONTyped(json, false);
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileImpersonatorResponseOrganizationsValueBusinessesValue {
    if (json == null) {
        return json;
    }
    return {
        
        'ID': json['ID'],
        'Name': json['Name'],
        'Ref': json['Ref'],
        'Users': (mapValues(json['Users'], MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueFromJSON)),
    };
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueToJSON(json: any): MobileImpersonatorResponseOrganizationsValueBusinessesValue {
    return MobileImpersonatorResponseOrganizationsValueBusinessesValueToJSONTyped(json, false);
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueToJSONTyped(value?: MobileImpersonatorResponseOrganizationsValueBusinessesValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ID': value['ID'],
        'Name': value['Name'],
        'Ref': value['Ref'],
        'Users': (mapValues(value['Users'], MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueToJSON)),
    };
}

