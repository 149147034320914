/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue
 */
export interface MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue {
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue
     */
    Name: string;
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue
     */
    Emails: string;
}

/**
 * Check if a given object implements the MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue interface.
 */
export function instanceOfMobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue(value: object): value is MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue {
    if (!('ID' in value) || value['ID'] === undefined) return false;
    if (!('Name' in value) || value['Name'] === undefined) return false;
    if (!('Emails' in value) || value['Emails'] === undefined) return false;
    return true;
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueFromJSON(json: any): MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue {
    return MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueFromJSONTyped(json, false);
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue {
    if (json == null) {
        return json;
    }
    return {
        
        'ID': json['ID'],
        'Name': json['Name'],
        'Emails': json['Emails'],
    };
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueToJSON(json: any): MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue {
    return MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueToJSONTyped(json, false);
}

export function MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValueToJSONTyped(value?: MobileImpersonatorResponseOrganizationsValueBusinessesValueUsersValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ID': value['ID'],
        'Name': value['Name'],
        'Emails': value['Emails'],
    };
}

