import React from "react";

export function MagicWand02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10 2.66683V1.3335M10 10.6668V9.3335M5.33333 6.00016H6.66667M13.3333 6.00016H14.6667M11.8667 7.86683L12.6667 8.66683M11.8667 4.1335L12.6667 3.3335M2 14.0002L8 8.00016M8.13333 4.1335L7.33333 3.3335"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
