/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Business } from './Business';
import {
    BusinessFromJSON,
    BusinessFromJSONTyped,
    BusinessToJSON,
    BusinessToJSONTyped,
} from './Business';

/**
 * 
 * @export
 * @interface GetBusinessesResponse
 */
export interface GetBusinessesResponse {
    /**
     * 
     * @type {Array<Business>}
     * @memberof GetBusinessesResponse
     */
    data: Array<Business>;
}

/**
 * Check if a given object implements the GetBusinessesResponse interface.
 */
export function instanceOfGetBusinessesResponse(value: object): value is GetBusinessesResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function GetBusinessesResponseFromJSON(json: any): GetBusinessesResponse {
    return GetBusinessesResponseFromJSONTyped(json, false);
}

export function GetBusinessesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBusinessesResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(BusinessFromJSON)),
    };
}

export function GetBusinessesResponseToJSON(json: any): GetBusinessesResponse {
    return GetBusinessesResponseToJSONTyped(json, false);
}

export function GetBusinessesResponseToJSONTyped(value?: GetBusinessesResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(BusinessToJSON)),
    };
}

