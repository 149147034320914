import React from "react";

export function MarkerPin04() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8825)">
        <path
          d="M3.3335 9.5244C2.09925 10.0689 1.3335 10.8276 1.3335 11.6668C1.3335 13.3237 4.31826 14.6668 8.00016 14.6668C11.6821 14.6668 14.6668 13.3237 14.6668 11.6668C14.6668 10.8276 13.9011 10.0689 12.6668 9.5244M12.0002 5.3335C12.0002 8.04264 9.00016 9.3335 8.00016 11.3335C7.00016 9.3335 4.00016 8.04264 4.00016 5.3335C4.00016 3.12436 5.79102 1.3335 8.00016 1.3335C10.2093 1.3335 12.0002 3.12436 12.0002 5.3335ZM8.66683 5.3335C8.66683 5.70169 8.36835 6.00016 8.00016 6.00016C7.63197 6.00016 7.3335 5.70169 7.3335 5.3335C7.3335 4.96531 7.63197 4.66683 8.00016 4.66683C8.36835 4.66683 8.66683 4.96531 8.66683 5.3335Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8825">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
