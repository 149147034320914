import { create } from "zustand";
import { createJSONStorage, persist } from "zustand/middleware";

export type SearchDataDataSourceStore = {
  dataSourceId: string;
  setDataSourceId(dataSourceId: string): void;
};

export const useSearchDataDataSourceStore = create(
  persist<SearchDataDataSourceStore>(
    (set) => ({
      dataSourceId: "",
      setDataSourceId(dataSourceId: string) {
        set({ dataSourceId });
      },
    }),
    {
      name: "search_data_data_source_id",
      storage: createJSONStorage(() => localStorage),
    },
  ),
);
