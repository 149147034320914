import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";
import { useAPI } from "../api";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAppMode } from "./useAppMode";

export function useToggleAppMode() {
  const { data: mode } = useAppMode();
  const api = useAPI();
  const queryClient = useQueryClient();

  const toggleAppModeMutation = useMutation({
    mutationFn: () => {
      const targetMode: AppMode = mode === AppMode.lite ? AppMode.pro : AppMode.lite;
      return api.postSelfEnterpriseWebAppMode({
        ChangeModeRequest: { mode: targetMode },
      });
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey: ["appMode"] }),
  });

  return {
    mode,
    toggleAppMode: toggleAppModeMutation.mutateAsync,
  };
}
