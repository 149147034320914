/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ChangeModeRequest
 */
export interface ChangeModeRequest {
    /**
     * 
     * @type {string}
     * @memberof ChangeModeRequest
     */
    mode: ChangeModeRequestModeEnum;
}


/**
 * @export
 */
export const ChangeModeRequestModeEnum = {
    lite: 'lite',
    pro: 'pro'
} as const;
export type ChangeModeRequestModeEnum = typeof ChangeModeRequestModeEnum[keyof typeof ChangeModeRequestModeEnum];


/**
 * Check if a given object implements the ChangeModeRequest interface.
 */
export function instanceOfChangeModeRequest(value: object): value is ChangeModeRequest {
    if (!('mode' in value) || value['mode'] === undefined) return false;
    return true;
}

export function ChangeModeRequestFromJSON(json: any): ChangeModeRequest {
    return ChangeModeRequestFromJSONTyped(json, false);
}

export function ChangeModeRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): ChangeModeRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'mode': json['mode'],
    };
}

export function ChangeModeRequestToJSON(json: any): ChangeModeRequest {
    return ChangeModeRequestToJSONTyped(json, false);
}

export function ChangeModeRequestToJSONTyped(value?: ChangeModeRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'mode': value['mode'],
    };
}

