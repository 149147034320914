import { Action, LiveboardEmbed } from "@thoughtspot/visual-embed-sdk/react";
import { useExpandVisualizationStore } from "../stores/useExpandVisualizationStore";
import { useGetRuntimeFilters } from "../thoughtspot/useGetRuntimeFilters";
import { EXPAND_ACTION, LOCALE_OVERRIDE } from "../thoughtspot/constants";
import { DialogButtonsFooter } from "./DialogButtonsFooter";
import { Modal } from "./Modal";
import { customizations } from "../thoughtspot/styles";

export function ExpandVisualization() {
  const { embed, hide } = useExpandVisualizationStore();

  const runtimeFilters = useGetRuntimeFilters();

  const show = Boolean(embed);
  function onShowChanged(show: boolean) {
    if (!show) hide();
  }

  return (
    <Modal
      show={show}
      onShowChanged={onShowChanged}
      dialogClassname="w-[90%] max-w-[1440px] h-[95%] rounded-3xl"
    >
      <div className="h-full w-full flex flex-col divide-y divide-backgroundGray">
        <span className="font-medium text-xl text-dark px-10 py-6">Expand</span>

        <div className="flex-1">
          {embed && (
            <LiveboardEmbed
              customizations={{
                ...customizations,
                style: {
                  ...customizations.style,
                  customCSS: {
                    ...customizations.style?.customCSS,
                    rules_UNSTABLE: {
                      ...customizations.style?.customCSS?.rules_UNSTABLE,
                      ".answer-title-module__titleText": {
                        "font-size": "1.25rem",
                        "line-height": "1.75rem",
                        "padding-left": "0",
                      },
                      ".answer-content-module__answerVizContainer": {
                        padding: "1.5rem 2.5rem !important",
                      },
                    },
                  },
                },
              }}
              liveboardId={embed.liveboardId}
              vizId={embed.vizId}
              className="h-full w-full"
              frameParams={{ height: "100%" }}
              hideTabPanel
              visibleActions={[
                Action.Pin,
                Action.ShowUnderlyingData,
                Action.SpotIQAnalyze,
                EXPAND_ACTION,
              ]}
              runtimeFilters={runtimeFilters}
              locale={LOCALE_OVERRIDE}
            />
          )}
        </div>

        <DialogButtonsFooter
          confirmText={"Close"}
          onConfirm={() => onShowChanged(false)}
          containerClassName="py-4 px-16"
        />
      </div>
    </Modal>
  );
}
