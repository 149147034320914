import React from "react";

export function Arabic() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3137_16359)">
        <path
          d="M10.6666 5.33322L1.33325 14.6666M11.9999 9.99988H5.99992M4.39992 12.6666H8.89142C9.05448 12.6666 9.13602 12.6666 9.21274 12.6481C9.28076 12.6318 9.34579 12.6049 9.40544 12.5683C9.47272 12.5271 9.53037 12.4694 9.64567 12.3541L12.9999 8.99988C13.1592 8.84058 13.2389 8.76092 13.303 8.6904C14.6898 7.16477 14.6898 4.835 13.303 3.30937C13.2389 3.23885 13.1592 3.15919 12.9999 2.99988C12.8406 2.84058 12.761 2.76092 12.6904 2.69682C11.1648 1.31005 8.83504 1.31005 7.3094 2.69682C7.23888 2.76092 7.15923 2.84057 6.99992 2.99988L3.64567 6.35413C3.53037 6.46943 3.47272 6.52708 3.43149 6.59436C3.39494 6.65401 3.368 6.71904 3.35167 6.78706C3.33325 6.86379 3.33325 6.94532 3.33325 7.10838V11.5999C3.33325 11.9733 3.33325 12.1599 3.40591 12.3025C3.46983 12.428 3.57182 12.53 3.69726 12.5939C3.83987 12.6666 4.02655 12.6666 4.39992 12.6666Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3137_16359">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
