import React from "react";

export function Move() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11940)">
        <path
          d="M3.33325 6.00016L1.33325 8.00016M1.33325 8.00016L3.33325 10.0002M1.33325 8.00016H14.6666M5.99992 3.3335L7.99992 1.3335M7.99992 1.3335L9.99992 3.3335M7.99992 1.3335V14.6668M9.99992 12.6668L7.99992 14.6668M7.99992 14.6668L5.99992 12.6668M12.6666 6.00016L14.6666 8.00016M14.6666 8.00016L12.6666 10.0002"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11940">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
