import React from "react";

export function Globe02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.77393 12.2259L4.8365 9.16331M12.1448 1.85498C14.6181 4.3283 14.6181 8.33835 12.1448 10.8117C9.6715 13.285 5.66146 13.285 3.18814 10.8117M11.3331 14.6667H4.66648M7.99982 14.6667V12.6667M11.6665 6.33332C11.6665 8.54246 9.87562 10.3333 7.66648 10.3333C5.45734 10.3333 3.66648 8.54246 3.66648 6.33332C3.66648 4.12418 5.45734 2.33332 7.66648 2.33332C9.87562 2.33332 11.6665 4.12418 11.6665 6.33332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
