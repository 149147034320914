export function Info() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.88878 4.44444V13.3333H11.5554V15.1111H4.44434V13.3333H7.111V6.22222H5.33322V4.44444H8.88878ZM8.88878 0V2.66667H7.111V0H8.88878Z"
        fill="#777E8B"
      />
    </svg>
  );
}
