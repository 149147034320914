/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Branch } from './Branch';
import {
    BranchFromJSON,
    BranchFromJSONTyped,
    BranchToJSON,
    BranchToJSONTyped,
} from './Branch';

/**
 * 
 * @export
 * @interface BranchReport
 */
export interface BranchReport {
    /**
     * 
     * @type {Branch}
     * @memberof BranchReport
     */
    branch: Branch;
    /**
     * 
     * @type {number}
     * @memberof BranchReport
     */
    net_sales: number;
}

/**
 * Check if a given object implements the BranchReport interface.
 */
export function instanceOfBranchReport(value: object): value is BranchReport {
    if (!('branch' in value) || value['branch'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    return true;
}

export function BranchReportFromJSON(json: any): BranchReport {
    return BranchReportFromJSONTyped(json, false);
}

export function BranchReportFromJSONTyped(json: any, ignoreDiscriminator: boolean): BranchReport {
    if (json == null) {
        return json;
    }
    return {
        
        'branch': BranchFromJSON(json['branch']),
        'net_sales': json['net_sales'],
    };
}

export function BranchReportToJSON(json: any): BranchReport {
    return BranchReportToJSONTyped(json, false);
}

export function BranchReportToJSONTyped(value?: BranchReport | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'branch': BranchToJSON(value['branch']),
        'net_sales': value['net_sales'],
    };
}

