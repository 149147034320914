import React from "react";
import { useAccessToken } from "../Auth/useAccessToken";
import { useOnboardingProgressStore } from "../stores/OnboardingProgressStore";
import { useExpandVisualizationStore } from "../stores/useExpandVisualizationStore";
import { useQueryClient } from "@tanstack/react-query";

export function useLogout() {
  const queryClient = useQueryClient();
  const { removeAccessToken } = useAccessToken();
  const resetOnboardingProgress = useOnboardingProgressStore((state) => state.reset);
  const resetExpandVisualizationStore = useExpandVisualizationStore((state) => state.reset);

  const logout = React.useCallback(() => {
    queryClient.removeQueries();
    removeAccessToken();
    resetOnboardingProgress();
    resetExpandVisualizationStore();
  }, [queryClient, removeAccessToken, resetOnboardingProgress, resetExpandVisualizationStore]);

  return { logout };
}
