import React from "react";

export function Fi_3815314() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.666 3C9.68138 3 8.76474 3.29616 8 3.80373C7.23526 3.29616 6.31862 3 5.334 3C2.6685 3 0.5 5.16853 0.5 7.834C0.5 10.4995 2.66853 12.668 5.334 12.668C6.31862 12.668 7.23526 12.3718 8 11.8643C8.76474 12.3718 9.68138 12.668 10.666 12.668C13.3315 12.668 15.5 10.4995 15.5 7.834C15.5 5.16853 13.3315 3 10.666 3ZM5.334 11.7891C3.15314 11.7891 1.37891 10.0148 1.37891 7.834C1.37891 5.65316 3.15316 3.87891 5.334 3.87891C6.03983 3.87891 6.70293 4.06497 7.27724 4.39029C6.38574 5.2677 5.83199 6.48731 5.83199 7.834C5.83199 9.1807 6.38574 10.4003 7.27724 11.2777C6.70296 11.603 6.03983 11.7891 5.334 11.7891ZM8.62083 5.63655H7.37914C7.55674 5.37177 7.76542 5.12951 7.99997 4.91511C8.23455 5.12954 8.44323 5.37177 8.62083 5.63655ZM8 10.7529C7.76545 10.5385 7.55677 10.2962 7.37917 10.0315H8.62086C8.44323 10.2962 8.23455 10.5385 8 10.7529ZM9.06281 9.15254H6.93716C6.83785 8.87255 6.76929 8.57821 6.73542 8.27346H9.26452C9.23071 8.57818 9.16212 8.87255 9.06281 9.15254ZM9.26455 7.39455H6.73545C6.76929 7.08983 6.83788 6.79545 6.93719 6.51546H9.06284C9.16212 6.79545 9.23071 7.08983 9.26455 7.39455ZM10.666 11.7891C9.96017 11.7891 9.29704 11.603 8.72276 11.2777C9.61426 10.4003 10.168 9.1807 10.168 7.834C10.168 6.48731 9.61426 5.2677 8.72276 4.39029C9.29707 4.06495 9.96017 3.87891 10.666 3.87891C12.8468 3.87891 14.6211 5.65316 14.6211 7.834C14.6211 10.0148 12.8469 11.7891 10.666 11.7891Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.3"
      />
    </svg>
  );
}
