/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Token
 */
export interface Token {
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    token: string;
    /**
     * 
     * @type {string}
     * @memberof Token
     */
    refreshToken: string;
    /**
     * 
     * @type {Date}
     * @memberof Token
     */
    expiresAt: Date;
}

/**
 * Check if a given object implements the Token interface.
 */
export function instanceOfToken(value: object): value is Token {
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('refreshToken' in value) || value['refreshToken'] === undefined) return false;
    if (!('expiresAt' in value) || value['expiresAt'] === undefined) return false;
    return true;
}

export function TokenFromJSON(json: any): Token {
    return TokenFromJSONTyped(json, false);
}

export function TokenFromJSONTyped(json: any, ignoreDiscriminator: boolean): Token {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
        'refreshToken': json['refreshToken'],
        'expiresAt': (new Date(json['expiresAt'])),
    };
}

export function TokenToJSON(json: any): Token {
    return TokenToJSONTyped(json, false);
}

export function TokenToJSONTyped(value?: Token | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
        'refreshToken': value['refreshToken'],
        'expiresAt': ((value['expiresAt']).toISOString()),
    };
}

