import React from "react";

export function HorizontalBarChart03() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.66667 6.3335V4.06683C8.66667 3.69346 8.66667 3.50678 8.594 3.36417C8.53009 3.23873 8.4281 3.13674 8.30266 3.07283C8.16005 3.00016 7.97337 3.00016 7.6 3.00016H2M11.3333 9.66683V7.40016C11.3333 7.02679 11.3333 6.84011 11.2607 6.6975C11.1968 6.57206 11.0948 6.47007 10.9693 6.40616C10.8267 6.3335 10.64 6.3335 10.2667 6.3335H2M2 1.3335L2 14.6668M2 13.0002H12.9333C13.3067 13.0002 13.4934 13.0002 13.636 12.9275C13.7614 12.8636 13.8634 12.7616 13.9273 12.6362C14 12.4935 14 12.3069 14 11.9335V10.7335C14 10.3601 14 10.1734 13.9273 10.0308C13.8634 9.90539 13.7614 9.80341 13.636 9.73949C13.4934 9.66683 13.3067 9.66683 12.9333 9.66683L2 9.66683L2 13.0002Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
