import React from "react";

export function SpacingWidth01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 8H12M4 8L5.33333 6M4 8L5.33333 10M12 8L10.6667 6M12 8L10.6667 10M14 14V2M2 14V2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
