import React from "react";

export function useIsHovered<Element extends HTMLElement>(): {
  props: React.HTMLAttributes<Element>;
  isHovered: boolean;
} {
  const [isHovered, setIsHovered] = React.useState(false);
  const onMouseEnter = React.useCallback(() => setIsHovered(true), []);
  const onMouseLeave = React.useCallback(() => setIsHovered(false), []);

  return {
    props: { onMouseEnter, onMouseLeave },
    isHovered,
  };
}
