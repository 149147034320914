/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MobileImpersonatorResponseOrganizationsValueBusinessesValue } from './MobileImpersonatorResponseOrganizationsValueBusinessesValue';
import {
    MobileImpersonatorResponseOrganizationsValueBusinessesValueFromJSON,
    MobileImpersonatorResponseOrganizationsValueBusinessesValueFromJSONTyped,
    MobileImpersonatorResponseOrganizationsValueBusinessesValueToJSON,
    MobileImpersonatorResponseOrganizationsValueBusinessesValueToJSONTyped,
} from './MobileImpersonatorResponseOrganizationsValueBusinessesValue';

/**
 * 
 * @export
 * @interface MobileImpersonatorResponseOrganizationsValue
 */
export interface MobileImpersonatorResponseOrganizationsValue {
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValue
     */
    ID: string;
    /**
     * 
     * @type {string}
     * @memberof MobileImpersonatorResponseOrganizationsValue
     */
    Name: string;
    /**
     * 
     * @type {{ [key: string]: MobileImpersonatorResponseOrganizationsValueBusinessesValue; }}
     * @memberof MobileImpersonatorResponseOrganizationsValue
     */
    Businesses: { [key: string]: MobileImpersonatorResponseOrganizationsValueBusinessesValue; };
}

/**
 * Check if a given object implements the MobileImpersonatorResponseOrganizationsValue interface.
 */
export function instanceOfMobileImpersonatorResponseOrganizationsValue(value: object): value is MobileImpersonatorResponseOrganizationsValue {
    if (!('ID' in value) || value['ID'] === undefined) return false;
    if (!('Name' in value) || value['Name'] === undefined) return false;
    if (!('Businesses' in value) || value['Businesses'] === undefined) return false;
    return true;
}

export function MobileImpersonatorResponseOrganizationsValueFromJSON(json: any): MobileImpersonatorResponseOrganizationsValue {
    return MobileImpersonatorResponseOrganizationsValueFromJSONTyped(json, false);
}

export function MobileImpersonatorResponseOrganizationsValueFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileImpersonatorResponseOrganizationsValue {
    if (json == null) {
        return json;
    }
    return {
        
        'ID': json['ID'],
        'Name': json['Name'],
        'Businesses': (mapValues(json['Businesses'], MobileImpersonatorResponseOrganizationsValueBusinessesValueFromJSON)),
    };
}

export function MobileImpersonatorResponseOrganizationsValueToJSON(json: any): MobileImpersonatorResponseOrganizationsValue {
    return MobileImpersonatorResponseOrganizationsValueToJSONTyped(json, false);
}

export function MobileImpersonatorResponseOrganizationsValueToJSONTyped(value?: MobileImpersonatorResponseOrganizationsValue | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'ID': value['ID'],
        'Name': value['Name'],
        'Businesses': (mapValues(value['Businesses'], MobileImpersonatorResponseOrganizationsValueBusinessesValueToJSON)),
    };
}

