import { useReactTable, getCoreRowModel, flexRender, ColumnDef } from "@tanstack/react-table";
import clsx from "clsx";
import React from "react";

export type BaseSettingsTableProps<TData> = {
  data: TData[];
  columns: ColumnDef<TData, keyof TData>[];
  tableClassName?: string;
  title?: React.ReactNode;
  description?: React.ReactNode;
  TopSection?: React.ReactNode;
  onRowClick?: (row: TData) => void;
  renderEditColumn?: React.FC<TData>;
};

export function BaseSettingsTable<TData>({
  data,
  columns,
  tableClassName = "settings-table",
  title,
  description,
  TopSection,
  onRowClick,
  renderEditColumn,
}: BaseSettingsTableProps<TData>) {
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="flex gap-y-4 flex-col h-full overflow-auto">
      {(title || description) && (
        <div className="flex flex-col gap-2">
          {title && <h1 className="text-2xl font-semibold text-dark">{title}</h1>}
          {description && <p className="text-dark">{description}</p>}
        </div>
      )}
      {TopSection}
      <div className="overflow-x-auto">
        <table className={clsx("w-full table-fixed border-collapse", tableClassName)}>
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className="bg-gray-50">
                <th className="w-10 edit-icon-header"></th>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2 text-left text-sm font-medium text-gray-700"
                  >
                    {flexRender(header.column.columnDef.header, header.getContext())}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr
                key={row.id}
                onClick={() => onRowClick?.(row.original)}
                className={clsx("group", onRowClick && "cursor-pointer hover:bg-lightGray")}
              >
                <td className="edit-icon-cell">
                  {renderEditColumn && renderEditColumn(row.original)}
                </td>
                {row.getVisibleCells().map((cell, index) => (
                  <td
                    key={cell.id}
                    className={clsx(
                      "px-4 py-2",
                      index === 0 ? "font-medium" : "font-normal",
                      index === 0 && onRowClick && "group-hover:text-primaryColor",
                    )}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}
