import { Card } from "../../components/Card";

export type CheckYourEmailProps = {
  onFinish: () => void;
  cardClassname?: string;
};

export function CheckYourEmail(props: CheckYourEmailProps) {
  return (
    <Card
      className={props.cardClassname}
      cardTitle={"Check your Email"}
      Footer={
        <>
          <input
            type="button"
            className="thoughtspot-main-button"
            value={"Finish"}
            onClick={props.onFinish}
          />
        </>
      }
    >
      <div className="flex flex-col py-4">
        <span className="content-label">Check your email</span>
        <span className="content-label-subhead">
          We will shortly send an email with a link to reset your password, if we find an account
          tied to this email. Please, click that link to reset your password.
        </span>
      </div>
    </Card>
  );
}
