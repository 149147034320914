export function Liveboards() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.6665 7.73332C10.6665 7.14422 11.1213 6.66666 11.6824 6.66666H14.984C15.545 6.66666 15.9998 7.14422 15.9998 7.73332V14.9333C15.9998 15.5224 15.545 16 14.984 16H11.6824C11.1213 16 10.6665 15.5224 10.6665 14.9333L10.6665 7.73332Z"
        fill="currentColor"
      />
      <path
        d="M0 1.08475C0 0.485658 0.477563 0 1.06667 0H8.26667C8.85577 0 9.33333 0.485657 9.33333 1.08475V14.9153C9.33333 15.5143 8.85577 16 8.26667 16H1.06667C0.477563 16 0 15.5143 0 14.9153L0 1.08475Z"
        fill="currentColor"
      />
      <path
        d="M10.6665 1.12281C10.6665 0.502698 11.1213 0 11.6824 0L14.984 6.6925e-08C15.545 6.6925e-08 15.9998 0.502698 15.9998 1.12281V4.21053C15.9998 4.83064 15.545 5.33333 14.984 5.33333H11.6824C11.1213 5.33333 10.6665 4.83064 10.6665 4.21053V1.12281Z"
        fill="currentColor"
      />
    </svg>
  );
}
