import React from "react";

export function Cryptocurrency03() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8827)">
        <path
          d="M1.3335 1.3335L2.66683 2.66683M14.6668 1.3335L13.3335 2.66683M14.6668 14.6668L13.3335 13.3335M1.3335 14.6668L2.66683 13.3335M1.3335 10.6668H2.3335M5.3335 1.3335V2.3335M14.6668 5.3335H13.6668M10.6668 14.6668V13.6668M12.0002 10.6668H14.3335M10.6668 1.3335V4.00016M1.3335 5.3335H4.00016M5.3335 14.6668V12.0002M10.6668 8.00016C10.6668 9.47292 9.47292 10.6668 8.00016 10.6668C6.5274 10.6668 5.3335 9.47292 5.3335 8.00016C5.3335 6.5274 6.5274 5.3335 8.00016 5.3335C9.47292 5.3335 10.6668 6.5274 10.6668 8.00016Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8827">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
