import React from "react";

export function Fi_4753365() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.5898 7.14322H14.2897C15.8466 5.51449 14.6701 2.7432 12.3932 2.75001C10.1164 2.7432 8.93975 5.51493 10.4967 7.14322H5.5033C7.06014 5.51449 5.88362 2.7432 3.60673 2.75001C1.32998 2.74318 0.153301 5.5149 1.71025 7.14322H1.41016C1.18364 7.14322 1 7.32686 1 7.55338V9.31065C1 9.53717 1.18364 9.72081 1.41016 9.72081H7.0098L4.19539 12.5352C4.07809 12.6525 4.04298 12.8289 4.10647 12.9822C4.16996 13.1355 4.31953 13.2354 4.4854 13.2354H11.5145C11.6804 13.2354 11.83 13.1355 11.8935 12.9822C11.9569 12.8289 11.9219 12.6525 11.8046 12.5352L8.9902 9.72081H14.5898C14.8164 9.72081 15 9.53717 15 9.31065V7.55335C15 7.32684 14.8164 7.14322 14.5898 7.14322ZM3.60676 3.5703C4.59182 3.5703 5.39321 4.37172 5.39321 5.35675C5.30341 7.72348 1.90975 7.72283 1.82031 5.35672C1.82031 4.37169 2.62173 3.5703 3.60676 3.5703ZM10.5244 12.4151H5.47563L8 9.8907L10.5244 12.4151ZM14.1797 8.9005H1.82031V7.96351H14.1797V8.9005ZM12.3932 7.14322C11.4082 7.14322 10.6068 6.34181 10.6068 5.35675C10.6966 2.99001 14.0902 2.99067 14.1797 5.35675C14.1797 6.34181 13.3783 7.14322 12.3932 7.14322Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.4"
      />
    </svg>
  );
}
