export function Home() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.66614 0.888885C1.6839 0.888885 0.888672 1.68522 0.888672 2.66666V13.3333C0.888672 14.3152 1.68461 15.1111 2.66645 15.1111H13.3331C14.315 15.1111 15.1109 14.3152 15.1109 13.3333V7.11111H7.99978V0.888885H2.66614ZM6.222 7.11111V2.66666H2.66614L2.66627 7.11111H6.222ZM2.66632 8.88889H6.222V13.3333H2.66645L2.66632 8.88889ZM7.99978 8.88889H13.3331V13.3333H7.99978V8.88889Z"
        fill="currentColor"
      />
      <path
        d="M11.5553 0.888885H13.3331V2.66666H15.1109V4.44444H13.3331V6.22222H11.5553V4.44444H9.77756V2.66666H11.5553V0.888885Z"
        fill="currentColor"
      />
    </svg>
  );
}
