/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserIdentity
 */
export interface UserIdentity {
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof UserIdentity
     */
    business_id: string;
}

/**
 * Check if a given object implements the UserIdentity interface.
 */
export function instanceOfUserIdentity(value: object): value is UserIdentity {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('business_id' in value) || value['business_id'] === undefined) return false;
    return true;
}

export function UserIdentityFromJSON(json: any): UserIdentity {
    return UserIdentityFromJSONTyped(json, false);
}

export function UserIdentityFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserIdentity {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'business_id': json['business_id'],
    };
}

export function UserIdentityToJSON(json: any): UserIdentity {
    return UserIdentityToJSONTyped(json, false);
}

export function UserIdentityToJSONTyped(value?: UserIdentity | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'business_id': value['business_id'],
    };
}

