export function Sales() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      stroke="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M11.6667 17.5V13.6833" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.1667 17.5V13.6833" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M16.6667 17.5V13.6833" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M10.8335 17.4997H17.5002" strokeLinecap="round" strokeLinejoin="round" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.8335 13.6833H17.5002V12.1974L14.1668 10.8333L10.8335 12.1974V13.6833Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M14.1668 5.83341H13.3335" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5 11.6667H5.83333" strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M8.33333 14.1666H4.58333C3.43274 14.1666 2.5 13.2338 2.5 12.0833V5.41659C2.5 4.86405 2.71949 4.33415 3.11019 3.94345C3.5009 3.55275 4.0308 3.33325 4.58333 3.33325H14.5833C15.7339 3.33325 16.6667 4.26599 16.6667 5.41659V8.33325"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.1102 10.2232C7.2966 9.40963 7.2966 8.09054 8.1102 7.27694C8.92379 6.46335 10.2429 6.46335 11.0565 7.27694"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
