import React from "react";

export function Compare3() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11888)">
        <path
          d="M3.78125 3.28125C3.78125 4.05666 4.41209 4.6875 5.1875 4.6875C5.96291 4.6875 6.59375 4.05666 6.59375 3.28125C6.59375 3.23088 6.58562 3.18081 6.56969 3.133L5.83784 0.9375H7.53125V5.65625H6.125C5.86613 5.65625 5.65625 5.86613 5.65625 6.125C5.65625 6.38387 5.86613 6.59375 6.125 6.59375H9.875C10.1339 6.59375 10.3438 6.38387 10.3438 6.125C10.3438 5.86613 10.1339 5.65625 9.875 5.65625H8.46875V0.9375H10.1622L9.43031 3.133C9.41437 3.18081 9.40625 3.23084 9.40625 3.28125C9.40625 4.05666 10.0371 4.6875 10.8125 4.6875C11.5879 4.6875 12.2188 4.05666 12.2188 3.28125C12.2188 3.23088 12.2106 3.18081 12.1947 3.13303L11.4629 0.9375H11.75C12.0089 0.9375 12.2188 0.727625 12.2188 0.46875C12.2188 0.209875 12.0089 0 11.75 0H4.25C3.99112 0 3.78125 0.209875 3.78125 0.46875C3.78125 0.727625 3.99112 0.9375 4.25 0.9375H4.53716L3.80531 3.133C3.78937 3.18081 3.78125 3.23088 3.78125 3.28125ZM10.8125 3.75C10.5755 3.75 10.379 3.57319 10.348 3.34456L10.8125 1.95109L11.277 3.34456C11.246 3.57322 11.0495 3.75 10.8125 3.75ZM5.1875 3.75C4.95047 3.75 4.754 3.57319 4.723 3.34456L5.1875 1.95109L5.652 3.34456C5.621 3.57322 5.42453 3.75 5.1875 3.75Z"
          fill="currentColor"
        />
        <path
          d="M0.46875 16H6.125C6.38387 16 6.59375 15.7901 6.59375 15.5312V9.875C6.59375 8.58266 5.54234 7.53125 4.25 7.53125H0.46875C0.209875 7.53125 0 7.74112 0 8V15.5312C0 15.7901 0.209875 16 0.46875 16ZM5.57562 9.40625H4.71875V8.54937C5.11763 8.69084 5.43416 9.00738 5.57562 9.40625ZM0.9375 8.46875H3.78125V9.875C3.78125 10.1339 3.99113 10.3438 4.25 10.3438H5.65625V15.0625H0.9375V8.46875Z"
          fill="currentColor"
        />
        <path
          d="M13.6562 7.53125H9.875C9.61612 7.53125 9.40625 7.74112 9.40625 8V15.5312C9.40625 15.7901 9.61612 16 9.875 16H15.5312C15.7901 16 16 15.7901 16 15.5312V9.875C16 8.58266 14.9486 7.53125 13.6562 7.53125ZM14.9819 9.40625H14.125V8.54937C14.5239 8.69084 14.8404 9.00738 14.9819 9.40625ZM10.3438 15.0625V8.46875H13.1875V9.875C13.1875 10.1339 13.3974 10.3438 13.6562 10.3438H15.0625V15.0625H10.3438Z"
          fill="currentColor"
        />
        <path
          d="M3.91858 11.8873L2.84377 12.9621L2.70645 12.8248C2.52342 12.6417 2.22661 12.6417 2.04355 12.8248C1.86048 13.0079 1.86048 13.3046 2.04355 13.4877L2.5123 13.9565C2.69533 14.1395 2.99214 14.1395 3.1752 13.9565L4.58145 12.5502C4.76452 12.3671 4.76452 12.0704 4.58145 11.8873C4.39842 11.7042 4.10161 11.7042 3.91858 11.8873Z"
          fill="currentColor"
        />
        <path
          d="M13.9877 11.4185C13.8047 11.2355 13.5079 11.2355 13.3248 11.4185L12.7188 12.0246L12.1127 11.4185C11.9297 11.2355 11.6329 11.2355 11.4498 11.4185C11.2667 11.6016 11.2667 11.8984 11.4498 12.0815L12.0559 12.6875L11.4498 13.2935C11.2668 13.4766 11.2668 13.7734 11.4498 13.9565C11.6329 14.1395 11.9297 14.1395 12.1127 13.9565L12.7188 13.3504L13.3248 13.9565C13.5079 14.1395 13.8047 14.1395 13.9877 13.9565C14.1708 13.7734 14.1708 13.4766 13.9877 13.2936L13.3817 12.6875L13.9877 12.0815C14.1708 11.8984 14.1708 11.6016 13.9877 11.4185Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11888">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
