import { ErrorEvent, ExceptionEvent, TimeoutEvent } from "./xhrEventSource";

export class SSEEmitterGenericError extends Error {
  type = "error";
  event: ErrorEvent;
  constructor(evt: ErrorEvent) {
    super(evt.message);
    this.event = evt;
  }
}

export class SSEEmitterExceptionError extends Error {
  type = "exception";
  event: ExceptionEvent;
  constructor(evt: ExceptionEvent) {
    super("sse emitter exception error");
    this.event = evt;
  }
}

export class SSEEmitterTimeoutError extends Error {
  type = "timeout";
  event: TimeoutEvent;
  constructor(evt: TimeoutEvent) {
    super("sse emitter timeout error");
    this.event = evt;
  }
}

export class SSEEmitterMalformedMessageError extends Error {
  type = "malformed-message";
  parseError: unknown;
  malformedMessage: unknown;

  constructor(parseError: unknown, malformedMessage: unknown) {
    super("sse emitter malformed message error");
    this.parseError = parseError;
    this.malformedMessage = malformedMessage;
  }
}

export type SSEEmitterErrorEvent =
  | SSEEmitterGenericError
  | SSEEmitterExceptionError
  | SSEEmitterTimeoutError
  | SSEEmitterMalformedMessageError;
