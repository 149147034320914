import React from "react";

export function Cryptocurrency02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.9187 13.3933C10.7797 14.2208 9.40797 14.6665 8.00011 14.6665C6.59224 14.6665 5.22052 14.2208 4.08154 13.3933M10.9226 2.00788C12.188 2.62504 13.2255 3.62696 13.8864 4.87003C14.5474 6.11309 14.7978 7.5335 14.6019 8.92766M1.39838 8.92759C1.20245 7.53343 1.4529 6.11303 2.11385 4.86996C2.7748 3.62689 3.81232 2.62498 5.0777 2.00781M7.62298 4.37696L4.37723 7.62271C4.24522 7.75472 4.17922 7.82072 4.15449 7.89683C4.13274 7.96378 4.13274 8.0359 4.15449 8.10284C4.17922 8.17895 4.24522 8.24496 4.37723 8.37696L7.62298 11.6227C7.75499 11.7547 7.82099 11.8207 7.8971 11.8455C7.96405 11.8672 8.03616 11.8672 8.10311 11.8455C8.17922 11.8207 8.24522 11.7547 8.37723 11.6227L11.623 8.37696C11.755 8.24496 11.821 8.17895 11.8457 8.10284C11.8675 8.0359 11.8675 7.96378 11.8457 7.89683C11.821 7.82072 11.755 7.75472 11.623 7.62272L8.37723 4.37696C8.24522 4.24496 8.17922 4.17895 8.10311 4.15422C8.03616 4.13247 7.96405 4.13247 7.8971 4.15422C7.82099 4.17895 7.75499 4.24496 7.62298 4.37696Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
