import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../api";

export function useAppMode() {
  const api = useAPI();

  return useQuery({
    queryKey: ["appMode"],
    queryFn: async () => {
      const self = await api.getSelf();
      return self.meta?.norma?.enterprise_web_mode;
    },
    enabled: api.isAuthenticated,
    staleTime: Infinity,
  });
}
