import React from "react";

export function Disc02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8831)">
        <path
          d="M10.0002 3.78258C11.5768 4.53156 12.6668 6.13857 12.6668 8.00016M5.5951 12.0001C4.23988 11.1835 3.3335 9.69768 3.3335 8.00013M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016ZM10.0002 8.00016C10.0002 9.10473 9.10473 10.0002 8.00016 10.0002C6.89559 10.0002 6.00016 9.10473 6.00016 8.00016C6.00016 6.89559 6.89559 6.00016 8.00016 6.00016C9.10473 6.00016 10.0002 6.89559 10.0002 8.00016Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8831">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
