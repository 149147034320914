import React from "react";
import * as thoughtspot from "@thoughtspot/visual-embed-sdk";
import { customizations } from "./styles";
import { useThoughtSpotToken } from "./useThoughtSpotToken";
import * as Sentry from "@sentry/react";
import { ThoughtSpotStatus } from "./status";

const MAX_RETRIES = 5;

const tsInitSpan = Sentry.startInactiveSpan({ name: "thoughtpost initialization" });

export function useInitializeThoughtspot() {
  const [status, setStatus] = React.useState<ThoughtSpotStatus>(ThoughtSpotStatus.Pending);
  const { token, invalidateCurrentToken } = useThoughtSpotToken();

  function initTSSDK(thoughtspotAuthToken: string) {
    return thoughtspot.init({
      thoughtSpotHost: "https://norma.thoughtspot.cloud",
      authType: thoughtspot.AuthType.TrustedAuthTokenCookieless,
      getAuthToken: async () => thoughtspotAuthToken,
      customizations: customizations,
    });
  }

  const retries = React.useRef(0);
  React.useEffect(() => {
    if (!token) {
      return;
    }

    return Sentry.startSpanManual(
      {
        name: "thoughtspot init try",
        parentSpan: tsInitSpan,
        attributes: {
          retryCount: retries.current,
        },
      },
      (span) => {
        setStatus(ThoughtSpotStatus.Pending);

        const authStatus = initTSSDK(token);

        function maybeRetry() {
          // keep the tsInitSpan active when retrying

          if (retries.current < MAX_RETRIES) {
            setStatus(ThoughtSpotStatus.Pending);
            retries.current++;
            invalidateCurrentToken();
          } else {
            setStatus(ThoughtSpotStatus.Failed);

            span.addEvent("max retries exceeded");
            tsInitSpan.end();
          }
        }

        function logoutHandler() {
          span.addEvent("logout");
          maybeRetry();
          span.end();
        }

        function failureHandler(failureType: thoughtspot.AuthFailureType) {
          span.addEvent("failure", { failureType });
          console.error("thoughtspot failure: ", failureType);
          maybeRetry();
          span.end();
        }

        function successHandler() {
          span.addEvent("success");
          console.log("thoughtspot success");
          setStatus(ThoughtSpotStatus.Authenticated);
          span.end();
          tsInitSpan.end();
        }

        authStatus.once(thoughtspot.AuthStatus.LOGOUT, logoutHandler);
        authStatus.once(thoughtspot.AuthStatus.FAILURE, failureHandler);
        authStatus.once(thoughtspot.AuthStatus.SDK_SUCCESS, successHandler);

        return () => {
          authStatus.off(thoughtspot.AuthStatus.LOGOUT, logoutHandler, undefined, true);
          authStatus.off(thoughtspot.AuthStatus.FAILURE, failureHandler, undefined, true);
          authStatus.off(thoughtspot.AuthStatus.SDK_SUCCESS, successHandler, undefined, true);
        };
      },
    );
  }, [token, invalidateCurrentToken]);

  return status;
}
