import React from "react";

export function BarChartSquareUp() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99992 2.00016H10.7999C11.92 2.00016 12.4801 2.00016 12.9079 2.21815C13.2842 2.4099 13.5902 2.71586 13.7819 3.09218C13.9999 3.52001 13.9999 4.08006 13.9999 5.20016V10.8002C13.9999 11.9203 13.9999 12.4803 13.7819 12.9081C13.5902 13.2845 13.2842 13.5904 12.9079 13.7822C12.4801 14.0002 11.92 14.0002 10.7999 14.0002H5.19992C4.07981 14.0002 3.51976 14.0002 3.09194 13.7822C2.71561 13.5904 2.40965 13.2845 2.21791 12.9081C1.99992 12.4803 1.99992 11.9203 1.99992 10.8002V8.00016M5.33325 8.66683V11.3335M10.6666 7.3335V11.3335M7.99992 4.66683V11.3335M1.33325 3.3335L3.33325 1.3335M3.33325 1.3335L5.33325 3.3335M3.33325 1.3335L3.33325 5.3335"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
