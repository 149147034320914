export function UpDownArrows() {
  return (
    <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 18.5L9 24.5L14.07 18.5H4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.07 11.5L9.07 5.5L4 11.5H14.07Z"
        fill="currentColor"
      />
    </svg>
  );
}
