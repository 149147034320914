import React from "react";

export function Dropper() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11970)">
        <path
          d="M7.00016 4.33337L11.6668 9.00004M1.3335 14.6667C1.3335 14.6667 4.3335 14.3334 6.00016 12.6667L14.0002 4.66671C14.7365 3.93033 14.7365 2.73642 14.0002 2.00004C13.2638 1.26366 12.0699 1.26366 11.3335 2.00004L3.3335 10C1.66683 11.6667 1.3335 14.6667 1.3335 14.6667Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11970">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
