import React from "react";

export function Feather() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8850)">
        <path
          d="M10.6668 5.33322L1.3335 14.6666M12.0002 9.99988H6.00016M4.40016 12.6666H8.89167C9.05473 12.6666 9.13626 12.6666 9.21298 12.6481C9.28101 12.6318 9.34604 12.6049 9.40569 12.5683C9.47296 12.5271 9.53061 12.4694 9.64591 12.3541L13.0002 8.99988C13.1595 8.84058 13.2391 8.76092 13.3032 8.6904C14.69 7.16477 14.69 4.835 13.3032 3.30937C13.2391 3.23885 13.1595 3.15919 13.0002 2.99988C12.8409 2.84058 12.7612 2.76092 12.6907 2.69682C11.165 1.31005 8.83528 1.31005 7.30965 2.69682C7.23913 2.76092 7.15947 2.84057 7.00016 2.99988L3.64592 6.35413C3.53061 6.46943 3.47296 6.52708 3.43174 6.59436C3.39518 6.65401 3.36825 6.71904 3.35192 6.78706C3.3335 6.86379 3.3335 6.94532 3.3335 7.10838V11.5999C3.3335 11.9733 3.3335 12.1599 3.40616 12.3025C3.47007 12.428 3.57206 12.53 3.6975 12.5939C3.84011 12.6666 4.02679 12.6666 4.40016 12.6666Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8850">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
