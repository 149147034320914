/* eslint-disable react-refresh/only-export-components */
import React from "react";
import {
  AuthenticatedAPIInterface,
  OnboardingProgress,
  OnboardingProgressSSEEmitter,
  SSEEmitterInterface,
  createAuthenticatedAPI,
} from "@norma-bi/bi-api";
import { BASE_URL } from "../constants";
import { useAccessToken } from "../Auth/useAccessToken";
import * as Sentry from "@sentry/react";
import { useThoughtSpotToken } from "../thoughtspot";
import { useInitialOnboardingToken } from "../pages/Onboarding/InitialOnboarding/useInitialOnboardingToken";

function reportError(err: string) {
  Sentry.captureEvent({
    level: "error",
    message: `API Error: ${err}`,
  });
}

export const apiContext = React.createContext<AuthenticatedAPIInterface>(
  createAuthenticatedAPI({
    basePath: BASE_URL,
    accessToken: undefined,
    reportError,
  }),
);

export function APIProvider(props: React.PropsWithChildren) {
  const { accessToken } = useAccessToken();
  const { token: thoughtspotToken } = useThoughtSpotToken();
  const { token: initialOnboardingToken } = useInitialOnboardingToken();

  const api = React.useMemo(() => {
    const headers = new Headers();
    if (thoughtspotToken) {
      headers.set("X-THOUGHTSPOT-TOKEN", thoughtspotToken);
    }

    function accessTokenFn(securityName?: string) {
      if (securityName === "Foodics_Initial_Onboarding") {
        return initialOnboardingToken;
      }
      return accessToken;
    }

    return createAuthenticatedAPI({
      accessToken: accessTokenFn,
      basePath: BASE_URL,
      headers,
      reportError,
    });
  }, [accessToken, thoughtspotToken, initialOnboardingToken]);

  return <apiContext.Provider {...props} value={api} />;
}

export function useAPI() {
  return React.useContext(apiContext);
}

export type OnboardingProgressEmitterContext =
  | {
      isAuthenticated: true;
      emitter: SSEEmitterInterface<OnboardingProgress>;
    }
  | {
      isAuthenticated: false;
      emitter: null;
    };

export const onboardingProgressEmitterContext =
  React.createContext<OnboardingProgressEmitterContext>({
    isAuthenticated: false,
    emitter: null,
  });

export function OnboardingProgressEmitterProvider(props: React.PropsWithChildren) {
  const { accessToken } = useAccessToken();

  const value = React.useMemo<OnboardingProgressEmitterContext>(() => {
    if (!accessToken) {
      return {
        isAuthenticated: false,
        emitter: null,
      };
    }
    return {
      isAuthenticated: true,
      emitter: new OnboardingProgressSSEEmitter(BASE_URL, accessToken),
    };
  }, [accessToken]);

  return <onboardingProgressEmitterContext.Provider {...props} value={value} />;
}

export function useOnboardingProgressEmitter() {
  return React.useContext(onboardingProgressEmitterContext);
}
