import React from "react";

export function Candidates1() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11902)">
        <path
          d="M3.65681 2.06847V1.33731C3.65681 0.599906 3.05687 0 2.3195 0C1.58212 0 0.982188 0.599906 0.982188 1.33731V2.04897L0.711781 2.31634C0.259438 2.76356 0 3.38484 0 4.02094V4.30534C0 4.56422 0.209875 4.77409 0.46875 4.77409H4.13462C4.3935 4.77409 4.60337 4.56422 4.60337 4.30534V4.00997C4.60337 3.37334 4.35647 2.77378 3.90809 2.32181L3.65681 2.06847ZM2.3195 0.9375C2.53997 0.9375 2.71931 1.11684 2.71931 1.33731V1.79278H1.91969V1.33731C1.91969 1.11684 2.09903 0.9375 2.3195 0.9375ZM0.949406 3.83659C0.990906 3.51559 1.13944 3.21184 1.37088 2.983L1.6265 2.73028H2.99278L3.2425 2.98203C3.47438 3.21581 3.61781 3.51444 3.65575 3.83659H0.949406Z"
          fill="currentColor"
        />
        <path
          d="M3.90809 7.93457L3.65681 7.68129V6.95011C3.65681 6.2127 3.05691 5.61279 2.3195 5.61279C1.58209 5.61279 0.982188 6.2127 0.982188 6.95007V7.66173L0.711781 7.92911C0.259438 8.37632 0 8.99764 0 9.63373V9.91814C0 10.177 0.209875 10.3869 0.46875 10.3869H4.13462C4.3935 10.3869 4.60337 10.177 4.60337 9.91814V9.62276C4.60337 8.98614 4.35644 8.38657 3.90809 7.93457ZM2.3195 6.55026C2.53997 6.55026 2.71931 6.72961 2.71931 6.95007V7.38873H1.91969V6.95007C1.91969 6.72964 2.09903 6.55026 2.3195 6.55026ZM0.949406 9.44939C0.990906 9.12839 1.13944 8.82464 1.37088 8.59579L1.64353 8.32626H2.97606L3.2425 8.59482C3.47438 8.82861 3.61781 9.12723 3.65575 9.44942H0.949406V9.44939Z"
          fill="currentColor"
        />
        <path
          d="M3.65681 13.2945V12.5634C3.65681 11.826 3.05691 11.2261 2.3195 11.2261C1.58209 11.2261 0.982188 11.826 0.982188 12.5634V13.275L0.711781 13.5424C0.259438 13.9896 0 14.6109 0 15.247V15.5314C0 15.7903 0.209875 16.0002 0.46875 16.0002H4.13462C4.3935 16.0002 4.60337 15.7903 4.60337 15.5314V15.236C4.60337 14.5994 4.35647 13.9999 3.90809 13.5479L3.65681 13.2945ZM2.3195 12.1636C2.53997 12.1636 2.71931 12.3429 2.71931 12.5634V13.0189H1.91969V12.5634C1.91969 12.3429 2.09903 12.1636 2.3195 12.1636ZM0.949406 15.0627C0.990906 14.7417 1.13944 14.4379 1.37088 14.2091L1.6265 13.9564H2.99278L3.2425 14.2081C3.47441 14.4419 3.61781 14.7405 3.65575 15.0627H0.949406Z"
          fill="currentColor"
        />
        <path
          d="M15.5312 0.521973H6.00659C5.74772 0.521973 5.53784 0.731848 5.53784 0.990723V3.78316C5.53784 4.04204 5.74772 4.25191 6.00659 4.25191H15.5312C15.79 4.25191 15.9999 4.04204 15.9999 3.78316V0.990723C15.9999 0.731816 15.79 0.521973 15.5312 0.521973ZM6.47534 1.45947H7.57959V3.31441H6.47534V1.45947ZM15.0624 3.31438H8.51709V1.45947H15.0624V3.31438Z"
          fill="currentColor"
        />
        <path
          d="M12.9659 6.13525H6.00659C5.74772 6.13525 5.53784 6.34513 5.53784 6.604V9.39641C5.53784 9.65529 5.74772 9.86516 6.00659 9.86516H12.9659C13.2248 9.86516 13.4347 9.65529 13.4347 9.39641V6.604C13.4347 6.34513 13.2248 6.13525 12.9659 6.13525ZM6.47534 7.07275H7.57959V8.92766H6.47534V7.07275ZM12.4972 8.92769H8.51709V7.07279H12.4972V8.92769Z"
          fill="currentColor"
        />
        <path
          d="M11.0948 11.748H6.00659C5.74772 11.748 5.53784 11.9579 5.53784 12.2168V15.0092C5.53784 15.2681 5.74772 15.478 6.00659 15.478H11.0948C11.3537 15.478 11.5636 15.2681 11.5636 15.0092V12.2168C11.5636 11.9579 11.3537 11.748 11.0948 11.748ZM6.47534 12.6855H7.57959V14.5405H6.47534V12.6855ZM10.6261 14.5405H8.51709V12.6855H10.6261V14.5405Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11902">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
