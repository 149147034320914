import React from "react";

export function Scale01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11935)">
        <path
          d="M9.33325 14.6668H4.53325M4.53325 14.6668C3.41315 14.6668 2.85309 14.6668 2.42527 14.4488C2.04895 14.2571 1.74299 13.9511 1.55124 13.5748C1.33325 13.147 1.33325 12.5869 1.33325 11.4668M4.53325 14.6668H4.79992C5.92002 14.6668 6.48008 14.6668 6.9079 14.4488C7.28422 14.2571 7.59019 13.9511 7.78193 13.5748C7.99992 13.147 7.99992 12.5869 7.99992 11.4668V11.2002C7.99992 10.0801 7.99992 9.52001 7.78193 9.09218C7.59019 8.71586 7.28422 8.4099 6.9079 8.21815C6.48008 8.00016 5.92002 8.00016 4.79992 8.00016H4.53325C3.41315 8.00016 2.85309 8.00016 2.42527 8.21815C2.04895 8.4099 1.74299 8.71586 1.55124 9.09218C1.33325 9.52001 1.33325 10.0801 1.33325 11.2002V11.4668M1.33325 11.4668V6.66683M6.66659 1.3335H9.33325M14.6666 6.66683V9.3335M11.9999 14.6668C12.6199 14.6668 12.9299 14.6668 13.1842 14.5987C13.8744 14.4137 14.4135 13.8747 14.5984 13.1845C14.6666 12.9301 14.6666 12.6201 14.6666 12.0002M14.6666 4.00016C14.6666 3.38018 14.6666 3.07019 14.5984 2.81586C14.4135 2.12567 13.8744 1.58658 13.1842 1.40164C12.9299 1.3335 12.6199 1.3335 11.9999 1.3335M3.99992 1.3335C3.37994 1.3335 3.06995 1.3335 2.81561 1.40164C2.12543 1.58658 1.58633 2.12567 1.4014 2.81586C1.33325 3.07019 1.33325 3.38018 1.33325 4.00016"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11935">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
