import { SvgIcon } from "../../svg";
import { Link, useLocation, useNavigate } from "react-router";
import { MenuButton, simpleButtonClassName, useMenuButtons } from "./useMenuButtons";
import clsx from "clsx";
import { useSearchDataFeatureDataSources } from "../../hooks/useSearchDataFeatureDataSources";
import { useSearchDataDataSourceStore } from "../../stores/SearchDataDataSource";
import React from "react";
import * as Sentry from "@sentry/react";
import { isSearchDataPath } from "../utils";
import { useFloating, useHover, useInteractions, safePolygon } from "@floating-ui/react";

export function MenuButtons() {
  const location = useLocation();
  const menuButtons = useMenuButtons();

  return (
    <>
      {menuButtons.map((button) => {
        return (
          <Link
            key={button.id}
            to={button.path}
            replace={button.replace}
            className={button.className(button.isSelected(location.pathname))}
          >
            <SvgIcon icon={button.icon} />
            <div>{button.label}</div>
          </Link>
        );
      })}
      <SearchDataButton />
    </>
  );
}

/*
I would very much like to append this button to the buttons list, however its behaviour is so
special that this is impossible
*/
export function SearchDataButton() {
  const navigate = useNavigate();

  const selectedDataSourceId = useSearchDataDataSourceStore((state) => state.dataSourceId);
  const selectDatasourceId = useSearchDataDataSourceStore((state) => state.setDataSourceId);
  const dataSources = useSearchDataFeatureDataSources();

  React.useEffect(() => {
    if (!dataSources.isSuccess || dataSources.data.length === 0) {
      return;
    }

    const fallbackDataSource = dataSources.data[0];

    // if the user has never selected a data source, use the fallback
    if (selectedDataSourceId === "") {
      selectDatasourceId(fallbackDataSource.id);
      return;
    }

    const dataSourceExists = Boolean(dataSources.data.find((ds) => ds.id === selectedDataSourceId));

    // If the selected data source does not exist (e.g. it was deleted)
    if (!dataSourceExists) {
      Sentry.captureEvent({
        level: "error",
        message: `Datasource with id '${selectedDataSourceId}' not found, falling back to the first available.`,
      });
      selectDatasourceId(fallbackDataSource.id);
    }
  }, [dataSources.data, dataSources.isSuccess, selectDatasourceId, selectedDataSourceId]);

  const selectedDataSource = React.useMemo(() => {
    return dataSources.data?.find((d) => d.id === selectedDataSourceId);
  }, [dataSources.data, selectedDataSourceId]);

  const [isOpen, setIsOpen] = React.useState(false);

  const floating = useFloating<HTMLAnchorElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
  });

  const hover = useHover(floating.context, {
    handleClose: safePolygon(),
  });

  const interactions = useInteractions([hover]);

  if (!selectedDataSource || !dataSources.data) {
    return null;
  }

  type DataSourceOption = {
    id: string;
    label: string;
    isSelected: boolean;
  };

  const button: MenuButton & { options: DataSourceOption[] } = {
    id: "search-data",
    path: "/answer",
    replace: false,
    label: `Search ${selectedDataSource.name}`,
    icon: "Search",
    isSelected: isSearchDataPath,
    className: simpleButtonClassName,
    options: dataSources.data.map((ds) => ({
      id: ds.id,
      isSelected: ds.id === selectedDataSourceId,
      label: ds.name,
    })),
  };

  function onSelectOption(opt: DataSourceOption) {
    selectDatasourceId(opt.id);
    navigate(button.path, { replace: button.replace });
  }

  return (
    <>
      <Link
        ref={floating.refs.setReference}
        {...interactions.getReferenceProps()}
        to={button.path}
        replace={button.replace}
        className={simpleButtonClassName(isOpen || button.isSelected(location.pathname))}
      >
        <SvgIcon icon={button.icon} />
        <div>{button.label}</div>

        <div className={clsx(isOpen && "rotat180")}>
          <SvgIcon icon={"CaretDown"} />
        </div>
      </Link>

      {isOpen && (
        <div
          ref={floating.refs.setFloating}
          style={floating.floatingStyles}
          {...interactions.getFloatingProps()}
          className={"mt-3 min-w-44 shadow-xl bg-white flex flex-col rounded-lg p-2"}
        >
          {button.options.map((opt) => {
            return (
              <button
                key={opt.id}
                className={clsx(
                  "p-2 text-left",
                  "hover:bg-backgroundGray",
                  opt.isSelected ? "content-label-subhead text-dark" : "text-feintGray text-sm",
                )}
                onClick={() => onSelectOption(opt)}
              >
                {opt.label}
              </button>
            );
          })}
        </div>
      )}
    </>
  );
}
