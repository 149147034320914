import React from "react";
import { useReactTable, getCoreRowModel, ColumnDef, flexRender } from "@tanstack/react-table";
import { SvgIcon } from "../../../svg";
import clsx from "clsx";
import { FoodicsUser } from "@norma-bi/bi-api";

export type FoodicsUsersTableProps = {
  foodicsUsers: FoodicsUser[];
  selectedFoodicsUsers: { [key: string]: boolean };
  onFoodicsUserSelected: (foodicsUserId: string, selected: boolean) => void;
  headers: string[];
  foodicsUserProperties: (keyof FoodicsUser)[];
  tableClassName?: string;
  isSelectable?: boolean;
  onSelectAll?: (selected: boolean) => void;
};

const SelectionCheckbox = ({
  checked,
  onClick,
}: {
  checked: boolean;
  onClick: (e: React.MouseEvent) => void;
}) => (
  <div className="cursor-pointer" onClick={onClick}>
    <SvgIcon icon={checked ? "CheckboxTicked" : "CheckboxDefault"} />
  </div>
);

export function FoodicsUsersTable({
  foodicsUsers,
  selectedFoodicsUsers,
  onFoodicsUserSelected,
  headers,
  foodicsUserProperties,
  tableClassName = "settings-users-table",
  isSelectable = true,
  onSelectAll,
}: FoodicsUsersTableProps) {
  const allSelected =
    foodicsUsers.length > 0 && foodicsUsers.every((account) => selectedFoodicsUsers[account.id]);

  const columns = React.useMemo<ColumnDef<FoodicsUser>[]>(() => {
    const selectColumn: ColumnDef<FoodicsUser> = {
      id: "select",
      header: () => (
        <div className="flex justify-center">
          <SelectionCheckbox checked={allSelected} onClick={() => onSelectAll?.(!allSelected)} />
        </div>
      ),
      cell: ({ row }) => {
        const account = row.original;
        const isSelected = selectedFoodicsUsers[account.id];
        return (
          <div className="flex items-center justify-center">
            <SelectionCheckbox
              checked={isSelected}
              onClick={(e) => {
                e.stopPropagation();
                onFoodicsUserSelected(account.id, !isSelected);
              }}
            />
          </div>
        );
      },
      size: 50,
    };

    const dataColumns: ColumnDef<FoodicsUser>[] = foodicsUserProperties.map((prop, index) => ({
      id: prop as string,
      header: headers[index],
      accessorKey: prop,
      cell: ({ row }) => {
        const account = row.original;

        if (prop === "email") {
          return (
            <div className="flex items-center gap-2">
              <div
                className={`size-8 min-w-8 min-h-8 text-white capitalize text-center content-center rounded-full ${
                  "is_owner" in account && account.is_owner ? "bg-[#7D55C7]" : "bg-[#74D1EA]"
                }`}
              >
                {account.email[0]}
              </div>
              <span className="font-medium text-sm overflow-hidden break-words">
                {account.email}
              </span>
            </div>
          );
        }

        if (prop === "branches" && "branches" in account) {
          return (
            <span className="font-normal text-sm">{account.branches?.length || 0} Branches</span>
          );
        }

        if (prop === "roles") {
          const roles = account.roles?.map((role) => role.name).join(", ") || "";
          return <span className="font-normal text-sm">{roles}</span>;
        }

        return <span className="font-normal text-sm">{String(account[prop])}</span>;
      },
    }));

    return [selectColumn, ...dataColumns];
  }, [
    headers,
    foodicsUserProperties,
    selectedFoodicsUsers,
    allSelected,
    onSelectAll,
    onFoodicsUserSelected,
  ]);

  const table = useReactTable({
    data: foodicsUsers,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <table className={tableClassName}>
      <thead>
        {table.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th key={header.id} style={{ width: header.getSize() }}>
                {flexRender(header.column.columnDef.header, header.getContext())}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {foodicsUsers.length > 0 ? (
          table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className={clsx({
                "bg-lightGray": selectedFoodicsUsers[row.original.id],
              })}
              onClick={() =>
                isSelectable &&
                onFoodicsUserSelected(row.original.id, !selectedFoodicsUsers[row.original.id])
              }
            >
              {row.getVisibleCells().map((cell) => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan={columns.length} className="text-center content-label-subhead">
              No accounts available
            </td>
          </tr>
        )}
      </tbody>
    </table>
  );
}
