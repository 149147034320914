import React from "react";

export function IntersectCircle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11898)">
        <path
          d="M5.99992 10.6668C8.57725 10.6668 10.6666 8.57749 10.6666 6.00016C10.6666 3.42283 8.57725 1.3335 5.99992 1.3335C3.42259 1.3335 1.33325 3.42283 1.33325 6.00016C1.33325 8.57749 3.42259 10.6668 5.99992 10.6668Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.99992 14.6668C12.5772 14.6668 14.6666 12.5775 14.6666 10.0002C14.6666 7.42283 12.5772 5.3335 9.99992 5.3335C7.42259 5.3335 5.33325 7.42283 5.33325 10.0002C5.33325 12.5775 7.42259 14.6668 9.99992 14.6668Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11898">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
