import React from "react";
import { Navigate, useSearchParams } from "react-router";
import { ResetPasswordForm } from "../../components/ResetPasswordForm";
import { useResetPassword } from "../../hooks/useResetPassword";
import { jwtDecode } from "jwt-decode";
import * as Sentry from "@sentry/react";

export function ResetPassword() {
  const [params] = useSearchParams();

  const token = params.get("token");

  const resetPassword = useResetPassword();

  async function onPasswordReady(newPassword: string) {
    if (!token) {
      Sentry.captureEvent({ message: "token is empty when resetting password" });
      return null;
    }
    resetPassword.mutate({ token, newPassword });
  }

  const hasExpired = React.useMemo(() => {
    if (!token) return false;
    try {
      const decodedToken = jwtDecode(token);
      if (!decodedToken.exp) return false;
      return decodedToken.exp < Date.now() / 1000;
    } catch (e) {
      console.error("failed to decode jwt token: ", e);
      return true;
    }
  }, [token]);

  if (!token) {
    // this is unexpected, so redirect to home
    return <Navigate to="/" replace />;
  }

  return (
    <div className="w-screen h-screen flex justify-center items-center bg-backgroundGray">
      <ResetPasswordForm
        title={"Reset your password"}
        confirmButtonText={"Confirm"}
        onPasswordReady={onPasswordReady}
        isLoading={resetPassword.isPending}
        hasExpired={hasExpired}
      />
    </div>
  );
}
