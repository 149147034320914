import React from "react";

export function AlignTopArrow02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.66675 12V4C6.66675 3.37874 6.66675 3.06812 6.56525 2.82309C6.42993 2.49638 6.17036 2.23682 5.84366 2.10149C5.59863 2 5.288 2 4.66675 2C4.04549 2 3.73486 2 3.48984 2.10149C3.16313 2.23682 2.90357 2.49638 2.76824 2.82309C2.66675 3.06812 2.66675 3.37874 2.66675 4V12C2.66675 12.6213 2.66675 12.9319 2.76824 13.1769C2.90357 13.5036 3.16313 13.7632 3.48984 13.8985C3.73486 14 4.04549 14 4.66675 14C5.288 14 5.59863 14 5.84366 13.8985C6.17036 13.7632 6.42993 13.5036 6.56525 13.1769C6.66675 12.9319 6.66675 12.6213 6.66675 12Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3334 9.33333V4C13.3334 3.37874 13.3334 3.06812 13.2319 2.82309C13.0966 2.49638 12.837 2.23682 12.5103 2.10149C12.2653 2 11.9547 2 11.3334 2C10.7122 2 10.4015 2 10.1565 2.10149C9.8298 2.23682 9.57023 2.49638 9.43491 2.82309C9.33341 3.06812 9.33341 3.37874 9.33341 4V9.33333C9.33341 9.95459 9.33341 10.2652 9.43491 10.5102C9.57023 10.8369 9.8298 11.0965 10.1565 11.2318C10.4015 11.3333 10.7122 11.3333 11.3334 11.3333C11.9547 11.3333 12.2653 11.3333 12.5103 11.2318C12.837 11.0965 13.0966 10.8369 13.2319 10.5102C13.3334 10.2652 13.3334 9.95459 13.3334 9.33333Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
