import React from "react";

export function PuzzlePiece02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8830)">
        <path
          d="M8.00016 1.3335L10.4002 3.7335C12.0002 -0.466504 16.4668 4.00016 12.2668 5.60016L14.6668 8.00016L12.2668 10.4002C10.6668 6.20016 6.20016 10.6668 10.4002 12.2668L8.00016 14.6668L5.60016 12.2668C4.00016 16.4668 -0.466504 12.0002 3.7335 10.4002L1.3335 8.00016L3.7335 5.60016C5.3335 9.80016 9.80016 5.3335 5.60016 3.7335L8.00016 1.3335Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8830">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
