import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

export type AppModeToggleProps = {
  mode: AppMode;
  onToggleMode: () => void;
};

export function AppModeToggle(props: AppModeToggleProps) {
  return (
    <div
      className="flex items-center justify-center px-4 py-6 gap-6 hover:bg-backgroundGray text-light cursor-pointer"
      onClick={props.onToggleMode}
    >
      <div
        className={clsx(
          "text-sm transition-colors duration-300",
          props.mode === AppMode.lite && "text-dark",
        )}
      >
        Lite mode
      </div>
      <Switch
        checked={props.mode === AppMode.pro}
        className="group bg-primaryColor bg-opacity-10 inline-flex h-5 w-10 rounded-2xl transition hover:bg-opacity-40"
      >
        <span className="size-5 rounded-full bg-primaryColor transition group-data-[checked]:translate-x-6" />
      </Switch>
      <div
        className={clsx(
          "text-sm transition-colors duration-300",
          props.mode === AppMode.pro && "text-dark",
        )}
      >
        Pro mode
      </div>
    </div>
  );
}
