/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Business
 */
export interface Business {
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    display_name: string;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    venues_count: number;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    users_count: number;
}

/**
 * Check if a given object implements the Business interface.
 */
export function instanceOfBusiness(value: object): value is Business {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('display_name' in value) || value['display_name'] === undefined) return false;
    if (!('venues_count' in value) || value['venues_count'] === undefined) return false;
    if (!('users_count' in value) || value['users_count'] === undefined) return false;
    return true;
}

export function BusinessFromJSON(json: any): Business {
    return BusinessFromJSONTyped(json, false);
}

export function BusinessFromJSONTyped(json: any, ignoreDiscriminator: boolean): Business {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'display_name': json['display_name'],
        'venues_count': json['venues_count'],
        'users_count': json['users_count'],
    };
}

export function BusinessToJSON(json: any): Business {
    return BusinessToJSONTyped(json, false);
}

export function BusinessToJSONTyped(value?: Business | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'display_name': value['display_name'],
        'venues_count': value['venues_count'],
        'users_count': value['users_count'],
    };
}

