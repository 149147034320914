export function Cross() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.28619 3.71484L3.71484 5.28619L8.42889 10.0002L3.71484 14.7143L5.28619 16.2856L10.0002 11.5716L14.7143 16.2856L16.2856 14.7143L11.5716 10.0002L16.2856 5.28619L14.7143 3.71484L10.0002 8.42889L5.28619 3.71484Z"
        fill="currentColor"
      />
    </svg>
  );
}
