import { useQuery } from "@tanstack/react-query";
import { useAPI } from "../api";

export const LiveboardsQueryKey = "liveboards";

export function useLiveboards() {
  const api = useAPI();

  return useQuery({
    queryKey: [LiveboardsQueryKey],
    queryFn: async () => {
      const r = await api.getThoughtspotLiveboards();
      return r.data;
    },
    enabled: api.isAuthenticated,
  });
}
