import React from "react";

export function Reflect01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.99992 2V4M7.99992 7V9M7.99992 12V14M2.19176 5.32706L5.11703 7.57727C5.30403 7.72111 5.39753 7.79303 5.43113 7.88069C5.46057 7.95751 5.46057 8.04249 5.43113 8.11931C5.39753 8.20697 5.30403 8.27889 5.11703 8.42273L2.19176 10.6729C1.91602 10.885 1.77815 10.9911 1.6625 10.9897C1.56189 10.9885 1.46721 10.9418 1.40489 10.8628C1.33325 10.772 1.33325 10.5981 1.33325 10.2502V5.74979C1.33325 5.40191 1.33325 5.22796 1.40489 5.13716C1.46721 5.05816 1.56189 5.01155 1.6625 5.01031C1.77815 5.0089 1.91602 5.11495 2.19176 5.32706ZM13.8081 5.32706L10.8828 7.57727C10.6958 7.72111 10.6023 7.79303 10.5687 7.88069C10.5393 7.95751 10.5393 8.04249 10.5687 8.11931C10.6023 8.20697 10.6958 8.27889 10.8828 8.42273L13.8081 10.6729C14.0838 10.885 14.2217 10.9911 14.3373 10.9897C14.438 10.9885 14.5326 10.9418 14.595 10.8628C14.6666 10.772 14.6666 10.5981 14.6666 10.2502V5.74979C14.6666 5.40191 14.6666 5.22796 14.595 5.13716C14.5326 5.05816 14.438 5.01155 14.3373 5.01031C14.2217 5.0089 14.0838 5.11495 13.8081 5.32706Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
