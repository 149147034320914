import React from "react";
import { useSelf } from "../hooks/useSelf";
import { useInitializeThoughtspot } from "../thoughtspot/useInitializeThoughtspot";
import { AuthContext, authContext } from "./context";
import * as Sentry from "@sentry/react";
import { mergeUserEmails } from "../utils/mergeUserEmails";

export function AuthProvider({ children }: React.PropsWithChildren) {
  const self = useSelf();

  const thoughtspotStatus = useInitializeThoughtspot();

  const value = React.useMemo<AuthContext>(() => {
    if (self.isLoading) {
      return {
        isHydrated: false,
      };
    }
    if (self.isSuccess) {
      return {
        isHydrated: true,
        isAuthenticated: true,
        user: self.data,
        thoughtspotStatus,
      };
    }
    return {
      isHydrated: true,
      isAuthenticated: false,
      user: undefined,
      thoughtspotStatus: undefined,
    };
  }, [self.isSuccess, self.isLoading, self.data, thoughtspotStatus]);

  React.useEffect(() => {
    if (!value.isHydrated) {
      return;
    }
    if (!value.isAuthenticated) {
      Sentry.setUser(null);
      return;
    }
    Sentry.setUser({
      id: value.user.id,
      email: mergeUserEmails(value.user),
    });
  }, [value.isHydrated, value.isAuthenticated, value.user]);

  return <authContext.Provider value={value}>{children}</authContext.Provider>;
}
