import React from "react";
import { useLocation, useSearchParams } from "react-router";
import { Dialog } from "../../components/Dialog";

export function AlreadyInstalledDialog() {
  const [searchParams, setSearchParams] = useSearchParams();

  const showDialog = searchParams.has("already-installed");
  function closeDialog() {
    setSearchParams((prev) => {
      prev.delete("already-installed");
      return prev;
    });
  }

  const location = useLocation();
  const adminEmails = location.state?.adminEmails || [];

  const MessageContainer = React.useCallback((props: React.PropsWithChildren) => {
    return <div style={{ textAlign: "center" }}>{props.children}</div>;
  }, []);

  let message = (
    <MessageContainer>
      You are not allowed to install the app twice, please either <strong>login</strong> with your
      account, <strong>reset</strong> your password or contact <strong>support</strong>.
    </MessageContainer>
  );

  if (Array.isArray(adminEmails) && adminEmails.length > 0) {
    const adminEmailsStr = `"${adminEmails.join(`", "`)}"`;
    message = (
      <MessageContainer>
        Your business is already installed. The <strong>Admins</strong> of this Business are{" "}
        {adminEmailsStr}. Please try to <strong>reset</strong> the password of one of the admins or
        login with their accounts. If you don't have access to these emails, please contact{" "}
        <strong>Customer Support</strong>.
      </MessageContainer>
    );
  }

  return (
    <Dialog
      show={showDialog}
      title="Already Installed"
      message={message}
      onConfirm={closeDialog}
      onCancel={closeDialog}
      confirmText="Continue"
      messageClassName={"px-20 py-4"}
    />
  );
}
