import React from "react";

export function GridDotsHorizontalCenter() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 2H2.00667M2 8H2.00667M2 14H2.00667M2 11H2.00667M2 5H2.00667M5 2H5.00667M5 8H5.00667M5 14H5.00667M11 2H11.0067M11 8H11.0067M11 14H11.0067M14 2H14.0067M14 8H14.0067M14 14H14.0067M14 11H14.0067M14 5H14.0067M8 14V2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
