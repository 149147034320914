import { useMutation, useQueryClient } from "@tanstack/react-query";
import { isAllBusiness, useAPI } from "../api";
import { customToast } from "../utils/customToast";

export function useOnboardFoodicsAccounts() {
  const queryClient = useQueryClient();
  const api = useAPI();

  return useMutation({
    mutationFn: (args: { businessId: string; accountIds: string[] }) => {
      if (isAllBusiness(args.businessId)) {
        throw new Error("cannot onboard users to business in ALL BUSINESSES context");
      }
      return api.postOnboardUsersToBusiness({
        business_id: args.businessId,
        OnboardFoodicsUsersRequest: {
          ext_ids: args.accountIds,
        },
      });
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: ["users"] });
      await queryClient.invalidateQueries({ queryKey: ["accounts"] });
      customToast("Invitation sent to inactive user.", "success", "bottom");
    },
    onError: (error) => {
      customToast("Failed to send invite", "error", "bottom");
      console.error("Failed to send invite:", error);
    },
  });
}
