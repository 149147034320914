import React from "react";

export function ChartBreakoutSquare() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.33333 2.00016H5.2C4.0799 2.00016 3.51984 2.00016 3.09202 2.21815C2.71569 2.4099 2.40973 2.71586 2.21799 3.09218C2 3.52001 2 4.08006 2 5.20016V10.8002C2 11.9203 2 12.4803 2.21799 12.9081C2.40973 13.2845 2.71569 13.5904 3.09202 13.7822C3.51984 14.0002 4.07989 14.0002 5.2 14.0002H10.8C11.9201 14.0002 12.4802 14.0002 12.908 13.7822C13.2843 13.5904 13.5903 13.2845 13.782 12.9081C14 12.4803 14 11.9203 14 10.8002V8.66683M8 5.3335H10.6667V8.00016M10.3333 2.3335V1.3335M12.9596 3.0406L13.6667 2.3335M13.6735 5.66683H14.6735M2 8.89823C2.43463 8.96534 2.87991 9.00016 3.33333 9.00016C6.25757 9.00016 8.84355 7.55189 10.4131 5.3335"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
