import React from "react";
import { useOutletContext, useParams } from "react-router";
import { useOnboardingStore } from "../store";
import { OutletContext } from "../../components/OutletContext";
import { OnboardUsers } from "../../components/OnboardUsers";
import { useGetInactiveFoodicsAccounts } from "../../../../hooks/useGetInactiveFoodicsAccounts";

export function OnboardUsersInExistingOrganization() {
  const { businessId } = useParams<{ businessId: string }>();
  const onboardingStore = useOnboardingStore();
  const { goToNextStage, cancel } = useOutletContext<OutletContext>();

  if (!businessId) {
    throw new Error("businessId param missing");
  }

  const accounts = useGetInactiveFoodicsAccounts({ businessId });

  return (
    <OnboardUsers
      mainMessage={"Users from New Business"}
      secondaryMessage={
        "The list of users you see below will have access only to the new Business."
      }
      accounts={accounts}
      selectedAccountIds={onboardingStore.selectedAccountIds}
      setSelectedAccountIds={onboardingStore.setSelectedAccountIds}
      onGoToNextStage={goToNextStage}
      previousStageButton={{ show: false }}
      onCancel={cancel}
      skippable
    />
  );
}
