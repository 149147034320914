/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { GetBranchGeneralMetrics200ResponseData } from './GetBranchGeneralMetrics200ResponseData';
import {
    GetBranchGeneralMetrics200ResponseDataFromJSON,
    GetBranchGeneralMetrics200ResponseDataFromJSONTyped,
    GetBranchGeneralMetrics200ResponseDataToJSON,
    GetBranchGeneralMetrics200ResponseDataToJSONTyped,
} from './GetBranchGeneralMetrics200ResponseData';

/**
 * 
 * @export
 * @interface GetBranchGeneralMetrics200Response
 */
export interface GetBranchGeneralMetrics200Response {
    /**
     * 
     * @type {GetBranchGeneralMetrics200ResponseData}
     * @memberof GetBranchGeneralMetrics200Response
     */
    data: GetBranchGeneralMetrics200ResponseData;
}

/**
 * Check if a given object implements the GetBranchGeneralMetrics200Response interface.
 */
export function instanceOfGetBranchGeneralMetrics200Response(value: object): value is GetBranchGeneralMetrics200Response {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function GetBranchGeneralMetrics200ResponseFromJSON(json: any): GetBranchGeneralMetrics200Response {
    return GetBranchGeneralMetrics200ResponseFromJSONTyped(json, false);
}

export function GetBranchGeneralMetrics200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBranchGeneralMetrics200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'data': GetBranchGeneralMetrics200ResponseDataFromJSON(json['data']),
    };
}

export function GetBranchGeneralMetrics200ResponseToJSON(json: any): GetBranchGeneralMetrics200Response {
    return GetBranchGeneralMetrics200ResponseToJSONTyped(json, false);
}

export function GetBranchGeneralMetrics200ResponseToJSONTyped(value?: GetBranchGeneralMetrics200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': GetBranchGeneralMetrics200ResponseDataToJSON(value['data']),
    };
}

