import { Action, SearchEmbed } from "@thoughtspot/visual-embed-sdk/react";
import { useThoughtSpotEmbedCommonProps } from "../../thoughtspot";
import { useSearchDataDataSourceStore } from "../../stores/SearchDataDataSource";

export function SearchEmbedPage() {
  const dataSourceId = useSearchDataDataSourceStore((state) => state.dataSourceId);

  const commonProps = useThoughtSpotEmbedCommonProps();

  if (!dataSourceId) {
    return null;
  }

  return (
    <SearchEmbed
      {...commonProps}
      dataSource={dataSourceId}
      dataPanelV2
      className={"w-full h-full"}
      disabledActions={[Action.ChooseDataSources]}
    />
  );
}
