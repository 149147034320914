export function Dashboard() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="28" height="28" rx="4" fill="#EF4444" />
      <path
        d="M9.00008 12.3334L11.862 15.1953C12.027 15.3603 12.1095 15.4428 12.2047 15.4737C12.2883 15.5009 12.3785 15.5009 12.4622 15.4737C12.5573 15.4428 12.6398 15.3603 12.8048 15.1953L15.1953 12.8048C15.3604 12.6398 15.4429 12.5572 15.538 12.5263C15.6217 12.4991 15.7118 12.4991 15.7955 12.5263C15.8906 12.5572 15.9731 12.6398 16.1382 12.8048L19.0001 15.6667M22.3334 14C22.3334 18.6024 18.6025 22.3334 14.0001 22.3334C9.39771 22.3334 5.66675 18.6024 5.66675 14C5.66675 9.39765 9.39771 5.66669 14.0001 5.66669C18.6025 5.66669 22.3334 9.39765 22.3334 14Z"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
