export function More() {
  return (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4 9C4 9.82843 3.32843 10.5 2.5 10.5C1.67157 10.5 1 9.82843 1 9C1 8.17157 1.67157 7.5 2.5 7.5C3.32843 7.5 4 8.17157 4 9Z"
        fill="#1D232F"
      />
      <path
        d="M10.5 9C10.5 9.82843 9.82843 10.5 9 10.5C8.17157 10.5 7.5 9.82843 7.5 9C7.5 8.17157 8.17157 7.5 9 7.5C9.82843 7.5 10.5 8.17157 10.5 9Z"
        fill="#1D232F"
      />
      <path
        d="M15.5 10.5C16.3284 10.5 17 9.82843 17 9C17 8.17157 16.3284 7.5 15.5 7.5C14.6716 7.5 14 8.17157 14 9C14 9.82843 14.6716 10.5 15.5 10.5Z"
        fill="#1D232F"
      />
    </svg>
  );
}
