export function Edit() {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.8492 0.706956C12.4587 0.316431 11.8255 0.316431 11.435 0.706956L10.7279 1.41406L13.5563 4.24249L14.2634 3.53538C14.6539 3.14486 14.6539 2.51169 14.2634 2.12117L12.8492 0.706956Z"
        fill="currentColor"
      />
      <path
        d="M12.8492 4.9496L10.0208 2.12117L1.12126 11.0207L0.414154 14.5562L3.94969 13.8491L12.8492 4.9496Z"
        fill="currentColor"
      />
    </svg>
  );
}
