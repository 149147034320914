import React from "react";

export type DialogButtonsFooterProps = {
  confirmText: React.ReactNode;
  onConfirm: () => void;
  isConfirmDisabled?: boolean;
  cancelText?: React.ReactNode;
  onCancel?: () => void;
  isCancelDisabled?: boolean;
  containerClassName?: string;
};

export function DialogButtonsFooter(props: DialogButtonsFooterProps) {
  return (
    <div
      className={`w-full bg-white justify-end flex flex-row px-4 text-sm ${props.containerClassName ?? ""}`}
    >
      {props.onCancel && (
        <button
          className="thoughtspot-cancel-button mr-4"
          onClick={props.onCancel}
          disabled={props.isCancelDisabled}
        >
          {props.cancelText}
        </button>
      )}
      <button
        className="thoughtspot-main-button"
        onClick={props.onConfirm}
        disabled={props.isConfirmDisabled}
      >
        {props.confirmText}
      </button>
    </div>
  );
}
