/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RequestResetPasswordRequest
 */
export interface RequestResetPasswordRequest {
    /**
     * 
     * @type {string}
     * @memberof RequestResetPasswordRequest
     */
    email: string;
    /**
     * 
     * @type {number}
     * @memberof RequestResetPasswordRequest
     */
    business_ref: number;
}

/**
 * Check if a given object implements the RequestResetPasswordRequest interface.
 */
export function instanceOfRequestResetPasswordRequest(value: object): value is RequestResetPasswordRequest {
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('business_ref' in value) || value['business_ref'] === undefined) return false;
    return true;
}

export function RequestResetPasswordRequestFromJSON(json: any): RequestResetPasswordRequest {
    return RequestResetPasswordRequestFromJSONTyped(json, false);
}

export function RequestResetPasswordRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestResetPasswordRequest {
    if (json == null) {
        return json;
    }
    return {
        
        'email': json['email'],
        'business_ref': json['business_ref'],
    };
}

export function RequestResetPasswordRequestToJSON(json: any): RequestResetPasswordRequest {
    return RequestResetPasswordRequestToJSONTyped(json, false);
}

export function RequestResetPasswordRequestToJSONTyped(value?: RequestResetPasswordRequest | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'email': value['email'],
        'business_ref': value['business_ref'],
    };
}

