import React from "react";

export function Divider() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2 8H2.00667M5 8H5.00667M11 8H11.0067M8 8H8.00667M14 8H14.0067M14 14V13.4667C14 12.7199 14 12.3466 13.8547 12.0613C13.7268 11.8105 13.5229 11.6065 13.272 11.4787C12.9868 11.3333 12.6134 11.3333 11.8667 11.3333H4.13333C3.3866 11.3333 3.01323 11.3333 2.72801 11.4787C2.47713 11.6065 2.27316 11.8105 2.14532 12.0613C2 12.3466 2 12.7199 2 13.4667V14M14 2V2.53333C14 3.28007 14 3.65344 13.8547 3.93865C13.7268 4.18954 13.5229 4.39351 13.272 4.52134C12.9868 4.66667 12.6134 4.66667 11.8667 4.66667H4.13333C3.3866 4.66667 3.01323 4.66667 2.72801 4.52134C2.47713 4.39351 2.27316 4.18954 2.14532 3.93865C2 3.65344 2 3.28007 2 2.53333V2"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
