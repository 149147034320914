import React from "react";

export function Flag05() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.39409 4.48132H13.3633C13.678 4.48132 13.8354 4.48132 13.9274 4.5475C14.0078 4.60524 14.06 4.69411 14.0715 4.79235C14.0847 4.90495 14.0082 5.04253 13.8554 5.31768L12.9081 7.02273C12.8527 7.12252 12.825 7.17241 12.8141 7.22525C12.8045 7.27202 12.8045 7.32025 12.8141 7.36701C12.825 7.41985 12.8527 7.46975 12.9081 7.56953L13.8554 9.27459C14.0082 9.54974 14.0847 9.68732 14.0715 9.79991C14.06 9.89816 14.0078 9.98703 13.9274 10.0448C13.8354 10.1109 13.678 10.1109 13.3633 10.1109H8.40891C8.0148 10.1109 7.81774 10.1109 7.66721 10.0342C7.5348 9.96678 7.42715 9.85913 7.35968 9.72672C7.28298 9.57619 7.28298 9.37913 7.28298 8.98502V7.29613M4.82002 14.3332L2.00521 3.07391M3.06079 7.29613H8.26817C8.66228 7.29613 8.85933 7.29613 9.00986 7.21943C9.14227 7.15197 9.24993 7.04431 9.31739 6.9119C9.39409 6.76137 9.39409 6.56432 9.39409 6.17021V2.79243C9.39409 2.39832 9.39409 2.20126 9.31739 2.05073C9.24993 1.91832 9.14227 1.81067 9.00986 1.7432C8.85933 1.6665 8.66228 1.6665 8.26817 1.6665H3.0954C2.60385 1.6665 2.35807 1.6665 2.18997 1.76835C2.04264 1.85762 1.93316 1.99783 1.8823 2.16242C1.82426 2.35021 1.88387 2.58864 2.0031 3.06552L3.06079 7.29613Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
