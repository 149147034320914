import { Card } from "../../../../components/Card";
import { useOutletContext } from "react-router";
import { OutletContext } from "../../components/OutletContext";

export function Done() {
  const { goToNextStage, goToPreviousStage, isFinishPending, isFinishError } =
    useOutletContext<OutletContext>();

  let buttonText = "Next";
  if (isFinishPending) {
    buttonText = "Finishing...";
  } else if (isFinishError) {
    buttonText = "Restart";
  }

  return (
    <Card
      cardTitle={"You are ready!"}
      Footer={
        <div className="flex gap-4">
          <button className="thoughtspot-cancel-button" onClick={goToPreviousStage}>
            Back
          </button>
          <button
            disabled={isFinishPending}
            className="thoughtspot-main-button"
            onClick={goToNextStage}
          >
            {buttonText}
          </button>
        </div>
      }
      className="max-w-[60%]"
    >
      <div className="py-6 flex flex-col">
        <span className="content-label-subhead">
          We will need some time to retrieve the new Business' data.
        </span>
      </div>
    </Card>
  );
}
