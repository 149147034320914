/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThoughtspotSchedule
 */
export interface ThoughtspotSchedule {
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotSchedule
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotSchedule
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotSchedule
     */
    liveboard_id: string;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotSchedule
     */
    liveboard_name: string;
    /**
     * 
     * @type {number}
     * @memberof ThoughtspotSchedule
     */
    recipients: number;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotSchedule
     */
    status: string;
    /**
     * 
     * @type {number}
     * @memberof ThoughtspotSchedule
     */
    runs: number;
}

/**
 * Check if a given object implements the ThoughtspotSchedule interface.
 */
export function instanceOfThoughtspotSchedule(value: object): value is ThoughtspotSchedule {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('liveboard_id' in value) || value['liveboard_id'] === undefined) return false;
    if (!('liveboard_name' in value) || value['liveboard_name'] === undefined) return false;
    if (!('recipients' in value) || value['recipients'] === undefined) return false;
    if (!('status' in value) || value['status'] === undefined) return false;
    if (!('runs' in value) || value['runs'] === undefined) return false;
    return true;
}

export function ThoughtspotScheduleFromJSON(json: any): ThoughtspotSchedule {
    return ThoughtspotScheduleFromJSONTyped(json, false);
}

export function ThoughtspotScheduleFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThoughtspotSchedule {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'liveboard_id': json['liveboard_id'],
        'liveboard_name': json['liveboard_name'],
        'recipients': json['recipients'],
        'status': json['status'],
        'runs': json['runs'],
    };
}

export function ThoughtspotScheduleToJSON(json: any): ThoughtspotSchedule {
    return ThoughtspotScheduleToJSONTyped(json, false);
}

export function ThoughtspotScheduleToJSONTyped(value?: ThoughtspotSchedule | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'liveboard_id': value['liveboard_id'],
        'liveboard_name': value['liveboard_name'],
        'recipients': value['recipients'],
        'status': value['status'],
        'runs': value['runs'],
    };
}

