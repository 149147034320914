import { useQuery } from "@tanstack/react-query";
import { isAllBusiness, useAPI } from "../api";
import { demoInactiveFoodicsAccounts } from "../demo";

export function useGetInactiveFoodicsAccounts(args: {
  businessId: string;
  enabled?: boolean;
  userIsDemo?: boolean;
}) {
  const api = useAPI();

  return useQuery({
    queryKey: ["accounts", args.businessId, args.userIsDemo],
    queryFn: async () => {
      if (args.userIsDemo) {
        return demoInactiveFoodicsAccounts;
      }
      return api.getInactiveUsersByBusiness({ business_id: args.businessId! }).then((r) => r.data);
    },
    enabled:
      api.isAuthenticated &&
      Boolean(args.businessId) &&
      Boolean(args.enabled ?? true) &&
      !isAllBusiness(args.businessId),
  });
}
