/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FoodicsBranch } from './FoodicsBranch';
import {
    FoodicsBranchFromJSON,
    FoodicsBranchFromJSONTyped,
    FoodicsBranchToJSON,
    FoodicsBranchToJSONTyped,
} from './FoodicsBranch';
import type { FoodicsRole } from './FoodicsRole';
import {
    FoodicsRoleFromJSON,
    FoodicsRoleFromJSONTyped,
    FoodicsRoleToJSON,
    FoodicsRoleToJSONTyped,
} from './FoodicsRole';

/**
 * 
 * @export
 * @interface FoodicsUser
 */
export interface FoodicsUser {
    /**
     * 
     * @type {string}
     * @memberof FoodicsUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FoodicsUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FoodicsUser
     */
    email: string;
    /**
     * 
     * @type {boolean}
     * @memberof FoodicsUser
     */
    is_owner: boolean;
    /**
     * 
     * @type {Array<FoodicsBranch>}
     * @memberof FoodicsUser
     */
    branches?: Array<FoodicsBranch>;
    /**
     * 
     * @type {Array<FoodicsRole>}
     * @memberof FoodicsUser
     */
    roles?: Array<FoodicsRole>;
}

/**
 * Check if a given object implements the FoodicsUser interface.
 */
export function instanceOfFoodicsUser(value: object): value is FoodicsUser {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('email' in value) || value['email'] === undefined) return false;
    if (!('is_owner' in value) || value['is_owner'] === undefined) return false;
    return true;
}

export function FoodicsUserFromJSON(json: any): FoodicsUser {
    return FoodicsUserFromJSONTyped(json, false);
}

export function FoodicsUserFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodicsUser {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'email': json['email'],
        'is_owner': json['is_owner'],
        'branches': json['branches'] == null ? undefined : ((json['branches'] as Array<any>).map(FoodicsBranchFromJSON)),
        'roles': json['roles'] == null ? undefined : ((json['roles'] as Array<any>).map(FoodicsRoleFromJSON)),
    };
}

export function FoodicsUserToJSON(json: any): FoodicsUser {
    return FoodicsUserToJSONTyped(json, false);
}

export function FoodicsUserToJSONTyped(value?: FoodicsUser | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'email': value['email'],
        'is_owner': value['is_owner'],
        'branches': value['branches'] == null ? undefined : ((value['branches'] as Array<any>).map(FoodicsBranchToJSON)),
        'roles': value['roles'] == null ? undefined : ((value['roles'] as Array<any>).map(FoodicsRoleToJSON)),
    };
}

