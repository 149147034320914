import React from "react";

export function Type02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.6665 4.6665C2.6665 4.04525 2.6665 3.73462 2.768 3.48959C2.90332 3.16289 3.16289 2.90332 3.48959 2.768C3.73462 2.6665 4.04525 2.6665 4.6665 2.6665H11.3332C11.9544 2.6665 12.2651 2.6665 12.5101 2.768C12.8368 2.90332 13.0964 3.16289 13.2317 3.48959C13.3332 3.73462 13.3332 4.04525 13.3332 4.6665M5.33317 13.3332H10.6665M6.83317 2.6665V13.3332M9.1665 2.6665V13.3332"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
