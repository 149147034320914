import React from "react";

export function LifeBuoy02() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8833)">
        <path
          d="M5.64313 5.64315L3.28612 3.28613M3.28612 12.7142L5.64315 10.3572M10.3572 10.3572L12.7142 12.7142M12.7142 3.2861L10.3572 5.64313M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016ZM11.3335 8.00016C11.3335 9.84111 9.84111 11.3335 8.00016 11.3335C6.15921 11.3335 4.66683 9.84111 4.66683 8.00016C4.66683 6.15921 6.15921 4.66683 8.00016 4.66683C9.84111 4.66683 11.3335 6.15921 11.3335 8.00016Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8833">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
