import React from "react";
import { NotFound } from "../pages/NotFound";
import { LoadingScreen } from "../LoadingScreen";
import { useAuth } from "../Auth";
import { Navigate } from "react-router";

export function RequireRoot({ children }: React.PropsWithChildren) {
  const auth = useAuth();

  if (!auth.isHydrated) {
    return <LoadingScreen />;
  }

  if (!auth.isAuthenticated) {
    return <Navigate to={"/login"} replace />;
  }

  if (!auth.user.is_root) {
    return <NotFound />;
  }

  return children;
}
