import React from "react";

export function Compare1() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11897)">
        <path
          d="M7.5 0H3.08709L0 3.08709V11.25H7.5V0ZM2.8125 1.60041V2.8125H1.60041L2.8125 1.60041ZM6.5625 10.3125H0.9375V3.75H3.75V0.9375H6.5625V10.3125Z"
          fill="currentColor"
        />
        <path
          d="M12.9129 0H8.5V11.25H16V3.08709L12.9129 0ZM13.1875 1.60041L14.3996 2.8125H13.1875V1.60041ZM9.4375 10.3125V0.9375H12.25V3.75H15.0625V10.3125H9.4375Z"
          fill="currentColor"
        />
        <path
          d="M10.481 13.3246L11.1438 13.9875L11.7499 13.3815V15.0623H4.24989V13.3815L4.85596 13.9875L5.51883 13.3246L3.78114 11.5869L2.04346 13.3246L2.70633 13.9875L3.31239 13.3815V15.9998H12.6874V13.3815L13.2935 13.9875L13.9563 13.3246L12.2186 11.5869L10.481 13.3246Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11897">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
