/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserAggregate1 } from './UserAggregate1';
import {
    UserAggregate1FromJSON,
    UserAggregate1FromJSONTyped,
    UserAggregate1ToJSON,
    UserAggregate1ToJSONTyped,
} from './UserAggregate1';
import type { Token } from './Token';
import {
    TokenFromJSON,
    TokenFromJSONTyped,
    TokenToJSON,
    TokenToJSONTyped,
} from './Token';

/**
 * 
 * @export
 * @interface LoginResponse
 */
export interface LoginResponse {
    /**
     * 
     * @type {UserAggregate1}
     * @memberof LoginResponse
     */
    user: UserAggregate1;
    /**
     * 
     * @type {Token}
     * @memberof LoginResponse
     */
    token: Token;
    /**
     * 
     * @type {boolean}
     * @memberof LoginResponse
     */
    shouldChangePassword: boolean;
}

/**
 * Check if a given object implements the LoginResponse interface.
 */
export function instanceOfLoginResponse(value: object): value is LoginResponse {
    if (!('user' in value) || value['user'] === undefined) return false;
    if (!('token' in value) || value['token'] === undefined) return false;
    if (!('shouldChangePassword' in value) || value['shouldChangePassword'] === undefined) return false;
    return true;
}

export function LoginResponseFromJSON(json: any): LoginResponse {
    return LoginResponseFromJSONTyped(json, false);
}

export function LoginResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): LoginResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'user': UserAggregate1FromJSON(json['user']),
        'token': TokenFromJSON(json['token']),
        'shouldChangePassword': json['shouldChangePassword'],
    };
}

export function LoginResponseToJSON(json: any): LoginResponse {
    return LoginResponseToJSONTyped(json, false);
}

export function LoginResponseToJSONTyped(value?: LoginResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'user': UserAggregate1ToJSON(value['user']),
        'token': TokenToJSON(value['token']),
        'shouldChangePassword': value['shouldChangePassword'],
    };
}

