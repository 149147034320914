/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IDName } from './IDName';
import {
    IDNameFromJSON,
    IDNameFromJSONTyped,
    IDNameToJSON,
    IDNameToJSONTyped,
} from './IDName';

/**
 * 
 * @export
 * @interface UserAggregateMetaFoodics
 */
export interface UserAggregateMetaFoodics {
    /**
     * 
     * @type {Array<IDName>}
     * @memberof UserAggregateMetaFoodics
     */
    roles?: Array<IDName>;
}

/**
 * Check if a given object implements the UserAggregateMetaFoodics interface.
 */
export function instanceOfUserAggregateMetaFoodics(value: object): value is UserAggregateMetaFoodics {
    return true;
}

export function UserAggregateMetaFoodicsFromJSON(json: any): UserAggregateMetaFoodics {
    return UserAggregateMetaFoodicsFromJSONTyped(json, false);
}

export function UserAggregateMetaFoodicsFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAggregateMetaFoodics {
    if (json == null) {
        return json;
    }
    return {
        
        'roles': json['roles'] == null ? undefined : ((json['roles'] as Array<any>).map(IDNameFromJSON)),
    };
}

export function UserAggregateMetaFoodicsToJSON(json: any): UserAggregateMetaFoodics {
    return UserAggregateMetaFoodicsToJSONTyped(json, false);
}

export function UserAggregateMetaFoodicsToJSONTyped(value?: UserAggregateMetaFoodics | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'roles': value['roles'] == null ? undefined : ((value['roles'] as Array<any>).map(IDNameToJSON)),
    };
}

