export function Search() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.5556 6.66667C11.5556 9.36672 9.36673 11.5556 6.66667 11.5556C3.96661 11.5556 1.77778 9.36672 1.77778 6.66667C1.77778 3.96661 3.96661 1.77778 6.66667 1.77778C9.36673 1.77778 11.5556 3.96661 11.5556 6.66667ZM10.9154 11.8043C9.76183 12.7593 8.28128 13.3333 6.66667 13.3333C2.98477 13.3333 0 10.3486 0 6.66667C0 2.98477 2.98477 0 6.66667 0C10.3486 0 13.3333 2.98477 13.3333 6.66667C13.3333 8.0926 12.8857 9.41398 12.1232 10.4979L15.4793 13.854L14.2222 15.1111L10.9154 11.8043Z"
        fill="currentColor"
      />
    </svg>
  );
}
