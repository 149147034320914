import { useNavigate, useSearchParams } from "react-router";
import { useLogout } from "./hooks/useLogout";
import "./index.css";
import { Dialog } from "./components/Dialog";

export function Logout() {
  const { logout } = useLogout();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  const show = searchParams.has("logout");

  function hideDialog() {
    setSearchParams((curr) => {
      curr.delete("logout");
      return curr;
    });
  }

  function onConfirm() {
    hideDialog();
    navigate("/");
    logout();
  }

  function onCancel() {
    hideDialog();
  }

  return (
    <Dialog
      show={show}
      title="Logout"
      message="Are you sure you want to logout?"
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmText="Confirm"
      cancelText="Cancel"
      className="h-[212px]"
    />
  );
}
