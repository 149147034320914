import React from "react";

export function Mark() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11979)">
        <path
          d="M13.3335 8.00016C13.3335 10.9457 10.9457 13.3335 8.00016 13.3335M13.3335 8.00016C13.3335 5.05464 10.9457 2.66683 8.00016 2.66683M13.3335 8.00016H14.6668M8.00016 13.3335C5.05464 13.3335 2.66683 10.9457 2.66683 8.00016M8.00016 13.3335V14.6668M2.66683 8.00016C2.66683 5.05464 5.05464 2.66683 8.00016 2.66683M2.66683 8.00016H1.3335M8.00016 2.66683V1.3335M10.0002 8.00016C10.0002 9.10473 9.10473 10.0002 8.00016 10.0002C6.89559 10.0002 6.00016 9.10473 6.00016 8.00016C6.00016 6.89559 6.89559 6.00016 8.00016 6.00016C9.10473 6.00016 10.0002 6.89559 10.0002 8.00016Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11979">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
