import { useCookies } from "react-cookie";
import { INITIAL_ONBOARDING_TOKEN_COOKIE } from "../../../constants";
import React from "react";

export function useInitialOnboardingToken() {
  const [{ initial_onboarding_token }, setCookie, removeCookie] = useCookies([
    INITIAL_ONBOARDING_TOKEN_COOKIE,
  ]);

  return {
    token: initial_onboarding_token,
    setToken: React.useCallback(
      (t: string) => setCookie("initial_onboarding_token", t),
      [setCookie],
    ),
    clearToken: React.useCallback(() => removeCookie("initial_onboarding_token"), [removeCookie]),
  };
}
