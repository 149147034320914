import React from "react";
import { Navigate } from "react-router";
import { useAuth } from "./Auth";
import { LoadingScreen } from "./LoadingScreen";

export function Unprotected(props: React.PropsWithChildren<{ thoughtspot?: boolean }>) {
  const auth = useAuth();

  if (!auth.isHydrated) {
    return <LoadingScreen />;
  }

  if (auth.isAuthenticated) {
    return <Navigate to="/" replace />;
  }

  return props.children;
}
