import { create } from "zustand";

export type OnboardingStore = {
  selectedAccountIds: { [key: string]: boolean };

  setSelectedAccountIds: (accountIds: { [key: string]: boolean }) => void;

  reset: () => void;
};

export const useOnboardingStore = create<OnboardingStore>((set) => ({
  selectedAccountIds: {},

  setSelectedAccountIds: (accountIds) => {
    set({ selectedAccountIds: accountIds });
  },

  reset: () => set({ selectedAccountIds: {} }),
}));
