import { create } from "zustand";

export type TSEmbed = {
  liveboardId: string;
  vizId: string;
};

export type ExpandVisualizationStore = {
  embed: TSEmbed | null;
  show: (embed: TSEmbed) => void;
  hide: () => void;
  reset: () => void;
};

const defaultState: () => Pick<ExpandVisualizationStore, "embed"> = () => ({ embed: null });

export const useExpandVisualizationStore = create<ExpandVisualizationStore>((set) => ({
  ...defaultState(),

  show: (embed: TSEmbed) => {
    set({ embed });
  },
  hide: () => {
    set({ embed: null });
  },
  reset: () => {
    set({ ...defaultState() });
  },
}));
