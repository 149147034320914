/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetBranchGeneralMetrics200ResponseData
 */
export interface GetBranchGeneralMetrics200ResponseData {
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    net_sales_with_tax: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    net_sales: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    no_of_orders: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    spend_per_order: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    returns: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    voids: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    discounts: number;
    /**
     * 
     * @type {number}
     * @memberof GetBranchGeneralMetrics200ResponseData
     */
    payments_count: number;
}

/**
 * Check if a given object implements the GetBranchGeneralMetrics200ResponseData interface.
 */
export function instanceOfGetBranchGeneralMetrics200ResponseData(value: object): value is GetBranchGeneralMetrics200ResponseData {
    if (!('net_sales_with_tax' in value) || value['net_sales_with_tax'] === undefined) return false;
    if (!('net_sales' in value) || value['net_sales'] === undefined) return false;
    if (!('no_of_orders' in value) || value['no_of_orders'] === undefined) return false;
    if (!('spend_per_order' in value) || value['spend_per_order'] === undefined) return false;
    if (!('returns' in value) || value['returns'] === undefined) return false;
    if (!('voids' in value) || value['voids'] === undefined) return false;
    if (!('discounts' in value) || value['discounts'] === undefined) return false;
    if (!('payments_count' in value) || value['payments_count'] === undefined) return false;
    return true;
}

export function GetBranchGeneralMetrics200ResponseDataFromJSON(json: any): GetBranchGeneralMetrics200ResponseData {
    return GetBranchGeneralMetrics200ResponseDataFromJSONTyped(json, false);
}

export function GetBranchGeneralMetrics200ResponseDataFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBranchGeneralMetrics200ResponseData {
    if (json == null) {
        return json;
    }
    return {
        
        'net_sales_with_tax': json['net_sales_with_tax'],
        'net_sales': json['net_sales'],
        'no_of_orders': json['no_of_orders'],
        'spend_per_order': json['spend_per_order'],
        'returns': json['returns'],
        'voids': json['voids'],
        'discounts': json['discounts'],
        'payments_count': json['payments_count'],
    };
}

export function GetBranchGeneralMetrics200ResponseDataToJSON(json: any): GetBranchGeneralMetrics200ResponseData {
    return GetBranchGeneralMetrics200ResponseDataToJSONTyped(json, false);
}

export function GetBranchGeneralMetrics200ResponseDataToJSONTyped(value?: GetBranchGeneralMetrics200ResponseData | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'net_sales_with_tax': value['net_sales_with_tax'],
        'net_sales': value['net_sales'],
        'no_of_orders': value['no_of_orders'],
        'spend_per_order': value['spend_per_order'],
        'returns': value['returns'],
        'voids': value['voids'],
        'discounts': value['discounts'],
        'payments_count': value['payments_count'],
    };
}

