/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserAggregateMetaFoodics } from './UserAggregateMetaFoodics';
import {
    UserAggregateMetaFoodicsFromJSON,
    UserAggregateMetaFoodicsFromJSONTyped,
    UserAggregateMetaFoodicsToJSON,
    UserAggregateMetaFoodicsToJSONTyped,
} from './UserAggregateMetaFoodics';
import type { UserAggregateMetaNorma } from './UserAggregateMetaNorma';
import {
    UserAggregateMetaNormaFromJSON,
    UserAggregateMetaNormaFromJSONTyped,
    UserAggregateMetaNormaToJSON,
    UserAggregateMetaNormaToJSONTyped,
} from './UserAggregateMetaNorma';

/**
 * 
 * @export
 * @interface UserAggregateMeta
 */
export interface UserAggregateMeta {
    /**
     * 
     * @type {UserAggregateMetaNorma}
     * @memberof UserAggregateMeta
     */
    norma?: UserAggregateMetaNorma;
    /**
     * 
     * @type {UserAggregateMetaFoodics}
     * @memberof UserAggregateMeta
     */
    foodics?: UserAggregateMetaFoodics;
}

/**
 * Check if a given object implements the UserAggregateMeta interface.
 */
export function instanceOfUserAggregateMeta(value: object): value is UserAggregateMeta {
    return true;
}

export function UserAggregateMetaFromJSON(json: any): UserAggregateMeta {
    return UserAggregateMetaFromJSONTyped(json, false);
}

export function UserAggregateMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAggregateMeta {
    if (json == null) {
        return json;
    }
    return {
        
        'norma': json['norma'] == null ? undefined : UserAggregateMetaNormaFromJSON(json['norma']),
        'foodics': json['foodics'] == null ? undefined : UserAggregateMetaFoodicsFromJSON(json['foodics']),
    };
}

export function UserAggregateMetaToJSON(json: any): UserAggregateMeta {
    return UserAggregateMetaToJSONTyped(json, false);
}

export function UserAggregateMetaToJSONTyped(value?: UserAggregateMeta | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'norma': UserAggregateMetaNormaToJSON(value['norma']),
        'foodics': UserAggregateMetaFoodicsToJSON(value['foodics']),
    };
}

