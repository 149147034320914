/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { MobileImpersonatorResponseOrganizationsValue } from './MobileImpersonatorResponseOrganizationsValue';
import {
    MobileImpersonatorResponseOrganizationsValueFromJSON,
    MobileImpersonatorResponseOrganizationsValueFromJSONTyped,
    MobileImpersonatorResponseOrganizationsValueToJSON,
    MobileImpersonatorResponseOrganizationsValueToJSONTyped,
} from './MobileImpersonatorResponseOrganizationsValue';

/**
 * 
 * @export
 * @interface MobileImpersonatorResponse
 */
export interface MobileImpersonatorResponse {
    /**
     * 
     * @type {{ [key: string]: MobileImpersonatorResponseOrganizationsValue; }}
     * @memberof MobileImpersonatorResponse
     */
    Organizations: { [key: string]: MobileImpersonatorResponseOrganizationsValue; };
}

/**
 * Check if a given object implements the MobileImpersonatorResponse interface.
 */
export function instanceOfMobileImpersonatorResponse(value: object): value is MobileImpersonatorResponse {
    if (!('Organizations' in value) || value['Organizations'] === undefined) return false;
    return true;
}

export function MobileImpersonatorResponseFromJSON(json: any): MobileImpersonatorResponse {
    return MobileImpersonatorResponseFromJSONTyped(json, false);
}

export function MobileImpersonatorResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): MobileImpersonatorResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'Organizations': (mapValues(json['Organizations'], MobileImpersonatorResponseOrganizationsValueFromJSON)),
    };
}

export function MobileImpersonatorResponseToJSON(json: any): MobileImpersonatorResponse {
    return MobileImpersonatorResponseToJSONTyped(json, false);
}

export function MobileImpersonatorResponseToJSONTyped(value?: MobileImpersonatorResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'Organizations': (mapValues(value['Organizations'], MobileImpersonatorResponseOrganizationsValueToJSON)),
    };
}

