import React from "react";

export function CodeBrowser() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6668 6H1.3335M9.3335 11.6667L11.0002 10L9.3335 8.33333M6.66683 8.33333L5.00016 10L6.66683 11.6667M1.3335 5.2L1.3335 10.8C1.3335 11.9201 1.3335 12.4802 1.55148 12.908C1.74323 13.2843 2.04919 13.5903 2.42551 13.782C2.85334 14 3.41339 14 4.53349 14H11.4668C12.5869 14 13.147 14 13.5748 13.782C13.9511 13.5903 14.2571 13.2843 14.4488 12.908C14.6668 12.4802 14.6668 11.9201 14.6668 10.8V5.2C14.6668 4.0799 14.6668 3.51984 14.4488 3.09202C14.2571 2.7157 13.9511 2.40973 13.5748 2.21799C13.147 2 12.5869 2 11.4668 2L4.5335 2C3.41339 2 2.85334 2 2.42552 2.21799C2.04919 2.40973 1.74323 2.71569 1.55148 3.09202C1.3335 3.51984 1.3335 4.0799 1.3335 5.2Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
