import React from "react";
import { Link } from "react-router";

type EmptyTableProps = {
  message: React.ReactNode;
  subtitle: string;
  linkTo: string;
  linkText: string;
  icon: React.ComponentType;
};

export const EmptyTable: React.FC<EmptyTableProps> = ({
  message,
  subtitle,
  linkTo,
  linkText,
  icon: Icon,
}) => {
  return (
    <div className="flex items-center justify-center h-[60vh] min-h-[400px]">
      <div className="flex flex-col items-center text-center">
        <Icon />
        <p className="mt-4 text-xl font-medium">{message}</p>
        <div className="flex">
          <p className="mt-2 text-light">{subtitle}&nbsp;</p>
          <Link
            to={linkTo}
            className="mt-2 text-light underline"
            target="_blank"
            rel="noopener noreferrer"
          >
            {linkText}
          </Link>
        </div>
      </div>
    </div>
  );
};
