import React from "react";
import * as Sentry from "@sentry/react";
import { Link, useRouteError } from "react-router";

export function ErrorBoundary() {
  const error = useRouteError() as Error;

  React.useEffect(() => {
    if (!error) {
      return;
    }

    console.error("error boundary: ", error);
    Sentry.captureException(error);
  }, [error]);

  return (
    <div className="bg-backgroundGray h-screen v-screen flex justify-center items-center">
      <span className="text-lg font-medium">
        An error has occurred, try refreshing the page or{" "}
        <Link to="/" className="underline font-light">
          navigate to the home page
        </Link>
      </span>
    </div>
  );
}
