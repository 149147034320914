/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ThoughtspotLiveboardCategory
 */
export interface ThoughtspotLiveboardCategory {
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotLiveboardCategory
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof ThoughtspotLiveboardCategory
     */
    index: number;
    /**
     * 
     * @type {string}
     * @memberof ThoughtspotLiveboardCategory
     */
    icon: string;
}

/**
 * Check if a given object implements the ThoughtspotLiveboardCategory interface.
 */
export function instanceOfThoughtspotLiveboardCategory(value: object): value is ThoughtspotLiveboardCategory {
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('index' in value) || value['index'] === undefined) return false;
    if (!('icon' in value) || value['icon'] === undefined) return false;
    return true;
}

export function ThoughtspotLiveboardCategoryFromJSON(json: any): ThoughtspotLiveboardCategory {
    return ThoughtspotLiveboardCategoryFromJSONTyped(json, false);
}

export function ThoughtspotLiveboardCategoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): ThoughtspotLiveboardCategory {
    if (json == null) {
        return json;
    }
    return {
        
        'name': json['name'],
        'index': json['index'],
        'icon': json['icon'],
    };
}

export function ThoughtspotLiveboardCategoryToJSON(json: any): ThoughtspotLiveboardCategory {
    return ThoughtspotLiveboardCategoryToJSONTyped(json, false);
}

export function ThoughtspotLiveboardCategoryToJSONTyped(value?: ThoughtspotLiveboardCategory | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'name': value['name'],
        'index': value['index'],
        'icon': value['icon'],
    };
}

