import React from "react";

export function Hand() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.60016 7.62979V9.48165M4.60016 7.62979V3.18535C4.60016 2.5717 5.10757 2.07424 5.7335 2.07424C6.35942 2.07424 6.86683 2.5717 6.86683 3.18535M4.60016 7.62979C4.60016 7.01614 4.09275 6.51868 3.46683 6.51868C2.84091 6.51868 2.3335 7.01614 2.3335 7.62979V9.11127C2.3335 12.1795 4.87055 14.6668 8.00016 14.6668C11.1298 14.6668 13.6668 12.1795 13.6668 9.11127V5.40757C13.6668 4.79392 13.1594 4.29646 12.5335 4.29646C11.9076 4.29646 11.4002 4.79392 11.4002 5.40757M6.86683 3.18535V7.25942M6.86683 3.18535V2.44461C6.86683 1.83096 7.37424 1.3335 8.00016 1.3335C8.62609 1.3335 9.1335 1.83096 9.1335 2.44461V3.18535M9.1335 3.18535V7.25942M9.1335 3.18535C9.1335 2.5717 9.64091 2.07424 10.2668 2.07424C10.8928 2.07424 11.4002 2.5717 11.4002 3.18535V5.40757M11.4002 5.40757V7.25942"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
