import React from "react";
import { Navigate, useSearchParams } from "react-router";
import { Stage } from "../components/StagesIndicator";
import { useInitialOnboardingStore } from "./store";
import { useAPI } from "../../../api";
import { useMutation } from "@tanstack/react-query";
import { OnboardingOrchestrator } from "../components/OnboardingOrchestrator";
import { useInitialOnboardingToken } from "./useInitialOnboardingToken";

export function InitialOnboarding() {
  const [stages] = React.useState<Stage[]>([
    {
      path: "/initial-onboarding/welcome",
    },
    {
      path: "/initial-onboarding/choose-users",
    },
    {
      path: "/initial-onboarding/alert-users",
    },
  ]);

  const [searchParams] = useSearchParams();
  const initialOnboardingTokenSearchParam = searchParams.get("token");
  const { token, setToken, clearToken } = useInitialOnboardingToken();
  React.useEffect(() => {
    if (!initialOnboardingTokenSearchParam) {
      return;
    }
    setToken(initialOnboardingTokenSearchParam);
  }, [initialOnboardingTokenSearchParam, setToken]);

  const initialOnboardingToken = initialOnboardingTokenSearchParam
    ? initialOnboardingTokenSearchParam
    : token;

  const initialOnboarding = useInitialOnboardingStore();

  const api = useAPI();

  const doFinishStep = useMutation({
    mutationFn: () =>
      api.postIntegrationsFoodicsAccountsInitialOnboarding({
        InitialOnboardFoodicsUsersRequest: {
          ext_ids: Object.entries(initialOnboarding.selectedAccountIds)
            .filter(([, isSelected]) => isSelected)
            .map(([id]) => id),
        },
      }),
  });

  function cleanup() {
    clearToken();
    initialOnboarding.reset();
  }

  if (!initialOnboardingToken) {
    return <Navigate to="/" />;
  }

  return (
    <OnboardingOrchestrator
      stages={stages}
      finishMutation={doFinishStep}
      cleanup={cleanup}
      onDone={{ navigateTo: "/" }}
      onCancel={{ navigateTo: "/" }}
      onError={{ navigateTo: "/" }}
    />
  );
}
