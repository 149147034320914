import React from "react";

export function Ruler() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11977)">
        <path
          d="M9.66648 3.66643L10.6665 4.66643M7.66648 5.66643L8.66648 6.66643M5.66648 7.66643L6.66648 8.66643M3.66648 9.66643L4.66648 10.6664M1.71024 11.7102L4.28933 14.2893C4.42134 14.4213 4.48734 14.4873 4.56345 14.5121C4.6304 14.5338 4.70251 14.5338 4.76946 14.5121C4.84557 14.4873 4.91157 14.4213 5.04358 14.2893L14.2893 5.04358C14.4213 4.91157 14.4873 4.84557 14.5121 4.76946C14.5338 4.70251 14.5338 4.6304 14.5121 4.56345C14.4873 4.48734 14.4213 4.42134 14.2893 4.28933L11.7102 1.71024C11.5782 1.57824 11.5122 1.51224 11.4361 1.48751C11.3692 1.46575 11.2971 1.46575 11.2301 1.48751C11.154 1.51224 11.088 1.57824 10.956 1.71024L1.71024 10.956C1.57824 11.088 1.51224 11.154 1.48751 11.2301C1.46575 11.2971 1.46575 11.3692 1.48751 11.4361C1.51224 11.5122 1.57824 11.5782 1.71024 11.7102Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11977">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
