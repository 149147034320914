import React from "react";
import { SvgIconType } from "../../svg";
import { isAnswersPath, isHomePath, isInsightsPath, isLiveboardPath } from "../utils";
import { useAuth } from "../../Auth";

export type MenuButton = {
  id: string;
  icon: SvgIconType;
  label: string;
  isSelected: (pathname: string) => boolean;
  className: (isSelected: boolean) => string;
  path: string;
  replace: boolean;
};

export const simpleButtonClassName = (
  isSelected: boolean,
) => `flex h-full space-x-2 px-6 items-center normaMenuItem border-b-2 hover:border-b-white
            ${isSelected ? "selectedNormaMenuItem border-b-white" : "border-b-transparent"}`;

export function useMenuButtons(): MenuButton[] {
  const { user: currentUser } = useAuth();

  return React.useMemo<MenuButton[]>(() => {
    const ret: MenuButton[] = [
      {
        id: "home",
        label: "Home",
        path: "/home",
        icon: "Home",
        replace: true,
        isSelected: isHomePath,
        className: simpleButtonClassName,
      },
      {
        id: "answers",
        label: "Answers",
        path: "/answers",
        icon: "Answers",
        replace: true,
        isSelected: isAnswersPath,
        className: simpleButtonClassName,
      },
      {
        id: "liveboards",
        label: "Liveboards",
        path: "/insights/pinboards",
        icon: "Liveboards",
        replace: true,
        isSelected: isLiveboardPath,
        className: simpleButtonClassName,
      },
      {
        id: "insights",
        label: "SpotIQ",
        path: "/insights",
        icon: "SpotIQ",
        replace: true,
        isSelected: isInsightsPath,
        className: simpleButtonClassName,
      },
    ];

    if (currentUser?.is_root) {
      ret.push({
        id: "settings",
        label: "Settings",
        path: "/settings",
        icon: "Settings",
        replace: false,
        isSelected: (pathname) => pathname.startsWith("/settings"),
        className: simpleButtonClassName,
      });
    }

    return ret;
  }, [currentUser?.is_root]);
}
