export function Shifts() {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2502 17.0866V16.7481C11.2529 15.7851 12.0329 15.0051 12.9959 15.0024H15.7571C16.7201 15.0051 17.5 15.7851 17.5028 16.7481V17.0866C17.5028 17.3168 17.3162 17.5034 17.0859 17.5034H11.667C11.4368 17.5034 11.2502 17.3168 11.2502 17.0866Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.7085 11.2443C12.7122 10.3243 13.4603 9.58112 14.3803 9.58356C15.3003 9.58602 16.0445 10.3332 16.0432 11.2531C16.042 12.1731 15.2959 12.9183 14.3759 12.9183C13.9325 12.9183 13.5074 12.7417 13.1945 12.4276C12.8816 12.1134 12.7067 11.6876 12.7085 11.2443V11.2443Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.49585 15.8358C2.49585 16.7566 3.24235 17.5031 4.16321 17.5031"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.496 13.3348V15.8358"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49939 17.5036H4.16467"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.49585 15.8358C2.49585 16.7566 3.24235 17.5031 4.16321 17.5031"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.496 13.3348V15.8358"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.49939 17.5036H4.16467"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.49585 15.8358C2.49585 16.7566 3.24235 17.5031 4.16321 17.5031"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.496 13.3348V15.8358"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.33308 17.5036H4.16467"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.49585 10V9.66156C2.4986 8.69856 3.27858 7.91858 4.24158 7.91583H7.00273C7.96572 7.91858 8.74571 8.69856 8.74845 9.66156V10C8.74845 10.2302 8.56183 10.4169 8.33161 10.4169H2.91269C2.68248 10.4169 2.49585 10.2302 2.49585 10Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.95637 4.15758C3.96007 3.2376 4.70819 2.49445 5.62818 2.49689C6.54816 2.49935 7.29232 3.24648 7.29109 4.16647C7.28987 5.08646 6.54374 5.83161 5.62375 5.83161C5.18038 5.83161 4.75526 5.65504 4.44238 5.3409C4.1295 5.02677 3.95461 4.60095 3.95637 4.15758V4.15758Z"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5031 4.19013C17.5031 3.26927 16.7566 2.52277 15.8358 2.52277"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.5025 7.49851V4.18964"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.0002 2.52316H15.8359"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
