import React from "react";

export function BezierCurve03() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.23826 4.66667L3.42873 11.3333M4.00016 12.6667H12M12.5716 11.3333L8.76207 4.66667M2.40016 14H2.9335C3.30686 14 3.49355 14 3.63616 13.9273C3.7616 13.8634 3.86358 13.7614 3.9275 13.636C4.00016 13.4934 4.00016 13.3067 4.00016 12.9333V12.4C4.00016 12.0266 4.00016 11.8399 3.9275 11.6973C3.86358 11.5719 3.7616 11.4699 3.63616 11.406C3.49355 11.3333 3.30686 11.3333 2.9335 11.3333H2.40016C2.02679 11.3333 1.84011 11.3333 1.6975 11.406C1.57206 11.4699 1.47007 11.5719 1.40616 11.6973C1.3335 11.8399 1.3335 12.0266 1.3335 12.4V12.9333C1.3335 13.3067 1.3335 13.4934 1.40616 13.636C1.47007 13.7614 1.57206 13.8634 1.6975 13.9273C1.84011 14 2.02679 14 2.40016 14ZM13.0668 14H13.6002C13.9735 14 14.1602 14 14.3028 13.9273C14.4283 13.8634 14.5303 13.7614 14.5942 13.636C14.6668 13.4934 14.6668 13.3067 14.6668 12.9333V12.4C14.6668 12.0266 14.6668 11.8399 14.5942 11.6973C14.5303 11.5719 14.4283 11.4699 14.3028 11.406C14.1602 11.3333 13.9735 11.3333 13.6002 11.3333H13.0668C12.6935 11.3333 12.5068 11.3333 12.3642 11.406C12.2387 11.4699 12.1367 11.5719 12.0728 11.6973C12.0002 11.8399 12.0002 12.0266 12.0002 12.4V12.9333C12.0002 13.3067 12.0002 13.4934 12.0728 13.636C12.1367 13.7614 12.2387 13.8634 12.3642 13.9273C12.5068 14 12.6935 14 13.0668 14ZM7.7335 4.66667H8.26683C8.6402 4.66667 8.82688 4.66667 8.96949 4.594C9.09493 4.53009 9.19692 4.4281 9.26083 4.30266C9.3335 4.16005 9.3335 3.97337 9.3335 3.6V3.06667C9.3335 2.6933 9.3335 2.50661 9.26083 2.36401C9.19692 2.23856 9.09493 2.13658 8.96949 2.07266C8.82688 2 8.6402 2 8.26683 2H7.7335C7.36013 2 7.17344 2 7.03084 2.07266C6.90539 2.13658 6.80341 2.23856 6.73949 2.36401C6.66683 2.50661 6.66683 2.6933 6.66683 3.06667V3.6C6.66683 3.97337 6.66683 4.16005 6.73949 4.30266C6.80341 4.4281 6.90539 4.53009 7.03084 4.594C7.17344 4.66667 7.36013 4.66667 7.7335 4.66667Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
