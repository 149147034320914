import React from "react";
import { ThoughtSpotStatus } from "../thoughtspot";
import { UserAggregate } from "@norma-bi/bi-api";

type UnhydratedAuthContext = {
  isHydrated: false;
  isAuthenticated: undefined;
  user: undefined;
  thoughtspotStatus: undefined;
};
type UnauthenticatedAuthContext = {
  isHydrated: true;
  isAuthenticated: false;
  user: undefined;
  thoughtspotStatus: undefined;
};
type AuthenticatedAuthContext = {
  isHydrated: true;
  isAuthenticated: true;
  user: UserAggregate;
  thoughtspotStatus: ThoughtSpotStatus;
};

export type AuthContext =
  | AuthenticatedAuthContext
  | UnauthenticatedAuthContext
  | UnhydratedAuthContext;

export const authContext = React.createContext<AuthContext>({
  isHydrated: false,
  isAuthenticated: undefined,
  user: undefined,
  thoughtspotStatus: undefined,
});
