import React from "react";

export function FaceWink() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3126_8834)">
        <path
          d="M5.3335 9.3335C5.3335 9.3335 6.3335 10.6668 8.00016 10.6668C9.66683 10.6668 10.6668 9.3335 10.6668 9.3335M10.0002 6.00016H10.0068M5.3335 6.00016H6.66683M14.6668 8.00016C14.6668 11.6821 11.6821 14.6668 8.00016 14.6668C4.31826 14.6668 1.3335 11.6821 1.3335 8.00016C1.3335 4.31826 4.31826 1.3335 8.00016 1.3335C11.6821 1.3335 14.6668 4.31826 14.6668 8.00016ZM10.3335 6.00016C10.3335 6.18426 10.1843 6.3335 10.0002 6.3335C9.81607 6.3335 9.66683 6.18426 9.66683 6.00016C9.66683 5.81607 9.81607 5.66683 10.0002 5.66683C10.1843 5.66683 10.3335 5.81607 10.3335 6.00016Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3126_8834">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
