import React from "react";

export function Fi_17271980() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.04175 3.3335C7.04175 3.05736 7.25666 2.8335 7.52175 2.8335H12.0017C12.2668 2.8335 12.4817 3.05736 12.4817 3.3335C12.4817 3.60964 12.2668 3.8335 12.0017 3.8335H7.52175C7.25666 3.8335 7.04175 3.60964 7.04175 3.3335Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.54244 6.31295C6.7299 6.11769 7.03383 6.11769 7.22129 6.31295L10.1013 9.31297C10.2887 9.50824 10.2887 9.82477 10.1013 10.02C9.91384 10.2153 9.6099 10.2153 9.42244 10.02L6.54244 7.02004C6.35499 6.82477 6.35499 6.50821 6.54244 6.31295Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.6819 6.8335C3.947 6.8335 4.1619 7.05736 4.1619 7.3335V12.0002C4.1619 12.2763 3.947 12.5002 3.6819 12.5002C3.41681 12.5002 3.2019 12.2763 3.2019 12.0002V7.3335C3.2019 7.05736 3.41681 6.8335 3.6819 6.8335Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60642 1.8335C3.30058 1.8335 2.24198 2.9362 2.24198 4.29646C2.24198 5.65672 3.30058 6.75943 4.60642 6.75943C5.91227 6.75943 6.97086 5.65672 6.97086 4.29646C6.97086 2.9362 5.91227 1.8335 4.60642 1.8335ZM1.28198 4.29646C1.28198 2.38392 2.77039 0.833496 4.60642 0.833496C6.44248 0.833496 7.93086 2.38392 7.93086 4.29646C7.93086 6.209 6.44248 7.75943 4.60642 7.75943C2.77039 7.75943 1.28198 6.209 1.28198 4.29646Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.68188 12.5C3.24004 12.5 2.88188 12.8731 2.88188 13.3333C2.88188 13.7936 3.24004 14.1667 3.68188 14.1667C4.12371 14.1667 4.48187 13.7936 4.48187 13.3333C4.48187 12.8731 4.1237 12.5 3.68188 12.5ZM1.92188 13.3333C1.92188 12.3208 2.70986 11.5 3.68188 11.5C4.65389 11.5 5.44187 12.3208 5.44187 13.3333C5.44187 14.3459 4.65389 15.1667 3.68188 15.1667C2.70986 15.1667 1.92188 14.3459 1.92188 13.3333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7219 9.8335C10.2801 9.8335 9.92191 10.2066 9.92191 10.6668C9.92191 11.1271 10.2801 11.5002 10.7219 11.5002C11.1638 11.5002 11.5219 11.1271 11.5219 10.6668C11.5219 10.2066 11.1638 9.8335 10.7219 9.8335ZM8.96191 10.6668C8.96191 9.6543 9.74988 8.8335 10.7219 8.8335C11.6939 8.8335 12.4819 9.6543 12.4819 10.6668C12.4819 11.6794 11.6939 12.5002 10.7219 12.5002C9.74988 12.5002 8.96191 11.6794 8.96191 10.6668Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.282 2.5C12.8402 2.5 12.482 2.87309 12.482 3.33333C12.482 3.79357 12.8402 4.16667 13.282 4.16667C13.7238 4.16667 14.082 3.79357 14.082 3.33333C14.082 2.87309 13.7238 2.5 13.282 2.5ZM11.522 3.33333C11.522 2.32081 12.3099 1.5 13.282 1.5C14.254 1.5 15.042 2.32081 15.042 3.33333C15.042 4.34585 14.254 5.16667 13.282 5.16667C12.3099 5.16667 11.522 4.34585 11.522 3.33333Z"
        fill="currentColor"
        stroke="currentColor"
        strokeWidth="0.5"
      />
    </svg>
  );
}
