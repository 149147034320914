/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { IDName } from './IDName';
import {
    IDNameFromJSON,
    IDNameFromJSONTyped,
    IDNameToJSON,
    IDNameToJSONTyped,
} from './IDName';

/**
 * 
 * @export
 * @interface Metric
 */
export interface Metric {
    /**
     * 
     * @type {IDName}
     * @memberof Metric
     */
    label: IDName;
    /**
     * 
     * @type {number}
     * @memberof Metric
     */
    value: number;
}

/**
 * Check if a given object implements the Metric interface.
 */
export function instanceOfMetric(value: object): value is Metric {
    if (!('label' in value) || value['label'] === undefined) return false;
    if (!('value' in value) || value['value'] === undefined) return false;
    return true;
}

export function MetricFromJSON(json: any): Metric {
    return MetricFromJSONTyped(json, false);
}

export function MetricFromJSONTyped(json: any, ignoreDiscriminator: boolean): Metric {
    if (json == null) {
        return json;
    }
    return {
        
        'label': IDNameFromJSON(json['label']),
        'value': json['value'],
    };
}

export function MetricToJSON(json: any): Metric {
    return MetricToJSONTyped(json, false);
}

export function MetricToJSONTyped(value?: Metric | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'label': IDNameToJSON(value['label']),
        'value': value['value'],
    };
}

