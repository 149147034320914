/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface GetOnboardBusinessTokenResponse
 */
export interface GetOnboardBusinessTokenResponse {
    /**
     * 
     * @type {string}
     * @memberof GetOnboardBusinessTokenResponse
     */
    token: string;
}

/**
 * Check if a given object implements the GetOnboardBusinessTokenResponse interface.
 */
export function instanceOfGetOnboardBusinessTokenResponse(value: object): value is GetOnboardBusinessTokenResponse {
    if (!('token' in value) || value['token'] === undefined) return false;
    return true;
}

export function GetOnboardBusinessTokenResponseFromJSON(json: any): GetOnboardBusinessTokenResponse {
    return GetOnboardBusinessTokenResponseFromJSONTyped(json, false);
}

export function GetOnboardBusinessTokenResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetOnboardBusinessTokenResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'token': json['token'],
    };
}

export function GetOnboardBusinessTokenResponseToJSON(json: any): GetOnboardBusinessTokenResponse {
    return GetOnboardBusinessTokenResponseToJSONTyped(json, false);
}

export function GetOnboardBusinessTokenResponseToJSONTyped(value?: GetOnboardBusinessTokenResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'token': value['token'],
    };
}

