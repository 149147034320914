/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserIdentity } from './UserIdentity';
import {
    UserIdentityFromJSON,
    UserIdentityFromJSONTyped,
    UserIdentityToJSON,
    UserIdentityToJSONTyped,
} from './UserIdentity';
import type { UserAggregateMeta } from './UserAggregateMeta';
import {
    UserAggregateMetaFromJSON,
    UserAggregateMetaFromJSONTyped,
    UserAggregateMetaToJSON,
    UserAggregateMetaToJSONTyped,
} from './UserAggregateMeta';
import type { UserAggregateOrganization } from './UserAggregateOrganization';
import {
    UserAggregateOrganizationFromJSON,
    UserAggregateOrganizationFromJSONTyped,
    UserAggregateOrganizationToJSON,
    UserAggregateOrganizationToJSONTyped,
} from './UserAggregateOrganization';

/**
 * 
 * @export
 * @interface UserAggregate
 */
export interface UserAggregate {
    /**
     * 
     * @type {Array<UserIdentity>}
     * @memberof UserAggregate
     */
    identities: Array<UserIdentity>;
    /**
     * 
     * @type {number}
     * @memberof UserAggregate
     */
    businesses_count: number;
    /**
     * 
     * @type {number}
     * @memberof UserAggregate
     */
    venues_count: number;
    /**
     * 
     * @type {string}
     * @memberof UserAggregate
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof UserAggregate
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof UserAggregate
     */
    avatar?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserAggregate
     */
    should_change_password: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAggregate
     */
    is_root: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAggregate
     */
    is_active: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserAggregate
     */
    is_demo?: boolean;
    /**
     * 
     * @type {UserAggregateMeta}
     * @memberof UserAggregate
     */
    meta?: UserAggregateMeta;
    /**
     * 
     * @type {string}
     * @memberof UserAggregate
     */
    organization_id: string;
    /**
     * 
     * @type {UserAggregateOrganization}
     * @memberof UserAggregate
     */
    organization: UserAggregateOrganization;
}

/**
 * Check if a given object implements the UserAggregate interface.
 */
export function instanceOfUserAggregate(value: object): value is UserAggregate {
    if (!('identities' in value) || value['identities'] === undefined) return false;
    if (!('businesses_count' in value) || value['businesses_count'] === undefined) return false;
    if (!('venues_count' in value) || value['venues_count'] === undefined) return false;
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('should_change_password' in value) || value['should_change_password'] === undefined) return false;
    if (!('is_root' in value) || value['is_root'] === undefined) return false;
    if (!('is_active' in value) || value['is_active'] === undefined) return false;
    if (!('organization_id' in value) || value['organization_id'] === undefined) return false;
    if (!('organization' in value) || value['organization'] === undefined) return false;
    return true;
}

export function UserAggregateFromJSON(json: any): UserAggregate {
    return UserAggregateFromJSONTyped(json, false);
}

export function UserAggregateFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserAggregate {
    if (json == null) {
        return json;
    }
    return {
        
        'identities': ((json['identities'] as Array<any>).map(UserIdentityFromJSON)),
        'businesses_count': json['businesses_count'],
        'venues_count': json['venues_count'],
        'id': json['id'],
        'name': json['name'],
        'avatar': json['avatar'] == null ? undefined : json['avatar'],
        'should_change_password': json['should_change_password'],
        'is_root': json['is_root'],
        'is_active': json['is_active'],
        'is_demo': json['is_demo'] == null ? undefined : json['is_demo'],
        'meta': json['meta'] == null ? undefined : UserAggregateMetaFromJSON(json['meta']),
        'organization_id': json['organization_id'],
        'organization': UserAggregateOrganizationFromJSON(json['organization']),
    };
}

export function UserAggregateToJSON(json: any): UserAggregate {
    return UserAggregateToJSONTyped(json, false);
}

export function UserAggregateToJSONTyped(value?: UserAggregate | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'identities': ((value['identities'] as Array<any>).map(UserIdentityToJSON)),
        'businesses_count': value['businesses_count'],
        'venues_count': value['venues_count'],
        'id': value['id'],
        'name': value['name'],
        'avatar': value['avatar'],
        'should_change_password': value['should_change_password'],
        'is_root': value['is_root'],
        'is_active': value['is_active'],
        'is_demo': value['is_demo'],
        'meta': UserAggregateMetaToJSON(value['meta']),
        'organization_id': value['organization_id'],
        'organization': UserAggregateOrganizationToJSON(value['organization']),
    };
}

