import React from "react";

export function Wallet05() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.6666 6.6665L3.33323 6.6665M12.6666 6.6665L13.9093 8.73779C14.1879 9.20199 14.3271 9.43409 14.3994 9.68394C14.4635 9.90533 14.4889 10.1361 14.4743 10.3662C14.4579 10.6257 14.3723 10.8825 14.2011 11.3961L14.0626 11.8118C13.8003 12.5986 13.6692 12.992 13.4259 13.2829C13.2112 13.5397 12.9353 13.7385 12.6237 13.8611C12.2709 13.9998 11.8562 13.9998 11.0268 13.9998L4.97299 13.9998C4.14361 13.9998 3.72892 13.9998 3.37607 13.8611C3.06448 13.7385 2.78864 13.5397 2.57385 13.2829C2.33062 12.992 2.19948 12.5986 1.93721 11.8118L1.79865 11.3961C1.62746 10.8825 1.54187 10.6257 1.52546 10.3662C1.51093 10.1361 1.53627 9.90533 1.60035 9.68394C1.67268 9.43409 1.81194 9.20199 2.09046 8.73778L3.33323 6.6665M12.6666 6.6665L12.9447 5.69287C13.0766 5.23128 13.1426 5.00049 13.0908 4.81789C13.0454 4.65794 12.9419 4.52071 12.8006 4.43314C12.6392 4.33317 12.3992 4.33317 11.9191 4.33317L4.08067 4.33317C3.60061 4.33317 3.36058 4.33317 3.19924 4.43314C3.05791 4.52071 2.9544 4.65794 2.90903 4.81789C2.85722 5.00049 2.92317 5.23128 3.05505 5.69287L3.33323 6.6665M7.9999 4.33317H5.64275C5.29545 4.33317 4.96236 4.19269 4.71678 3.94265C4.4712 3.6926 4.33323 3.35346 4.33323 2.99984C4.33323 2.64622 4.4712 2.30708 4.71678 2.05703C4.96236 1.80698 5.29545 1.6665 5.64275 1.6665C7.47609 1.6665 7.9999 4.33317 7.9999 4.33317ZM7.9999 4.33317H10.357C10.7043 4.33317 11.0374 4.19269 11.283 3.94265C11.5286 3.6926 11.6666 3.35346 11.6666 2.99984C11.6666 2.64622 11.5286 2.30708 11.283 2.05703C11.0374 1.80698 10.7043 1.6665 10.357 1.6665C8.52371 1.6665 7.9999 4.33317 7.9999 4.33317Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
