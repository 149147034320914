import React from "react";
import { Outlet } from "react-router";
import { UserAggregateMetaNormaEnterpriseWebModeEnum as AppMode } from "@norma-bi/bi-api";
import { Dialog } from "../components/Dialog";
import { QuickSettings } from "../components/QuickSettings";
import { MenuButtons } from "./MenuButtons";
import { useToggleAppMode } from "../hooks/useToggleAppMode";
import { motion } from "framer-motion";
import "./index.css";

export function ProLayout() {
  const { mode, toggleAppMode } = useToggleAppMode();

  const [showSwitchDialog, setShowSwitchDialog] = React.useState(false);

  if (!mode) {
    return null;
  }

  return (
    <>
      <motion.div
        className="pro-layout-container flex h-screen bg-lightGray"
        initial={{ y: "-10%", opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.75, ease: "easeInOut" }}
      >
        <div className="flex flex-col justify-between w-full h-full">
          <div className={`norma-navbar select-none`}>
            <QuickSettings onToggleMode={() => setShowSwitchDialog(true)} mode={mode} />
            <div className="flex h-full items-center">
              <MenuButtons />
            </div>
          </div>
          <div className="w-full flex-1 min-h-0">
            <Outlet />
          </div>
        </div>
        <Dialog
          show={showSwitchDialog}
          title="Switch Mode"
          message={`Are you sure you want to switch to ${mode === AppMode.pro ? "Lite" : "Pro"} Mode?`}
          onConfirm={toggleAppMode}
          onCancel={() => setShowSwitchDialog(false)}
          confirmText="Confirm"
          cancelText="Cancel"
          className="h-[212px]"
        />
      </motion.div>
    </>
  );
}
