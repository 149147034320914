import React from "react";

export function HorizontalBarChart01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.3333 6.3335V4.06683C11.3333 3.69346 11.3333 3.50678 11.2607 3.36417C11.1968 3.23873 11.0948 3.13674 10.9693 3.07283C10.8267 3.00016 10.64 3.00016 10.2667 3.00016H2M8.66667 9.66683V11.9335C8.66667 12.3069 8.66667 12.4935 8.594 12.6362C8.53009 12.7616 8.4281 12.8636 8.30266 12.9275C8.16005 13.0002 7.97337 13.0002 7.6 13.0002H2M2 1.3335L2 14.6668M2 9.66683L12.9333 9.66683C13.3067 9.66683 13.4934 9.66683 13.636 9.59417C13.7614 9.53025 13.8634 9.42827 13.9273 9.30282C14 9.16022 14 8.97353 14 8.60016V7.40016C14 7.02679 14 6.84011 13.9273 6.6975C13.8634 6.57206 13.7614 6.47007 13.636 6.40616C13.4934 6.3335 13.3067 6.3335 12.9333 6.3335L2 6.3335L2 9.66683Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
