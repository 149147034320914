export type EventType = "open" | "message" | "error" | "close";

export interface MessageEvent {
  type: "message";
  data: string | null;
  lastEventId: string | null;
  url: string;
}

export interface OpenEvent {
  type: "open";
}

export interface CloseEvent {
  type: "close";
}

export interface TimeoutEvent {
  type: "timeout";
}

export interface ErrorEvent {
  type: "error";
  message: string;
  xhrState: number;
  xhrStatus: number;
}

export interface ExceptionEvent {
  type: "exception";
  message: string;
  error: Error;
}

export interface EventSourceOptions {
  method?: string;
  timeout?: number;
  timeoutBeforeConnection?: number;
  withCredentials?: boolean;
  headers?: Record<string, any>;
  body?: any;
  debug?: boolean;
  pollingInterval?: number;
  lineEndingCharacter?: string;
}

type EventMap = {
  message: MessageEvent;
  open: OpenEvent;
  close: CloseEvent;
  error: ErrorEvent | TimeoutEvent | ExceptionEvent;
};

export type EventSourceEvent<E extends EventType> = EventMap[E];
export type EventSourceListener<E extends EventType> = (event: EventSourceEvent<E>) => void;

const XMLReadyStateMap = ["UNSENT", "OPENED", "HEADERS_RECEIVED", "LOADING", "DONE"];

type Status = "error" | "connecting" | "open" | "closed";

const CRLF = "\r\n",
  LF = "\n",
  CR = "\r";

class XHREventSource {
  private lastEventId: string | null;
  private status: Status;
  private eventHandlers: {
    open: EventSourceListener<"open">[];
    message: EventSourceListener<"message">[];
    error: EventSourceListener<"error">[];
    close: EventSourceListener<"close">[];
  };
  private method: string;
  private timeout: number;
  private timeoutBeforeConnection: number;
  private withCredentials: boolean;
  private headers: Record<string, string>;
  private body: any;
  private debug: boolean;
  private interval: number;
  private lineEndingCharacter: string;

  private _xhr: XMLHttpRequest | null;
  private _pollTimer: ReturnType<typeof setTimeout> | null;
  private _lastIndexProcessed: number;

  private url: string | URL;

  constructor(url: string | URL, options: EventSourceOptions = {}) {
    this.lastEventId = null;
    this.status = "connecting";

    this.eventHandlers = {
      open: [],
      message: [],
      error: [],
      close: [],
    };

    this.method = options.method || "GET";
    this.timeout = options.timeout ?? 0;
    this.timeoutBeforeConnection = options.timeoutBeforeConnection ?? 500;
    this.withCredentials = options.withCredentials || false;
    this.headers = options.headers || {};
    this.body = options.body || undefined;
    this.debug = options.debug || false;
    this.interval = options.pollingInterval ?? 5000;
    this.lineEndingCharacter = options.lineEndingCharacter || LF;

    this._xhr = null;
    this._pollTimer = null;
    this._lastIndexProcessed = 0;

    if (!url || (typeof url !== "string" && typeof url.toString !== "function")) {
      throw new SyntaxError("[EventSource] Invalid URL argument.");
    }

    if (typeof url.toString === "function") {
      this.url = url.toString();
    } else {
      this.url = url;
    }

    this._pollAgain(this.timeoutBeforeConnection, true);
  }

  private _pollAgain(time: number, allowZero: boolean) {
    if (time > 0 || allowZero) {
      this._logDebug(`[EventSource] Will open new connection in ${time} ms.`);
      this._pollTimer = setTimeout(() => {
        this.open();
      }, time);
    }
  }

  open() {
    try {
      this.status = "connecting";

      this._lastIndexProcessed = 0;

      this._xhr = new XMLHttpRequest();
      this._xhr.open(this.method, this.url, true);

      if (this.withCredentials) {
        this._xhr.withCredentials = true;
      }

      this._xhr.setRequestHeader("Accept", "text/event-stream");
      this._xhr.setRequestHeader("Cache-Control", "no-cache");
      this._xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest");

      if (this.headers) {
        for (const [key, value] of Object.entries(this.headers)) {
          this._xhr.setRequestHeader(key, value);
        }
      }

      if (this.lastEventId !== null) {
        this._xhr.setRequestHeader("Last-Event-ID", this.lastEventId);
      }

      this._xhr.timeout = this.timeout;

      this._xhr.onreadystatechange = () => {
        if (this.status === "closed") {
          return;
        }

        const xhr = this._xhr!;

        this._logDebug(
          `[EventSource][onreadystatechange] ReadyState: ${XMLReadyStateMap[xhr.readyState] || "Unknown"}(${xhr.readyState}), status: ${xhr.status}`,
        );

        if (!([XMLHttpRequest.DONE, XMLHttpRequest.LOADING] as number[]).includes(xhr.readyState)) {
          return;
        }

        if (xhr.status >= 200 && xhr.status < 400) {
          if (this.status === "connecting") {
            this.status = "open";
            this.dispatch("open", { type: "open" });
            this._logDebug("[EventSource][onreadystatechange][OPEN] Connection opened.");
          }

          this._handleEvent(xhr.responseText || "");

          if (xhr.readyState === XMLHttpRequest.DONE) {
            this._logDebug("[EventSource][onreadystatechange][DONE] Operation done.");
            this._pollAgain(this.interval, false);
          }
        } else if (xhr.status !== 0) {
          this.status = "error";
          this.dispatch("error", {
            type: "error",
            message: xhr.responseText,
            xhrStatus: xhr.status,
            xhrState: xhr.readyState,
          });

          if (xhr.readyState === XMLHttpRequest.DONE) {
            this._logDebug("[EventSource][onreadystatechange][ERROR] Response status error.");
            this._pollAgain(this.interval, false);
          }
        }
      };

      this._xhr.onerror = () => {
        if (this.status === "closed") {
          return;
        }

        this.status = "error";
        this.dispatch("error", {
          type: "error",
          message: this._xhr!.responseText,
          xhrStatus: this._xhr!.status,
          xhrState: this._xhr!.readyState,
        });
      };

      if (this.body) {
        this._xhr.send(this.body);
      } else {
        this._xhr.send();
      }

      if (this.timeout > 0) {
        setTimeout(() => {
          if (this._xhr?.readyState === XMLHttpRequest.LOADING) {
            this.dispatch("error", { type: "timeout" });
            this.close();
          }
        }, this.timeout);
      }
    } catch (e) {
      this.status = "error";
      const err = e as Error;
      this.dispatch("error", {
        type: "exception",
        message: err.message,
        error: err,
      });
    }
  }

  private _logDebug(...msg: any[]) {
    if (this.debug) {
      console.debug(...msg);
    }
  }

  private _handleEvent(response: string) {
    if (this.lineEndingCharacter === null) {
      const detectedNewlineChar = this._detectNewlineChar(response);
      if (detectedNewlineChar !== null) {
        this._logDebug(
          `[EventSource] Automatically detected lineEndingCharacter: ${JSON.stringify(detectedNewlineChar).slice(1, -1)}`,
        );
        this.lineEndingCharacter = detectedNewlineChar;
      } else {
        console.warn(
          "[EventSource] Unable to identify the line ending character. Ensure your server delivers a standard line ending character: \\r\\n, \\n, \\r, or specify your custom character using the 'lineEndingCharacter' option.",
        );
        return;
      }
    }

    const indexOfDoubleNewline = this._getLastDoubleNewlineIndex(response);
    if (indexOfDoubleNewline <= this._lastIndexProcessed) {
      return;
    }

    const parts = response
      .substring(this._lastIndexProcessed, indexOfDoubleNewline)
      .split(this.lineEndingCharacter || "");
    this._lastIndexProcessed = indexOfDoubleNewline;

    let id = null;
    let data = [];
    let retry = 0;
    let line = "";

    for (let i = 0; i < parts.length; i++) {
      line = parts[i].trim();
      if (line.startsWith("retry")) {
        retry = parseInt(line.replace(/retry:?\s*/, ""), 10);
        if (!isNaN(retry)) {
          this.interval = retry;
        }
      } else if (line.startsWith("data")) {
        data.push(line.replace(/data:?\s*/, ""));
      } else if (line.startsWith("id")) {
        id = line.replace(/id:?\s*/, "");
        if (id !== "") {
          this.lastEventId = id;
        } else {
          this.lastEventId = null;
        }
      } else if (line === "") {
        if (data.length > 0) {
          const eventType: EventType = "message";
          const event: EventSourceEvent<"message"> = {
            type: eventType,
            data: data.join("\n"),
            url: String(this.url),
            lastEventId: this.lastEventId,
          };

          this.dispatch(eventType, event);

          data = [];
        }
      }
    }
  }

  private _detectNewlineChar(response: string) {
    const supportedLineEndings = [CRLF, LF, CR];
    for (const char of supportedLineEndings) {
      if (response.includes(char)) {
        return char;
      }
    }
    return null;
  }

  private _getLastDoubleNewlineIndex(response: string) {
    const doubleLineEndingCharacter = this.lineEndingCharacter + this.lineEndingCharacter;
    const lastIndex = response.lastIndexOf(doubleLineEndingCharacter);
    if (lastIndex === -1) {
      return -1;
    }

    return lastIndex + doubleLineEndingCharacter.length;
  }

  addEventListener<T extends EventType>(type: T, listener: EventSourceListener<T>) {
    if (this.eventHandlers[type] === undefined) {
      this.eventHandlers[type] = [];
    }
    // @ts-expect-error
    this.eventHandlers[type].push(listener);
  }

  removeEventListener<T extends EventType>(type: T, listener: EventSourceListener<T>) {
    if (this.eventHandlers[type] !== undefined) {
      // @ts-expect-error
      this.eventHandlers[type] = this.eventHandlers[type].filter((handler) => handler !== listener);
    }
  }

  removeAllEventListeners<T extends EventType>(type?: T) {
    const availableTypes = Object.keys(this.eventHandlers);

    if (type === undefined) {
      for (const eventType of availableTypes) {
        // @ts-expect-error
        this.eventHandlers[eventType] = [];
      }
    } else {
      if (!availableTypes.includes(type)) {
        throw Error(`[EventSource] '${type}' type is not supported event type.`);
      }

      this.eventHandlers[type] = [];
    }
  }

  dispatch<T extends EventType>(type: T, data: EventSourceEvent<T>) {
    const availableTypes = Object.keys(this.eventHandlers);

    if (!availableTypes.includes(type)) {
      return;
    }

    for (const handler of Object.values(this.eventHandlers[type])) {
      handler(data);
    }
  }

  close() {
    if (this.status !== "closed") {
      this.status = "closed";
      this.dispatch("close", { type: "close" });
    }

    if (this._pollTimer) {
      clearTimeout(this._pollTimer);
    }
    if (this._xhr) {
      this._xhr.abort();
    }
  }
}

export default XHREventSource;
