import React from "react";

export function Compare() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11903)">
        <path
          d="M15.0312 0.96875H8.46875V0H7.53125V0.96875H0.96875V15.0312H7.53125V16H8.46875V15.0312H15.0312V0.96875ZM14.0938 14.0938H8.46875V13.1562H7.53125V14.0938H1.90625V1.90625H7.53125V2.84375H8.46875V1.90625H14.0938V14.0938Z"
          fill="currentColor"
        />
        <path d="M7.53125 3.78125H8.46875V5.65625H7.53125V3.78125Z" fill="currentColor" />
        <path d="M7.53125 10.3438H8.46875V12.2188H7.53125V10.3438Z" fill="currentColor" />
        <path d="M7.53125 6.59375H8.46875V9.40625H7.53125V6.59375Z" fill="currentColor" />
        <path
          d="M3.87536 5.2487L3.20869 4.58203L2.47949 5.31123L3.87536 6.70709L6.30244 4.27998L5.57324 3.55078L3.87536 5.2487Z"
          fill="currentColor"
        />
        <path
          d="M5.11923 9.8125L4.39003 10.5417L3.6608 9.8125L2.93164 10.5417L3.66084 11.2709L2.93164 12.0001L3.6608 12.7293L4.39003 12.0001L5.11923 12.7293L5.84843 12.0001L5.11923 11.2709L5.84843 10.5417L5.11923 9.8125Z"
          fill="currentColor"
        />
        <path
          d="M11.0941 11.5729L10.4274 10.9062L9.69824 11.6354L11.0941 13.0313L13.5212 10.6042L12.792 9.875L11.0941 11.5729Z"
          fill="currentColor"
        />
        <path
          d="M13.5212 4.27998L12.792 3.55078L11.0941 5.2487L10.4274 4.58203L9.69824 5.31123L11.0941 6.70709L13.5212 4.27998Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11903">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
