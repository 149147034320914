import React from "react";
import { Business } from "@norma-bi/bi-api";
import { useUpdateBusiness } from "../../../hooks/useUpdateBusiness";
import { customToast } from "../../../utils/customToast";
import { RenameDialog } from "./RenameDialog";
import { ContextMenu, ContextMenuOption } from "../../../components/ContextMenu";

type BusinessOptionsProps = {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  business: Business;
};

export function BusinessContextMenu({ show, setShow, business }: BusinessOptionsProps) {
  const [showRenameDialog, setShowRenameDialog] = React.useState(false);

  const updateBusiness = useUpdateBusiness();

  function handleRename(newDisplayName: string) {
    updateBusiness.mutate(
      { businessId: business.id, changeset: { display_name: newDisplayName } },
      {
        onSuccess: () => {
          customToast("Successfully Changed Name of Business", "success", "bottom");
          setShowRenameDialog(false);
        },
        onError: (error) => {
          console.error("Error updating business name:", error);
          customToast("Failed to rename business", "error", "bottom");
        },
      },
    );
  }

  const options: ContextMenuOption[] = [
    {
      id: "rename",
      label: "Rename",
      handler: () => {
        setShowRenameDialog(true);
        return Promise.resolve();
      },
    },
  ];

  return (
    <>
      <ContextMenu show={show} setShow={setShow} options={options} />
      <RenameDialog
        show={showRenameDialog}
        setShow={setShowRenameDialog}
        onConfirm={handleRename}
        business={business}
      />
    </>
  );
}
