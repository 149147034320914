import React from "react";

export function StickerCircle() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11953)">
        <path
          d="M14.6672 8.07825C14.6672 4.35323 11.6475 1.3335 7.92248 1.3335C4.89914 1.3335 2.3404 3.32273 1.4837 6.06376C1.42883 6.23935 1.40139 6.32714 1.40398 6.43692C1.40608 6.52619 1.43321 6.63647 1.47277 6.71652C1.52141 6.81497 1.59652 6.89008 1.74673 7.04028L8.96074 14.254C9.11094 14.4042 9.18606 14.4793 9.28451 14.5279C9.36456 14.5675 9.47484 14.5946 9.56411 14.5967C9.67389 14.5993 9.76168 14.5718 9.93727 14.5169C12.6782 13.6602 14.6672 11.1015 14.6672 8.07825Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.29803 6.48956C2.45836 6.47816 2.62025 6.47236 2.78349 6.47236C6.50851 6.47236 9.52824 9.49209 9.52824 13.2171C9.52824 13.3804 9.52245 13.5422 9.51104 13.7026C9.49112 13.9827 9.48116 14.1228 9.3995 14.2093C9.33318 14.2795 9.22001 14.3218 9.12388 14.3121C9.00551 14.3003 8.89787 14.1926 8.68258 13.9773L2.02326 7.31802C1.80797 7.10273 1.70032 6.99509 1.68847 6.87673C1.67884 6.78059 1.72107 6.66742 1.79133 6.6011C1.87784 6.51945 2.0179 6.50948 2.29803 6.48956Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11953">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
