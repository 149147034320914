import React from "react";

export function Speedometer01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11932)">
        <path
          d="M7.99992 1.3335V3.00016M7.99992 1.3335C4.31802 1.3335 1.33325 4.31826 1.33325 8.00016M7.99992 1.3335C11.6818 1.3335 14.6666 4.31826 14.6666 8.00016M7.99992 13.0002V14.6668M7.99992 14.6668C11.6818 14.6668 14.6666 11.6821 14.6666 8.00016M7.99992 14.6668C4.31802 14.6668 1.33325 11.6821 1.33325 8.00016M2.99992 8.00016H1.33325M14.6666 8.00016H12.9999M12.7189 12.7191L11.5364 11.5366M3.28101 12.7191L4.46469 11.5354M3.28101 3.3335L4.43864 4.49113M12.7189 3.3335L8.99986 7.00016M9.33325 8.00016C9.33325 8.73654 8.7363 9.3335 7.99992 9.3335C7.26354 9.3335 6.66659 8.73654 6.66659 8.00016C6.66659 7.26378 7.26354 6.66683 7.99992 6.66683C8.7363 6.66683 9.33325 7.26378 9.33325 8.00016Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11932">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
