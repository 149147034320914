import { useMutation } from "@tanstack/react-query";
import { useNavigate } from "react-router";
import { useAPI } from "../api";
import { useAccessToken } from "../Auth/useAccessToken";

export function useResetPassword() {
  const { setAccessToken } = useAccessToken();
  const api = useAPI();
  const navigate = useNavigate();

  return useMutation({
    mutationFn: (args: { token: string; newPassword: string }) => {
      return api.postResetPassword({
        ResetPasswordRequest: {
          newPassword: args.newPassword,
          token: args.token,
        },
      });
    },
    onSuccess: ({ token: { token } }) => {
      setAccessToken(token);
      navigate("/");
    },
  });
}
