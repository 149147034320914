/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FoodicsRole
 */
export interface FoodicsRole {
    /**
     * 
     * @type {string}
     * @memberof FoodicsRole
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FoodicsRole
     */
    name: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FoodicsRole
     */
    authorities?: Array<string>;
}

/**
 * Check if a given object implements the FoodicsRole interface.
 */
export function instanceOfFoodicsRole(value: object): value is FoodicsRole {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    return true;
}

export function FoodicsRoleFromJSON(json: any): FoodicsRole {
    return FoodicsRoleFromJSONTyped(json, false);
}

export function FoodicsRoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodicsRole {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'authorities': json['authorities'] == null ? undefined : json['authorities'],
    };
}

export function FoodicsRoleToJSON(json: any): FoodicsRole {
    return FoodicsRoleToJSONTyped(json, false);
}

export function FoodicsRoleToJSONTyped(value?: FoodicsRole | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'authorities': value['authorities'],
    };
}

