export function DefaultTooltipRenderer({
  tooltip,
}: {
  tooltip: { content: string; x: number; y: number } | null;
}) {
  if (!tooltip) return null;
  return (
    <div
      className="absolute bg-dark p-2 rounded opacity-70 max-w-64"
      style={{ top: tooltip.y, left: tooltip.x, transform: "translate(-50%, -100%)" }}
    >
      <span className="text-white font-medium break-words">{tooltip.content}</span>
      <div className="absolute bottom-[-7px] left-1/2 transform -translate-x-1/2 w-0 h-0 border-l-8 border-l-transparent border-r-8 border-r-transparent border-t-8 border-t-dark"></div>
    </div>
  );
}
