/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface FoodicsBranch
 */
export interface FoodicsBranch {
    /**
     * 
     * @type {string}
     * @memberof FoodicsBranch
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof FoodicsBranch
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof FoodicsBranch
     */
    name_localized?: string;
    /**
     * 
     * @type {string}
     * @memberof FoodicsBranch
     */
    reference: string;
}

/**
 * Check if a given object implements the FoodicsBranch interface.
 */
export function instanceOfFoodicsBranch(value: object): value is FoodicsBranch {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('name' in value) || value['name'] === undefined) return false;
    if (!('reference' in value) || value['reference'] === undefined) return false;
    return true;
}

export function FoodicsBranchFromJSON(json: any): FoodicsBranch {
    return FoodicsBranchFromJSONTyped(json, false);
}

export function FoodicsBranchFromJSONTyped(json: any, ignoreDiscriminator: boolean): FoodicsBranch {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'name_localized': json['name_localized'] == null ? undefined : json['name_localized'],
        'reference': json['reference'],
    };
}

export function FoodicsBranchToJSON(json: any): FoodicsBranch {
    return FoodicsBranchToJSONTyped(json, false);
}

export function FoodicsBranchToJSONTyped(value?: FoodicsBranch | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'id': value['id'],
        'name': value['name'],
        'name_localized': value['name_localized'],
        'reference': value['reference'],
    };
}

