/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { Metric } from './Metric';
import {
    MetricFromJSON,
    MetricFromJSONTyped,
    MetricToJSON,
    MetricToJSONTyped,
} from './Metric';

/**
 * 
 * @export
 * @interface GetBranchOrderTypesNetSales200Response
 */
export interface GetBranchOrderTypesNetSales200Response {
    /**
     * 
     * @type {Array<Metric>}
     * @memberof GetBranchOrderTypesNetSales200Response
     */
    data: Array<Metric>;
}

/**
 * Check if a given object implements the GetBranchOrderTypesNetSales200Response interface.
 */
export function instanceOfGetBranchOrderTypesNetSales200Response(value: object): value is GetBranchOrderTypesNetSales200Response {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function GetBranchOrderTypesNetSales200ResponseFromJSON(json: any): GetBranchOrderTypesNetSales200Response {
    return GetBranchOrderTypesNetSales200ResponseFromJSONTyped(json, false);
}

export function GetBranchOrderTypesNetSales200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetBranchOrderTypesNetSales200Response {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(MetricFromJSON)),
    };
}

export function GetBranchOrderTypesNetSales200ResponseToJSON(json: any): GetBranchOrderTypesNetSales200Response {
    return GetBranchOrderTypesNetSales200ResponseToJSONTyped(json, false);
}

export function GetBranchOrderTypesNetSales200ResponseToJSONTyped(value?: GetBranchOrderTypesNetSales200Response | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(MetricToJSON)),
    };
}

