import React from "react";
import { useNavigate } from "react-router";
import { useMutation } from "@tanstack/react-query";
import { ForgotPasswordForm } from "./ForgotPasswordForm";
import { Modal } from "../../components/Modal";
import { useAPI } from "../../api";
import { CheckYourEmail } from "./CheckYourEmail";

export function ForgotPassword() {
  const navigate = useNavigate();

  const [showCheckYourEmail, setShowCheckYourEmail] = React.useState(false);

  const api = useAPI();

  const resetPassword = useMutation({
    mutationFn: (args: { accountNumber: string; email: string }) => {
      return api.postRequestResetPassword({
        RequestResetPasswordRequest: {
          business_ref: parseInt(args.accountNumber),
          email: args.email,
        },
      });
    },
  });

  async function onConfirm(accountNumber: string, email: string) {
    await resetPassword.mutateAsync({ accountNumber, email });
    setShowCheckYourEmail(true);
  }

  async function onFinish() {
    navigate("..");
  }

  function onShowChanged(show: boolean) {
    if (!show) navigate("..");
  }

  function onCancel() {
    navigate("..");
  }

  return (
    <Modal show={true} onShowChanged={onShowChanged} dialogClassname={"w-[53%] max-w-[660px]"}>
      {showCheckYourEmail ? (
        <CheckYourEmail onFinish={onFinish} />
      ) : (
        <ForgotPasswordForm
          onConfirm={onConfirm}
          isPending={resetPassword.isPending}
          onCancel={onCancel}
        />
      )}
    </Modal>
  );
}
