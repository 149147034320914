import React from "react";

type ModalProps = React.PropsWithChildren<{
  show: boolean;
  onShowChanged: (show: boolean) => void;
  dialogClassname?: string;
}>;

export function Modal({ show, onShowChanged, children, dialogClassname }: ModalProps) {
  const dialogElement = React.useRef<HTMLDialogElement>(null);

  React.useEffect(() => {
    const dialog = dialogElement.current;
    if (!dialog) return;

    const clickListener = (evt: MouseEvent) => {
      const el = evt.target as Element;
      if (el.contains(dialog)) onShowChanged(false);
    };

    document.addEventListener("click", clickListener);

    const closeListener = () => {
      onShowChanged(false);
    };

    dialog.addEventListener("close", closeListener);
    return () => {
      dialog.removeEventListener("close", closeListener);
      document.removeEventListener("click", clickListener);
    };
  }, [onShowChanged]);

  React.useEffect(() => {
    const dialog = dialogElement.current;
    if (!dialog) return;

    if (show) dialog.showModal();
    else dialog.close();
  }, [show]);

  return (
    <dialog ref={dialogElement} className={dialogClassname}>
      {children}
    </dialog>
  );
}
