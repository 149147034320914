import React from "react";
import toast from "react-hot-toast";
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { TooltipProvider, useTooltip } from "../components/Tooltip";
import {
  LiveboardCategory,
  Liveboard,
  useGetOrderedLiveboardCategories,
  CustomLiveboardCategoryId,
  OrderedLiveboardsCategory,
} from "./useOrderedLiveboards";
import { LoadingScreen } from "../LoadingScreen";
import { useFavoriteLiveboard } from "./useFavoriteLiveboard";
import { SvgIcon } from "../svg";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import clsx from "clsx";
import { customToast } from "../utils/customToast";

export type LiveboardListProps = {
  selectedLiveboardId: string | null;
  searchTerm: string;
  favoritesOnly: boolean;
  onLiveboardSelected: (liveboardId: string) => void;
  isSidebarExpanded: boolean;
};

export function LiveboardList({
  searchTerm,
  favoritesOnly,
  selectedLiveboardId,
  onLiveboardSelected,
  isSidebarExpanded,
}: LiveboardListProps) {
  const { liveboardCategories, query, mutation } = useGetOrderedLiveboardCategories({
    searchTerm,
    favoritesOnly,
  });

  function updateLiveboardCategoriesOrder(
    newLiveboardCategoriesOrder: OrderedLiveboardsCategory[],
  ) {
    mutation.mutate(
      { newLiveboardCategoriesOrder },
      {
        onSuccess: () => {
          toast.success("Liveboard order updated successfully");
        },
        onError: (error) => {
          console.error("Failed to update liveboard order:", error);
          toast.error("Failed to update liveboard order");
        },
      },
    );
  }

  React.useEffect(() => {
    // select the first liveboard only when none is selected
    if (selectedLiveboardId) {
      return;
    }
    if (liveboardCategories.length > 0) {
      const category = liveboardCategories[0];
      if (category.liveboards.length > 0) {
        onLiveboardSelected(category.liveboards[0].id);
      }
    }
  }, [selectedLiveboardId, liveboardCategories, onLiveboardSelected]);

  if (query.isLoading) {
    return <LoadingScreen />;
  }

  if (!liveboardCategories.length) {
    return <div>No liveboards found</div>;
  }

  return (
    <SortableLiveboardCategoriesList
      categories={liveboardCategories}
      onUpdateCategoriesOrder={updateLiveboardCategoriesOrder}
      selectedLiveboardId={selectedLiveboardId}
      onLiveboardSelected={onLiveboardSelected}
      isSidebarExpanded={isSidebarExpanded}
    />
  );
}

const classNameWhileIsDragging =
  "after:absolute after:block after:top-0 after:left-0 after:h-full after:w-full after:bg-blueishGray after:rounded-lg";

type SortableLiveboardCategoriesListProps = {
  categories: LiveboardCategory[];
  onUpdateCategoriesOrder: (newOrder: LiveboardCategory[]) => void;
  selectedLiveboardId: string | null;
  onLiveboardSelected: (liveboardId: string) => void;
  isSidebarExpanded: boolean;
};

function SortableLiveboardCategoriesList(props: SortableLiveboardCategoriesListProps) {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [categories, setCategories] = React.useState(props.categories);
  React.useEffect(() => {
    setCategories(props.categories);
  }, [props.categories]);
  function updateCategoriesOrder(newOrder: LiveboardCategory[]) {
    setCategories(newOrder);
    props.onUpdateCategoriesOrder(newOrder);
  }

  const [activeCategory, setActiveCategory] = React.useState<LiveboardCategory | null>(null);

  function onDragStart(evt: DragStartEvent) {
    setActiveCategory(categories.find((lc) => lc.id === evt.active.id) ?? null);
  }

  function onDragEnd(evt: DragEndEvent) {
    if (evt.active.id === evt.over?.id) {
      return;
    }
    if (!props.isSidebarExpanded) {
      customToast(
        "Category or Liveboard rearrangement is disabled when the sidebar is collapsed",
        "error",
      );
      return;
    }

    if (evt.active.id !== evt.over?.id) {
      const oldIndex = categories.findIndex((l) => l.id === evt.active.id);
      const newIndex = categories.findIndex((l) => l.id === evt.over?.id);
      const newOrder = arrayMove(categories, oldIndex, newIndex);
      updateCategoriesOrder(newOrder);
    }

    setActiveCategory(null);
  }

  const liveboardCategoriesIds = React.useMemo(() => {
    return categories.map((lc) => lc.id);
  }, [categories]);

  function onLiveboardsOrderChanged(categoryIndex: number, newLiveboardOrder: Liveboard[]) {
    updateCategoriesOrder([
      ...categories.slice(0, categoryIndex),
      { ...categories[categoryIndex], liveboards: newLiveboardOrder },
      ...categories.slice(categoryIndex + 1),
    ]);
  }

  // NOTE(yannis): this expanded states shenanigan must live here and not inside each category
  // because of the DragOverlay: if the state was unknown to the categories list, then
  // the DragOverlay could be expanded whereas the draggable category could be collapsed.
  type ExpandedStates = Record<string, boolean>;
  const [categoriesAreExpanded, setCategoriesAreExpanded] = React.useState<ExpandedStates>({});
  React.useEffect(() => {
    setCategoriesAreExpanded((curr) => {
      return categories.reduce((final, cat) => {
        final[cat.id] = final[cat.id] ?? true;
        return final;
      }, curr);
    });
  }, [categories]);
  function onCategoryExpandedChanged(categoryId: string, expanded: boolean) {
    setCategoriesAreExpanded((curr) => {
      const final = { ...curr };
      final[categoryId] = expanded;
      return final;
    });
  }
  function isCategoryExpanded(categoryId: string) {
    return categoriesAreExpanded[categoryId] ?? true;
  }

  return (
    <TooltipProvider>
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={onDragStart}
        onDragEnd={onDragEnd}
      >
        <SortableContext items={liveboardCategoriesIds} strategy={verticalListSortingStrategy}>
          <div className="select-none flex flex-col gap-4">
            {categories.map((category, index) => (
              <SortableLiveboardCategory
                key={category.id}
                isExpanded={isCategoryExpanded(category.id)}
                onExpandedChanged={(expanded) => onCategoryExpandedChanged(category.id, expanded)}
                category={category}
                onLiveboardsOrderChanged={(order) => {
                  onLiveboardsOrderChanged(index, order);
                }}
                selectedLiveboardId={props.selectedLiveboardId}
                onSelectLiveboard={props.onLiveboardSelected}
                isSidebarExpanded={props.isSidebarExpanded}
              />
            ))}
          </div>

          <DragOverlay>
            {activeCategory && (
              <LiveboardCategoryItem
                isExpanded={isCategoryExpanded(activeCategory.id)}
                category={activeCategory}
                selectedLiveboardId={props.selectedLiveboardId}
                isDragOverlay
                isSidebarExpanded={props.isSidebarExpanded}
              />
            )}
          </DragOverlay>
        </SortableContext>
      </DndContext>
    </TooltipProvider>
  );
}

type LiveboardCategoryItemProps = {
  category: LiveboardCategory;
  selectedLiveboardId: string | null;
  isExpanded: boolean;
  onExpandedChanged?: (expanded: boolean) => void;
  isDragging?: boolean;
  isDragOverlay?: boolean;
  // can be omitted (for use in DragOverlay)
  onLiveboardsOrderChanged?: (order: Liveboard[]) => void;
  onSelectLiveboard?: (liveboardId: string) => void;
  isSidebarExpanded: boolean;
};

function LiveboardCategoryItem(props: LiveboardCategoryItemProps) {
  function onClickHeader(evt: React.MouseEvent) {
    evt.preventDefault();
    evt.stopPropagation();
    props.onExpandedChanged?.(!props.isExpanded);
  }

  const categoryIsSelected = React.useMemo(() => {
    return Boolean(props.category.liveboards.find((l) => l.id === props.selectedLiveboardId));
  }, [props.category.liveboards, props.selectedLiveboardId]);

  const [animatedContainer] = useAutoAnimate();
  const [categoryHeaderAnimatedContainer] = useAutoAnimate();

  return (
    <div
      className={clsx(
        "select-none flex flex-col gap-2 bg-lightGray rounded-lg relative",
        props.isDragging && classNameWhileIsDragging,
        props.isDragOverlay && "-rotate-2",
      )}
      ref={animatedContainer}
    >
      <button
        onClick={onClickHeader}
        className={clsx(
          "flex items-center justify-between py-3 rounded-lg hover:bg-white",
          props.isSidebarExpanded ? "pl-4 pr-3 " : "px-2",
        )}
      >
        <div
          className={clsx(
            "flex-1 flex items-center justify-start gap-4 overflow-hidden",
            categoryIsSelected ? "text-dark" : "text-feintGray",
          )}
          ref={categoryHeaderAnimatedContainer}
        >
          <div>{props.category.icon}</div>

          {props.isSidebarExpanded && (
            <div className="font-medium truncate">
              {props.category.id === CustomLiveboardCategoryId ? "Custom" : props.category.name}
            </div>
          )}
        </div>

        <div className={clsx(!props.isExpanded && "rotate-180")}>
          <SvgIcon icon={"CaretDown"} />
        </div>
      </button>

      {props.isExpanded && (
        // wrap in a div to ensure that auto-animate works as expected (DndContext renders multiple elements)
        <div>
          <SortableLiveboardsList
            key={"liveboards-list"}
            selectedLiveboardId={props.selectedLiveboardId}
            liveboards={props.category.liveboards}
            onLiveboardsOrderChanged={props.onLiveboardsOrderChanged}
            onSelectLiveboard={props.onSelectLiveboard}
            isSidebarExpanded={props.isSidebarExpanded}
          />
        </div>
      )}
    </div>
  );
}

type SortableLiveboardsListProps = {
  selectedLiveboardId: string | null;
  liveboards: Liveboard[];
  onLiveboardsOrderChanged?: (newOrder: Liveboard[]) => void;
  onSelectLiveboard?: (liveboardId: string) => void;
  isSidebarExpanded: boolean;
};

function SortableLiveboardsList(props: SortableLiveboardsListProps) {
  const sensors = useSensors(
    useSensor(PointerSensor, { activationConstraint: { distance: 8 } }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const [liveboards, setLiveboards] = React.useState(props.liveboards);
  React.useEffect(() => {
    setLiveboards(props.liveboards);
  }, [props.liveboards]);

  const [activeLiveboard, setActiveLiveboard] = React.useState<Liveboard | null>(null);

  function onDragStart(evt: DragStartEvent) {
    const liveboard = props.liveboards.find((l) => l.id === evt.active.id);
    if (!liveboard) {
      // unexpected
      return;
    }
    setActiveLiveboard(liveboard);
  }

  function onDragEnd(evt: DragEndEvent) {
    if (evt.active.id === evt.over?.id) {
      return;
    }
    if (!props.isSidebarExpanded) {
      customToast(
        "Category or Liveboard rearrangement is disabled when the sidebar is collapsed",
        "error",
      );
      return;
    }
    if (props.onLiveboardsOrderChanged && evt.active.id !== evt.over?.id) {
      const oldIndex = liveboards.findIndex((l) => l.id === evt.active.id);
      const newIndex = liveboards.findIndex((l) => l.id === evt.over?.id);
      const newOrder = arrayMove(liveboards, oldIndex, newIndex);
      setLiveboards(newOrder);
      props.onLiveboardsOrderChanged(newOrder);
    }
    setActiveLiveboard(null);
  }

  const liveboardsIds = React.useMemo(() => {
    return liveboards.map((l) => l.id);
  }, [liveboards]);

  return (
    <DndContext
      sensors={sensors}
      collisionDetection={closestCenter}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
    >
      <div className={clsx("flex flex-col gap-2", props.isSidebarExpanded && "pl-3")}>
        <SortableContext items={liveboardsIds}>
          {liveboards.map((l) => {
            return (
              <SortableLiveboardItem
                key={l.id}
                liveboard={l}
                isSelected={props.selectedLiveboardId === l.id}
                onSelectLiveboard={() => props.onSelectLiveboard?.(l.id)}
                isSidebarExpanded={props.isSidebarExpanded}
              />
            );
          })}
        </SortableContext>

        <DragOverlay>
          {activeLiveboard && (
            <LiveboardItem
              liveboard={activeLiveboard}
              isSelected={props.selectedLiveboardId === activeLiveboard.id}
              isDragOverlay
              isSidebarExpanded={props.isSidebarExpanded}
            />
          )}
        </DragOverlay>
      </div>
    </DndContext>
  );
}

type SortableLiveboardCategoryProps = LiveboardCategoryItemProps;

function SortableLiveboardCategory(props: SortableLiveboardCategoryProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.category.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <LiveboardCategoryItem {...props} isDragging={isDragging} />
    </div>
  );
}

type LiveboardItemProps = {
  liveboard: Liveboard;
  isSelected: boolean;
  isDragging?: boolean;
  isDragOverlay?: boolean;
  // can be omitted (when used in DragOverlay)
  onSelectLiveboard?: () => void;
  isSidebarExpanded: boolean;
};

function LiveboardItem(props: LiveboardItemProps) {
  const toggleFavoriteMutation = useFavoriteLiveboard();

  const tooltipProps = useTooltip<HTMLDivElement>(props.liveboard.name, {
    enabled: props.isSidebarExpanded && !props.isDragOverlay,
  });

  async function onFavoriteToggle(e: React.MouseEvent) {
    e.stopPropagation();
    try {
      await toggleFavoriteMutation.mutateAsync({
        liveboardId: props.liveboard.id,
        isFavorite: props.liveboard.is_favorite,
      });
      if (props.liveboard.is_favorite) {
        toast.success(`Removed ${props.liveboard.name} from favorites`);
      } else {
        toast.success(`Added ${props.liveboard.name} to favorites`);
      }
    } catch (error) {
      console.error("Failed to toggle favorite:", error);
      toast.error("Failed to update favorite status");
    }
  }

  const [animatedContainer] = useAutoAnimate();

  /*
    NOTE(yannis): For some reason, the height of the container of the star/favorite icon below does
      not take up the whole vertical space which is available. From experimentation, I found out
      that if the div that we use for the sortable container of this component has display: flex,
      then the icon container is ok, but the sortable list is messed up.
      The hack here is to for each child element to have the container's padding (py-2)
      and the star container should have a fixed height (h-9). h-full just doesn't work
  */
  return (
    <div
      className={clsx(
        "group relative",
        "cursor-grab",
        "flex items-center justify-between",
        "pr-4 rounded-lg",
        // allows the liveboard icon to appear during the sidebar's expansion animations
        "overflow-visible",
        "text-feintGray hover:bg-white hover:text-dark",
        props.isSelected && "!bg-white !text-dark",
        props.isDragging && classNameWhileIsDragging,
        props.isDragOverlay && "-rotate-2",
        // the sort handle is removed when the sidebar is collapsed, so we need to give a bit more left padding
        props.isSidebarExpanded ? "pl-1" : "pl-2",
      )}
      onClick={props.onSelectLiveboard}
      {...tooltipProps}
    >
      <div
        className={clsx(
          "py-2 flex gap-2 items-center overflow-hidden",
          "min-w-[20px]", // this is necessary to prevent the icon jumping and doing weird stuff during the expansion animation
        )}
        ref={animatedContainer}
      >
        {props.isSidebarExpanded && (
          <div
            className={clsx(
              // opacity should be important because for a brief second the browser decides to ignore it
              // when this component is being mounted. Or maybe it's a race between react and tailwind.
              "!opacity-0 group-hover:!opacity-100",
              " group-hover:text-feintGray group-active:text-dark",
              props.isDragOverlay && "!text-dark",
            )}
          >
            <SvgIcon icon="SortHandle" />
          </div>
        )}

        <div>{props.liveboard.icon}</div>

        {props.isSidebarExpanded && (
          <div className={"truncate font-medium"}>{props.liveboard.name}</div>
        )}
      </div>

      <button
        className={clsx(
          "h-9 flex-1 max-w-4 py-2 text-sematicYellow",
          "flex items-center justify-end",
          props.liveboard.is_favorite
            ? "hover:text-opacity-40"
            : "text-opacity-0 group-hover:text-opacity-40 hover:!text-opacity-100",
        )}
        onClick={onFavoriteToggle}
      >
        <SvgIcon icon={"Star"} />
      </button>
    </div>
  );
}

type SortableLiveboardItemProps = Omit<LiveboardItemProps, "isDragging">;

export function SortableLiveboardItem(props: SortableLiveboardItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: props.liveboard.id,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <LiveboardItem {...props} isDragging={isDragging} />
    </div>
  );
}
