import React from "react";
import { PostHogProvider, usePostHog } from "posthog-js/react";
import { PostHogConfig } from "posthog-js";
import { useAppMode } from "./hooks/useAppMode";
import { useLocation } from "react-router";
import { useAuth } from "./Auth";

export function WithPostHog({ children }: React.PropsWithChildren) {
  const disable =
    import.meta.env.VITE_DISABLE_POSTHOG_ON_HOSTS?.includes(window.location.host) ?? false;
  if (disable || !import.meta.env.VITE_POSTHOG_KEY) {
    return children;
  }
  const options: Partial<PostHogConfig> = {
    api_host: "https://eu.i.posthog.com",
    person_profiles: "identified_only",
    capture_pageview: false,
    capture_pageleave: false,
    debug: import.meta.env.DEV,
  };
  return (
    <PostHogProvider apiKey={import.meta.env.VITE_POSTHOG_KEY} options={options}>
      {children}
    </PostHogProvider>
  );
}

export function PostHogInstrumentation(props: React.PropsWithChildren) {
  const posthog = usePostHog();
  const auth = useAuth();
  const appMode = useAppMode();
  const location = useLocation();

  const hasRegisteredMode = React.useRef(false);
  const hasIdentified = React.useRef(false);

  React.useEffect(() => {
    if (!appMode.isSuccess) {
      return;
    }
    posthog?.register({ app_mode: appMode.data });
    hasRegisteredMode.current = true;
  }, [posthog, appMode.isSuccess, appMode.data]);

  React.useEffect(() => {
    if (!auth.isHydrated) {
      return;
    }
    if (!auth.isAuthenticated) {
      // send a last screenleave before unauthenticating
      posthog.capture("screenleave", {
        url: window.location.pathname,
      });
      posthog?.reset();
      hasRegisteredMode.current = false;
      hasIdentified.current = false;
      return;
    }
    const user = auth.user;
    posthog?.identify(
      user.id,
      {
        emails: user.identities.map((i) => i.email),
        is_root: user.is_root,
        name: user.name,
      },
      {
        organization: {
          id: user.organization_id,
          name: user.organization.name,
        },
      },
    );

    posthog?.group("organization", user.organization.id, { name: user.organization.name });
    hasIdentified.current = true;
  }, [posthog, auth.isHydrated, auth.isAuthenticated, auth.user]);

  React.useEffect(() => {
    if (!hasRegisteredMode.current || !hasIdentified.current) {
      return;
    }
    const pathname = location.pathname;
    posthog.capture("screenview", {
      url: pathname,
    });
    return () => {
      posthog.capture("screenleave", {
        url: pathname,
      });
    };
  }, [posthog, location.pathname, auth.isAuthenticated]);

  return props.children;
}
