import { SvgIcon } from "../../svg";

export function Maintenance() {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-backgroundGray">
      <div className="flex flex-col items-center space-y-6 gap-y-4">
        <SvgIcon icon="MaintenanceTrip" />
        <div className="font-medium text-[32px] text-center">Maintenance in Progress</div>
      </div>
      <div className="text-feintGray font-medium text-center">We will be back soon!</div>
    </div>
  );
}
