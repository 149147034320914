import React, { MouseEventHandler } from "react";
import { TooltipContext } from "./context";

// return null to not show the tooltip
export type TooltipContent = string | (() => string | null);

export function useTooltip<T extends HTMLElement>(
  content: TooltipContent,
  options?: Partial<{ enabled: boolean; delay: number }>,
): React.HTMLProps<T> {
  const delay = options?.delay ?? 2000;
  const enabled = options?.enabled ?? true;

  const { setTooltip } = React.useContext(TooltipContext);
  const tooltipTimeoutRef = React.useRef<number | null>(null);
  const showTooltip = React.useCallback(
    (content: string, x: number, y: number) => {
      if (!enabled) {
        return;
      }
      tooltipTimeoutRef.current = window.setTimeout(() => {
        setTooltip({ content, x, y });
      }, delay);
    },
    [delay, setTooltip, enabled],
  );

  const hideTooltip = React.useCallback(() => {
    if (tooltipTimeoutRef.current !== null) {
      clearTimeout(tooltipTimeoutRef.current);
      tooltipTimeoutRef.current = null;
    }
    setTooltip(null);
  }, [setTooltip]);

  const onMouseEnter: MouseEventHandler = React.useCallback(
    (evt) => {
      const rect = evt.currentTarget.getBoundingClientRect();
      const c = typeof content === "function" ? content() : content;
      if (c) {
        showTooltip(c, rect.left + rect.width / 2, rect.top);
      }
    },
    [content, showTooltip],
  );

  const onMouseLeave: MouseEventHandler = React.useCallback(() => {
    hideTooltip();
  }, [hideTooltip]);

  React.useEffect(() => {
    if (!enabled) {
      hideTooltip();
    }
  }, [enabled, hideTooltip]);

  if (!enabled) {
    return {};
  }

  return {
    onMouseEnter,
    onMouseLeave,
  };
}
