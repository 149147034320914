import React from "react";
import { LoadingScreen } from "../LoadingScreen";
import { Maintenance } from "../pages/Maintenance";
import { useBIServerStatus } from "../hooks/useBIServerStatus";

export function MaintenanceGuard({ children }: React.PropsWithChildren) {
  if (import.meta.env.VITE_SKIP_MAINTENANCE_CHECK) {
    return children;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const status = useBIServerStatus({ fetchEvery: 60000 });

  if (status.isLoading || !status.isSuccess) {
    return <LoadingScreen />;
  }

  if (!status.data.available) {
    return <Maintenance />;
  }

  return children;
}
