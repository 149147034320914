/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { BudgetResponseVenueBudgetsInner } from './BudgetResponseVenueBudgetsInner';
import {
    BudgetResponseVenueBudgetsInnerFromJSON,
    BudgetResponseVenueBudgetsInnerFromJSONTyped,
    BudgetResponseVenueBudgetsInnerToJSON,
    BudgetResponseVenueBudgetsInnerToJSONTyped,
} from './BudgetResponseVenueBudgetsInner';

/**
 * 
 * @export
 * @interface BudgetResponse
 */
export interface BudgetResponse {
    /**
     * 
     * @type {string}
     * @memberof BudgetResponse
     */
    type: BudgetResponseTypeEnum;
    /**
     * 
     * @type {Array<BudgetResponseVenueBudgetsInner>}
     * @memberof BudgetResponse
     */
    venue_budgets: Array<BudgetResponseVenueBudgetsInner>;
}


/**
 * @export
 */
export const BudgetResponseTypeEnum = {
    monthly: 'monthly',
    weekly: 'weekly'
} as const;
export type BudgetResponseTypeEnum = typeof BudgetResponseTypeEnum[keyof typeof BudgetResponseTypeEnum];


/**
 * Check if a given object implements the BudgetResponse interface.
 */
export function instanceOfBudgetResponse(value: object): value is BudgetResponse {
    if (!('type' in value) || value['type'] === undefined) return false;
    if (!('venue_budgets' in value) || value['venue_budgets'] === undefined) return false;
    return true;
}

export function BudgetResponseFromJSON(json: any): BudgetResponse {
    return BudgetResponseFromJSONTyped(json, false);
}

export function BudgetResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): BudgetResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'type': json['type'],
        'venue_budgets': ((json['venue_budgets'] as Array<any>).map(BudgetResponseVenueBudgetsInnerFromJSON)),
    };
}

export function BudgetResponseToJSON(json: any): BudgetResponse {
    return BudgetResponseToJSONTyped(json, false);
}

export function BudgetResponseToJSONTyped(value?: BudgetResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'type': value['type'],
        'venue_budgets': ((value['venue_budgets'] as Array<any>).map(BudgetResponseVenueBudgetsInnerToJSON)),
    };
}

