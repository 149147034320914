import React from "react";

export function Scale03() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.8 2H5.2C4.0799 2 3.51984 2 3.09202 2.21799C2.71569 2.40973 2.40973 2.71569 2.21799 3.09202C2 3.51984 2 4.0799 2 5.2V10.8C2 11.9201 2 12.4802 2.21799 12.908C2.40973 13.2843 2.71569 13.5903 3.09202 13.782C3.51984 14 4.07989 14 5.2 14H10.8C11.9201 14 12.4802 14 12.908 13.782C13.2843 13.5903 13.5903 13.2843 13.782 12.908C14 12.4802 14 11.9201 14 10.8V5.2M10.8 2C11.9201 2 12.4802 2 12.908 2.21799C13.2843 2.40973 13.5903 2.71569 13.782 3.09202C14 3.51984 14 4.0799 14 5.2M10.8 2H10.1333C9.3866 2 9.01323 2 8.72801 2.14532C8.47713 2.27316 8.27316 2.47713 8.14532 2.72801C8 3.01323 8 3.3866 8 4.13333V5.86667C8 6.6134 8 6.98677 8.14532 7.27199C8.27316 7.52287 8.47713 7.72684 8.72801 7.85468C9.01323 8 9.3866 8 10.1333 8H11.8667C12.6134 8 12.9868 8 13.272 7.85468C13.5229 7.72684 13.7268 7.52287 13.8547 7.27199C14 6.98677 14 6.6134 14 5.86667V5.2M8.33333 7.66667L4.66667 11.3333M4.66667 11.3333H8M4.66667 11.3333L4.66667 8"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
