import React from "react";

export type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
> & {
  icon?: React.ReactNode;
  containerClassName?: string;
};

export function Input({ icon, containerClassName, ...inputProps }: InputProps) {
  return (
    <div className={`thoughtspot-text-input ${containerClassName ?? ""}`}>
      <div className="icon">{icon}</div>
      <input {...inputProps} />
    </div>
  );
}
