import React from "react";

export function LayersTwo01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.3335 9.66687L7.76165 12.8809C7.8491 12.9247 7.89283 12.9465 7.9387 12.9551C7.97932 12.9628 8.02101 12.9628 8.06163 12.9551C8.1075 12.9465 8.15122 12.9247 8.23868 12.8809L14.6668 9.66687M1.3335 6.33354L7.76165 3.11946C7.8491 3.07574 7.89283 3.05387 7.9387 3.04527C7.97932 3.03765 8.02101 3.03765 8.06163 3.04527C8.1075 3.05387 8.15122 3.07574 8.23868 3.11946L14.6668 6.33354L8.23868 9.54762C8.15122 9.59134 8.1075 9.61321 8.06163 9.62181C8.02101 9.62943 7.97932 9.62943 7.9387 9.62181C7.89283 9.61321 7.8491 9.59134 7.76165 9.54762L1.3335 6.33354Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
