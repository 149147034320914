export function CompanyLogoLogin() {
  return (
    <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="80" height="80" rx="16" fill="white" />
      <path
        d="M31.414 50.8307C31.1158 50.5413 30.7646 50.3118 30.3798 50.1549C29.9669 49.9894 29.5257 49.9061 29.081 49.91H27.2779C26.8318 49.9064 26.3894 49.9897 25.9751 50.1549C25.5855 50.3094 25.2315 50.5419 24.9349 50.838C24.6384 51.1341 24.4054 51.4875 24.2502 51.8769C24.0816 52.2923 23.9961 52.7366 23.9988 53.185V54.5869C23.9936 55.0364 24.0791 55.482 24.2502 55.8977C24.4099 56.286 24.6431 56.6405 24.9369 56.9405C25.2334 57.2341 25.5867 57.4641 25.9751 57.6164C26.3887 57.7772 26.8288 57.8589 27.2726 57.8579H29.0757C29.52 57.8598 29.9606 57.7781 30.3745 57.6164C30.762 57.4615 31.1153 57.2319 31.414 56.9405C31.7075 56.6403 31.9407 56.2858 32.1007 55.8977C32.2678 55.4809 32.3512 55.0358 32.3465 54.5869V53.185C32.3508 52.7387 32.2674 52.2961 32.1007 51.8821C31.9451 51.4904 31.7114 51.1346 31.414 50.836"
        fill="#440099"
      />
      <path
        d="M32.1155 32.7147C32.1154 32.4159 32.1741 32.1196 32.2885 31.8435C32.4028 31.5674 32.5707 31.3168 32.7821 31.1056C32.9936 30.8944 33.2444 30.7273 33.5206 30.6132C33.7969 30.4992 34.093 30.4404 34.3918 30.441H56.26V22.4H34.5809C31.7961 22.4011 29.1259 23.5078 27.1574 25.4776C25.1889 27.4475 24.0835 30.1192 24.0842 32.9042V36.391C24.0835 37.7702 24.3543 39.1361 24.8815 40.4105C25.4086 41.685 26.1817 42.8432 27.1564 43.8188C28.1312 44.7945 29.2887 45.5689 30.5626 46.0972C31.8365 46.6255 33.2018 46.8973 34.5809 46.8978H46.0078V38.8595H34.3918C34.0928 38.86 33.7964 38.8014 33.52 38.6872C33.2436 38.573 32.9926 38.4057 32.7811 38.1942C32.5697 37.9827 32.402 37.7314 32.2878 37.455C32.1736 37.1786 32.115 36.8822 32.1155 36.5831V32.7147Z"
        fill="#440099"
      />
    </svg>
  );
}
