import React from "react";

export function NavigationPointer01() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(currentColor3112_11983)">
        <path
          d="M2.27547 7.16299C1.87858 7.00864 1.68013 6.93146 1.62219 6.82026C1.57196 6.72386 1.5719 6.60903 1.62201 6.51257C1.67981 6.4013 1.87817 6.32389 2.27489 6.16908L13.5334 1.77552C13.8915 1.63576 14.0706 1.56588 14.185 1.60411C14.2844 1.6373 14.3623 1.71528 14.3955 1.81465C14.4337 1.92907 14.3639 2.10813 14.2241 2.46625L9.83056 13.7247C9.67574 14.1215 9.59833 14.3198 9.48706 14.3776C9.39061 14.4277 9.27577 14.4277 9.17937 14.3774C9.06817 14.3195 8.991 14.1211 8.83665 13.7242L7.08457 9.21883C7.05324 9.13826 7.03758 9.09798 7.01338 9.06406C6.99193 9.03399 6.96564 9.0077 6.93558 8.98625C6.90166 8.96206 6.86137 8.94639 6.78081 8.91506L2.27547 7.16299Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3112_11983">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
