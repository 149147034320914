import { AuthenticatedAPIInterface } from "@norma-bi/bi-api";
import { BASE_URL } from "../constants";

export function initiateNewFoodicsBusinessInstallation() {
  const url = new URL("/foodics-auth", BASE_URL);
  url.searchParams.set("client-type", "foodics-ebi");
  window.location.href = url.toString();
}

export async function initiateFoodicsBusinessOnboardingInExistingOrganization(
  api: AuthenticatedAPIInterface,
) {
  const { token } = await api.getIntegrationsFoodicsOnboardBusinessRequest();
  const url = new URL("/integrations/foodics/onboard-business", BASE_URL);
  url.searchParams.set("token", token);
  window.location.href = url.toString();
}
