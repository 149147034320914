import React from "react";
import { Dialog } from "../components/Dialog";
import { useToggleAppMode } from "../hooks/useToggleAppMode";
import { ChangeModeRequestModeEnum as AppMode } from "@norma-bi/bi-api";
import { Sidebar } from "./Sidebar";
import { motion } from "framer-motion";
import { Liveboard } from "../pages/LiveboardEmbed";
import "./index.css";

export function LiteLayout() {
  const { mode, toggleAppMode } = useToggleAppMode();

  const [showSwitchDialog, setShowSwitchDialog] = React.useState(false);

  if (!mode) {
    return null;
  }

  return (
    <motion.div
      className="lite-layout-container flex h-full overflow-hidden bg-lightGray"
      initial={{ y: "100%", opacity: 0 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
    >
      <Sidebar mode={mode} onToggleMode={() => setShowSwitchDialog(true)} />
      <main className="flex-grow py-5 pr-5 bg-lightGray">
        <Liveboard />
      </main>
      <Dialog
        show={showSwitchDialog}
        title="Switch Mode"
        message={`Are you sure you want to switch to ${mode === AppMode.pro ? "Lite" : "Pro"} Mode?`}
        onConfirm={toggleAppMode}
        onCancel={() => setShowSwitchDialog(false)}
        confirmText="Confirm"
        cancelText="Cancel"
        className="h-[212px]"
      />
    </motion.div>
  );
}
