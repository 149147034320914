import { Business } from "@norma-bi/bi-api";

export const AllBusiness: Business = {
  id: "ALL",
  // TODO(yannis): name and display_name will need to be translated when an intl lib is introduced in this project
  name: "ALL BUSINESSES",
  display_name: "ALL BUSINESSES",
  venues_count: 0,
  users_count: 0,
};
export function isAllBusiness(businessId: string): boolean {
  return businessId === AllBusiness.id;
}
