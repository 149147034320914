export function FavoriteFilled() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.57335 5.33219L7.0632 1.53099C7.39887 0.674564 8.60092 0.674564 8.93659 1.53099L10.4264 5.33219L14.5976 5.54516C15.5276 5.59264 15.9011 6.77808 15.1689 7.35813L11.9454 9.91156L13.0168 13.8212C13.2619 14.7156 12.2871 15.4457 11.5087 14.9508L7.99989 12.7194L4.49104 14.9508C3.71265 15.4457 2.73785 14.7156 2.98296 13.8212L4.05437 9.91156L0.830915 7.35813C0.0986603 6.77808 0.472191 5.59264 1.40218 5.54516L5.57335 5.33219Z"
        fill="currentColor"
      />
    </svg>
  );
}
