import EventSource, { EventSourceOptions } from "./xhrEventSource";
import {
  SSEEmitterErrorEvent,
  SSEEmitterExceptionError,
  SSEEmitterGenericError,
  SSEEmitterMalformedMessageError,
  SSEEmitterTimeoutError,
} from "./errors";
import {
  SSEEmitterMessageListener,
  SSEEmitterErrorListener,
  SSEEmitterOptions,
  SSEEmitterInterface,
  SSEEmitterBaseMessage,
} from "./types";

export abstract class SSEEmitter<Message extends SSEEmitterBaseMessage>
  implements SSEEmitterInterface<Message>
{
  private lastMessage: Message | null = null;
  private messageListeners: SSEEmitterMessageListener<Message>[] = [];
  private errorListeners: SSEEmitterErrorListener[] = [];

  private url: string | URL;
  private eventSourceOptions?: EventSourceOptions;
  private options?: SSEEmitterOptions;

  private stream: EventSource | null = null;

  constructor(
    url: string | URL,
    eventSourceOptions?: EventSourceOptions,
    topicSubscriberOptions?: SSEEmitterOptions,
  ) {
    this.url = url;
    this.eventSourceOptions = { timeoutBeforeConnection: 0, ...eventSourceOptions };
    this.options = topicSubscriberOptions;
  }

  // NOTE(yannis): receives only a string to prevent the notorious no-logging-when-toString-is-used bug,
  // which I haven't been able to track down
  private infoLog(log: string) {
    if (!this.options?.debugLogs) {
      return;
    }
    console.log(`[SSE-EMITTER (url=${this.url})] ${log}`);
  }

  // NOTE(yannis): receives only a string to prevent the notorious no-logging-when-toString-is-used bug,
  // which I haven't been able to track down
  private errorLog(log: string) {
    if (!this.options?.debugLogs) {
      return;
    }
    console.error(`[SSE-EMITTER (url=${this.url})] ${log}`);
  }

  private connect() {
    if (!this.stream) {
      this.infoLog("stream not yet initialized, initializing");
      this.stream = new EventSource(this.url, this.eventSourceOptions);
      this.setupHandlers();
    } else {
      this.infoLog("stream already initialized, re-opening");
      this.stream.open();
    }
  }

  private disconnect() {
    this.infoLog("closing");
    this.stream?.close();
    this.infoLog("de-initializing");
    this.stream = null;
  }

  public addEventListener<
    EventType extends "message" | "error",
    Listener extends EventType extends "message"
    ? SSEEmitterMessageListener<Message>
    : SSEEmitterErrorListener,
  >(eventType: EventType, listener: Listener) {
    if (eventType === "message") {
      this.addMessageListener(listener as SSEEmitterMessageListener<Message>);
    } else if (eventType === "error") {
      this.addErrorListener(listener as SSEEmitterErrorListener);
    }
  }

  private addMessageListener(listener: SSEEmitterMessageListener<Message>) {
    this.messageListeners.push(listener);
    this.infoLog("message listener added");

    this.connect();

    if (this.lastMessage) {
      this.infoLog("last message sent to new message listener");
      listener(this.lastMessage);
    }
  }

  private addErrorListener(listener: SSEEmitterErrorListener) {
    this.errorListeners.push(listener);
    this.infoLog("error listener added");
  }

  public removeEventListener<
    EventType extends "message" | "error",
    Listener extends EventType extends "message"
    ? SSEEmitterMessageListener<Message>
    : SSEEmitterErrorListener,
  >(eventType: EventType, listener: Listener) {
    if (eventType === "message") {
      this.removeMessageListener(listener as SSEEmitterMessageListener<Message>);
    } else if (eventType === "error") {
      this.removeErrorListener(listener as SSEEmitterErrorListener);
    }
  }

  private removeMessageListener(listener: SSEEmitterMessageListener<Message>) {
    const idx = this.messageListeners.indexOf(listener);
    if (idx === -1) {
      // unsubscribe multiple times
      return;
    }
    this.messageListeners.splice(idx, 1);
    if (this.messageListeners.length === 0) {
      this.disconnect();
    }
    this.infoLog("message listener removed");
  }

  private removeErrorListener(listener: SSEEmitterErrorListener) {
    const idx = this.errorListeners.indexOf(listener);
    if (idx === -1) {
      return;
    }
    this.errorListeners.splice(idx, 1);
    this.infoLog("error listener removed");
  }

  private emitMessage(message: Message) {
    this.messageListeners.forEach((l) => {
      l(message);
    });
  }

  private emitError(error: SSEEmitterErrorEvent) {
    this.errorListeners.forEach((l) => {
      l(error);
    });
  }

  private setupHandlers() {
    if (!this.stream) throw new Error("stream not initialized");

    this.stream.addEventListener("open", () => {
      this.infoLog("open event received");
    });
    this.stream.addEventListener("close", () => {
      this.infoLog("close event received");
    });
    this.stream.addEventListener("message", (ev) => {
      this.infoLog(`message event received: data=${ev.data}`);
      if (!ev.data) {
        this.infoLog("message event received: empty data");
        this.emitError(new SSEEmitterMalformedMessageError("empty data", null));
        return;
      }
      let msg: Message;
      try {
        msg = JSON.parse(ev.data) as Message;
      } catch (e) {
        this.errorLog(`failed to parse message event: data: ${String(ev.data)}`);
        this.emitError(new SSEEmitterMalformedMessageError(e, ev.data));
        return;
      }
      this.lastMessage = msg;
      this.emitMessage(msg);
    });

    this.stream.addEventListener("error", (evt) => {
      let err: SSEEmitterErrorEvent;
      switch (evt.type) {
        case "error":
          err = new SSEEmitterGenericError(evt);
          break;
        case "timeout":
          err = new SSEEmitterTimeoutError(evt);
          break;
        case "exception":
          err = new SSEEmitterExceptionError(evt);
          break;
      }
      this.errorLog(`error: ${String(err)}`);
      this.emitError(err);
    });
  }
}
