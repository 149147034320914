import { create } from "zustand";

export type InitialOnboardingStore = {
  selectedAccountIds: { [key: string]: boolean };

  setSelectedAccountIds: (accountIds: { [key: string]: boolean }) => void;
  reset: () => void;
};

export const useInitialOnboardingStore = create<InitialOnboardingStore>((set) => ({
  selectedAccountIds: {},

  setSelectedAccountIds: (accountIds) => set({ selectedAccountIds: accountIds }),

  reset: () => set({ selectedAccountIds: {} }),
}));
