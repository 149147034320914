/* tslint:disable */
/* eslint-disable */
/**
 * bi-api
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { UserAggregate1 } from './UserAggregate1';
import {
    UserAggregate1FromJSON,
    UserAggregate1FromJSONTyped,
    UserAggregate1ToJSON,
    UserAggregate1ToJSONTyped,
} from './UserAggregate1';

/**
 * 
 * @export
 * @interface GetUsersByBusinessResponse
 */
export interface GetUsersByBusinessResponse {
    /**
     * 
     * @type {Array<UserAggregate1>}
     * @memberof GetUsersByBusinessResponse
     */
    data: Array<UserAggregate1>;
}

/**
 * Check if a given object implements the GetUsersByBusinessResponse interface.
 */
export function instanceOfGetUsersByBusinessResponse(value: object): value is GetUsersByBusinessResponse {
    if (!('data' in value) || value['data'] === undefined) return false;
    return true;
}

export function GetUsersByBusinessResponseFromJSON(json: any): GetUsersByBusinessResponse {
    return GetUsersByBusinessResponseFromJSONTyped(json, false);
}

export function GetUsersByBusinessResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetUsersByBusinessResponse {
    if (json == null) {
        return json;
    }
    return {
        
        'data': ((json['data'] as Array<any>).map(UserAggregate1FromJSON)),
    };
}

export function GetUsersByBusinessResponseToJSON(json: any): GetUsersByBusinessResponse {
    return GetUsersByBusinessResponseToJSONTyped(json, false);
}

export function GetUsersByBusinessResponseToJSONTyped(value?: GetUsersByBusinessResponse | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'data': ((value['data'] as Array<any>).map(UserAggregate1ToJSON)),
    };
}

