import React from "react";

export function DotsGrid() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.00008 3.99984C8.36827 3.99984 8.66675 3.70136 8.66675 3.33317C8.66675 2.96498 8.36827 2.6665 8.00008 2.6665C7.63189 2.6665 7.33341 2.96498 7.33341 3.33317C7.33341 3.70136 7.63189 3.99984 8.00008 3.99984Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00008 8.6665C8.36827 8.6665 8.66675 8.36803 8.66675 7.99984C8.66675 7.63165 8.36827 7.33317 8.00008 7.33317C7.63189 7.33317 7.33341 7.63165 7.33341 7.99984C7.33341 8.36803 7.63189 8.6665 8.00008 8.6665Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.00008 13.3332C8.36827 13.3332 8.66675 13.0347 8.66675 12.6665C8.66675 12.2983 8.36827 11.9998 8.00008 11.9998C7.63189 11.9998 7.33341 12.2983 7.33341 12.6665C7.33341 13.0347 7.63189 13.3332 8.00008 13.3332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 3.99984C13.0349 3.99984 13.3334 3.70136 13.3334 3.33317C13.3334 2.96498 13.0349 2.6665 12.6667 2.6665C12.2986 2.6665 12.0001 2.96498 12.0001 3.33317C12.0001 3.70136 12.2986 3.99984 12.6667 3.99984Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 8.6665C13.0349 8.6665 13.3334 8.36803 13.3334 7.99984C13.3334 7.63165 13.0349 7.33317 12.6667 7.33317C12.2986 7.33317 12.0001 7.63165 12.0001 7.99984C12.0001 8.36803 12.2986 8.6665 12.6667 8.6665Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.6667 13.3332C13.0349 13.3332 13.3334 13.0347 13.3334 12.6665C13.3334 12.2983 13.0349 11.9998 12.6667 11.9998C12.2986 11.9998 12.0001 12.2983 12.0001 12.6665C12.0001 13.0347 12.2986 13.3332 12.6667 13.3332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33341 3.99984C3.7016 3.99984 4.00008 3.70136 4.00008 3.33317C4.00008 2.96498 3.7016 2.6665 3.33341 2.6665C2.96522 2.6665 2.66675 2.96498 2.66675 3.33317C2.66675 3.70136 2.96522 3.99984 3.33341 3.99984Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33341 8.6665C3.7016 8.6665 4.00008 8.36803 4.00008 7.99984C4.00008 7.63165 3.7016 7.33317 3.33341 7.33317C2.96522 7.33317 2.66675 7.63165 2.66675 7.99984C2.66675 8.36803 2.96522 8.6665 3.33341 8.6665Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.33341 13.3332C3.7016 13.3332 4.00008 13.0347 4.00008 12.6665C4.00008 12.2983 3.7016 11.9998 3.33341 11.9998C2.96522 11.9998 2.66675 12.2983 2.66675 12.6665C2.66675 13.0347 2.96522 13.3332 3.33341 13.3332Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
