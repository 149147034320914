export function SpotIQ() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.6312 0.315576C12.7068 1.06621 13.0373 1.76815 13.5678 2.30481C14.0982 2.84117 14.7964 3.17972 15.546 3.26406C15.7245 3.28484 15.8587 3.43639 15.8576 3.61597C15.8567 3.79563 15.7207 3.94573 15.5421 3.96451C14.7916 4.0402 14.0895 4.37071 13.553 4.90129C13.0165 5.43159 12.6779 6.12976 12.5938 6.87947C12.573 7.05803 12.4213 7.19215 12.2417 7.19106C12.062 7.19016 11.9121 7.05419 11.8933 6.87557C11.8175 6.12505 11.4869 5.42291 10.9566 4.88653C10.4261 4.34989 9.72794 4.01135 8.97824 3.9272C8.79996 3.90631 8.66556 3.75468 8.66666 3.5751C8.66756 3.39543 8.80361 3.24533 8.98223 3.22655C9.73285 3.15095 10.4348 2.82035 10.9712 2.28997C11.5078 1.75947 11.8464 1.06131 11.9305 0.311591C11.9514 0.133309 12.103 -0.000893718 12.2826 4.48201e-06C12.4622 0.00109449 12.6124 0.136952 12.6312 0.315576Z"
        fill="currentColor"
      />
      <path
        d="M11.3333 9.85775C11.8624 9.32866 12.3333 8.76994 12.685 8.16221C12.2091 8.2593 11.6002 8.19381 11.1249 7.78812C10.8738 8.14387 10.5761 8.47362 10.2706 8.77915C9.50163 9.54809 9.21447 10.5244 9.21447 11.1911H6.89214C6.89214 10.5505 6.49782 9.54809 5.72887 8.77915C4.66666 7.71693 4.18607 7.0177 4.18607 5.85773C4.18607 3.84918 5.89694 2.21476 7.99999 2.21476C8.31413 2.21476 8.61954 2.25125 8.9118 2.32002C9.26242 2.40253 10.2059 1.86067 10.5498 1.38369C9.79192 0.988051 8.9231 0.763021 8.00027 0.763021C5.05922 0.763021 2.66666 3.04856 2.66666 5.85775C2.66666 7.4545 3.48017 8.67126 4.66666 9.85775C5.55857 10.7497 5.55857 11.8577 5.55857 11.8967V12.5244H10.5498V11.8973C10.5498 11.8973 10.5498 10.6413 11.3333 9.85775Z"
        fill="currentColor"
      />
      <path
        d="M5.55856 13.3447H10.5471V14.4032C10.5471 14.6913 10.3728 14.9278 10.1597 14.9278H9.1972V15.3656C9.1972 15.7158 8.85754 15.8396 8.67116 15.8578H7.33783C7.03575 15.8578 6.82479 15.6312 6.82479 15.3447V14.9278H5.96874C5.74323 14.9278 5.55856 14.6913 5.55856 14.4032V13.3447Z"
        fill="currentColor"
      />
      <path
        d="M2.93637 12.3718C2.61628 12.0516 2.41556 11.6315 2.36768 11.1814C2.35581 11.0743 2.26524 10.9933 2.1575 10.9932C2.0497 10.9933 1.95922 11.0743 1.94729 11.1814C1.89932 11.6315 1.69855 12.0515 1.37836 12.3716C1.05832 12.6916 0.638279 12.8924 0.188167 12.9403C0.0810594 12.9521 -6.47991e-05 13.0426 3.88384e-08 13.1504C-5.05175e-05 13.2582 0.0810958 13.3487 0.188132 13.3607C0.638231 13.4086 1.05826 13.6094 1.37835 13.9296C1.69838 14.2496 1.89905 14.6698 1.9471 15.1198C1.95896 15.2269 2.04937 15.308 2.15716 15.3079C2.26491 15.308 2.3555 15.227 2.36737 15.1198C2.41534 14.6697 2.61611 14.2497 2.93625 13.9297C3.25634 13.6095 3.6765 13.4089 4.12649 13.3609C4.2336 13.3491 4.31472 13.2585 4.31466 13.1507C4.31471 13.043 4.23368 12.9525 4.12648 12.9406C3.67643 12.8926 3.2564 12.6918 2.93637 12.3718Z"
        fill="currentColor"
      />
    </svg>
  );
}
